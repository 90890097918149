import React, { createContext, useContext } from 'react';
import PropTypes from 'prop-types';

import styles from './index.module.scss';
import classNames from 'classnames/bind';

const cx = classNames.bind(styles);

const TabContext = createContext();

const Tabs = ({ value, onChange, children, position = 'top', matchPrefix }) => {
  let items = React.Children.toArray(children);
  items = items.filter(({ type }) => type === TabItem);

  return (
    <TabContext.Provider value={{ selected: value, matchPrefix, onChange }}>
      <div className={cx('tabs', position)}>{items}</div>
    </TabContext.Provider>
  );
};

Tabs.propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
  position: PropTypes.oneOf(['top', 'bottom', 'right']),
  matchPrefix: PropTypes.bool.isRequired,
};

const TabItem = ({ value, children }) => {
  const { selected, matchPrefix, onChange } = useContext(TabContext);

  const onClick = () => {
    onChange(value);
  };

  return (
    <div
      className={cx('item', {
        selected: matchPrefix ? selected.startsWith(value) : selected === value,
      })}
      onClick={onClick}>
      {children}
    </div>
  );
};

TabItem.propTypes = {
  value: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

Tabs.Item = TabItem;

export { Tabs, TabItem };
