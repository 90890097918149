import { useState, useEffect } from 'react';
import { useDebounce } from 'hooks/useDebounce';
import { formatISODate, formatDateTime } from 'utils/formatDate';
import { DateSelect, DataTable, Flex, Label, Ribbon, Select } from 'library/components';
import moment from 'moment';

import styles from './index.module.scss';
import classNames from 'classnames/bind';

import { useQuery } from 'react-query';
import { useAuth } from 'react-oidc-context';
import { apiFetch } from 'utils/apiService';
import { getProcessedData, getSearchedText } from 'utils/common';
import { PriceDefinitionSearch, PriceTypeSelect, SourceSelect, TagsMultiSelect } from 'components/shared';

const cx = classNames.bind(styles);

const getPriceCurveColumns = source => [
  {
    id: 'source',
    name: 'Source',
    field: 'source',
    sort: true,
  },
  {
    id: 'market',
    name: 'Market',
    function: row => row.name.split(/\|/g)[0],
    show: source === 'Aligne',
    sort: true,
  },
  {
    id: 'component',
    name: 'Component',
    function: row => row.name.split(/\|/g)[1],
    show: source === 'Aligne',
    sort: true,
  },
  {
    id: 'name',
    name: 'Name',
    field: 'name',
    show: source !== 'Aligne',
    sort: true,
  },
  {
    id: 'description',
    name: 'Description',
    field: 'description',
    sort: true,
  },
  {
    id: 'tags',
    name: 'Tags',
    field: 'tags',
    render: tags => (
      <Flex row wrap className={cx('tags')}>
        {tags.map((tag, i) => (
          <div className={cx('tag')} key={`${i}`}>
            {tag}
          </div>
        ))}
      </Flex>
    ),
  },
  {
    id: 'ingestionDate',
    name: 'Ingestion Date',
    function: row => (row.priceCurves[0] ? new Date(row.priceCurves[0].ingestionDate) : null),
    render: value => (value ? formatDateTime(value) : '(none)'),
    sort: true,
  },
];

const PriceCurve = () => {
  const [publicationDate, setPublicationDate] = useState(() => new Date(Date.now() - 24 * 3600_000));
  const [combinedData, setCombinedData] = useState([]);
  const [processedData, setProcessedData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [showEmpty, setShowEmpty] = useState(false);
  const [sources, setSources] = useState([]);
  const [source, setSource] = useState(null);
  const [markets, setMarkets] = useState([]);
  const [market, setMarket] = useState(null);
  const [components, setComponents] = useState([]);
  const [component, setComponent] = useState(null);
  const [tags, setTags] = useState([]);
  const [selectedTags, setSelectedTags] = useState([]);
  const [priceType, setPriceType] = useState(null);
  const [sortBy, setSortBy] = useState(null);
  const [live, setLive] = useState(false);
  const [search, setSearch] = useState('');
  const debouncedSearch = useDebounce(search, 300);

  const { user } = useAuth();

  const {
    isLoading: priceDefinitionsLoading,
    data: priceDefinitionsData,
    isError: priceDefinitionsError,
  } = useQuery({
    queryKey: ['/Raw/PriceDefinition'],
    queryFn: ({ queryKey: [url] }) => apiFetch({ url, token: user?.access_token }),
    enabled: !!user?.access_token,
  });
  const {
    isLoading: fxDefinitionsLoading,
    data: fxDefinitionsData,
    error: fxDefinitionsError,
  } = useQuery({
    queryKey: ['/Raw/FxDefinition'],
    queryFn: ({ queryKey: [url] }) => apiFetch({ url, token: user?.access_token }),
    enabled: !!user?.access_token,
  });
  const {
    isLoading: priceCurvesLoading,
    data: priceCurvesData,
    error: priceCurvesError,
  } = useQuery({
    queryKey: [live ? '/raw/LivePrices/list' : '/raw/PriceCurve/list'],
    queryFn: ({ queryKey: [url] }) =>
      apiFetch({
        url,
        params: live
          ? undefined
          : {
              PublicationDate: formatISODate(publicationDate),
            },
        token: user?.access_token,
      }),
    enabled: !!user?.access_token,
  });

  useEffect(() => {
    if (!fxDefinitionsData || !priceDefinitionsData || !priceCurvesData) {
      setCombinedData([]);
      return;
    }
    const definitionsData = [...fxDefinitionsData, ...priceDefinitionsData];
    const combinedData = [];
    for (const row of definitionsData) {
      const priceCurves = priceCurvesData
        .filter(curve => curve.source === row.source && curve.priceDefinitionName === row.name)
        .sort((a, b) => {
          if (a.ingestionDate > b.ingestionDate) {
            return -1;
          }
          if (a.ingestionDate < b.ingestionDate) {
            return 1;
          }
          return 0;
        })
        .map(x => ({ ...x }));
      combinedData.push({ ...row, priceCurves });
    }
    setCombinedData(combinedData);
  }, [priceDefinitionsData, fxDefinitionsData, priceCurvesData]);

  useEffect(() => {
    if (!combinedData) {
      setSources([]);
      setMarkets([]);
      setComponents([]);
      setTags([]);
      return;
    }

    setSources(Array.from(new Set(combinedData.map(row => row.source))));
    setMarkets(Array.from(new Set(combinedData.map(row => row.name.split(/\|/g)[0]))));
    setComponents(Array.from(new Set(combinedData.map(row => row.name.split(/\|/g)[1]))));

    setTags(
      Array.from(
        combinedData.reduce((a, b) => {
          b.tags.forEach(tag => a.add(tag));
          return a;
        }, new Set()),
      ),
    );
  }, [combinedData]);

  useEffect(() => {
    let processedData = combinedData ?? [];
    const filters = {
      source,
      priceType,
      selectedTags,
    };
    processedData = getProcessedData(processedData, filters);

    if (!showEmpty) {
      processedData = processedData.filter(row => row.priceCurves?.length);
    }
    if (market !== null) {
      processedData = processedData.filter(row => row.name.split(/\|/g)[0] === market);
    }
    if (component != null) {
      processedData = processedData.filter(row => row.name.split(/\|/g)[1] === component);
    }
    setProcessedData(processedData);
  }, [combinedData, showEmpty, selectedTags, source, market, component, priceType]);

  useEffect(() => {
    const filteredData = getSearchedText(processedData, debouncedSearch);
    setFilteredData(filteredData);
  }, [processedData, debouncedSearch]);

  const columns = getPriceCurveColumns(source);

  return (
    <Flex row alignItems="stretch" grow shrink overflow="hidden">
      <Flex column alignItems="stretch" grow shrink overflow="hidden">
        <Flex row alignItems="center" className={cx('toolbar')}>
          <Ribbon value={live} onChange={value => setLive(value)}>
            <Ribbon.Item value={true}>Latest</Ribbon.Item>
            <Ribbon.Item value={false}>Snapshot</Ribbon.Item>
          </Ribbon>
          {!live && (
            <>
              <Label className={cx('label')}>Publication Date:</Label>
              <DateSelect
                value={publicationDate}
                onChange={value => setPublicationDate(value)}
                min={moment(new Date(Date.now()), 'utc').startOf('day').add(-2, 'years').toDate()}
                max={moment(new Date(Date.now()), 'utc').startOf('day').toDate()}
              />
            </>
          )}
          <Flex grow />
          <Label className={cx('label')}>Empty Price Curves:</Label>
          <Ribbon value={showEmpty} onChange={value => setShowEmpty(value)}>
            <Ribbon.Item value={true}>Show</Ribbon.Item>
            <Ribbon.Item value={false}>Hide</Ribbon.Item>
          </Ribbon>

          <TagsMultiSelect value={selectedTags} onChange={value => setSelectedTags(value.slice())} tags={tags} />
        </Flex>
        <Flex column alignItems="stretch" grow shrink overflow="auto" className={cx('main')}>
          <DataTable
            loading={priceDefinitionsLoading || priceCurvesLoading || fxDefinitionsLoading}
            error={priceDefinitionsError ?? priceCurvesError ?? fxDefinitionsError}
            columns={columns}
            data={filteredData}
            sortBy={sortBy}
            onChangeSortBy={value => setSortBy(value)}
          />
        </Flex>
      </Flex>
      <Flex column alignItems="stretch" overflow="auto" basis="250px" className={cx('sidebar')}>
        <SourceSelect value={source} onChange={value => setSource(value)} sources={sources} />

        {source === 'Aligne' && (
          <>
            <Label className={cx('label')}>Market</Label>
            <Select value={market ?? null} onChange={value => setMarket(value)}>
              <Select.Item value={null}>All Markets</Select.Item>
              {markets.map(market => (
                <Select.Item key={market} value={market}>
                  {market}
                </Select.Item>
              ))}
            </Select>
          </>
        )}

        {source === 'Aligne' && (
          <>
            <Label className={cx('label')}>Component</Label>
            <Select value={component ?? null} onChange={value => setComponent(value)}>
              <Select.Item value={null}>All Components</Select.Item>
              {components.map(component => (
                <Select.Item key={component} value={component}>
                  {component}
                </Select.Item>
              ))}
            </Select>
          </>
        )}

        <PriceTypeSelect value={priceType} onChange={value => setPriceType(value)} />
        <PriceDefinitionSearch value={search} onChange={value => setSearch(value)} />
      </Flex>
    </Flex>
  );
};

export { PriceCurve };
