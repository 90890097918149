import { useRef, useState, useEffect } from 'react';
import orderBy from 'lodash/orderBy';
import PropTypes from 'prop-types';
import { ReactComponent as CheckIcon } from './check.svg';
import { ReactComponent as SpinnerIcon } from './spinner.svg';
import { formatTime } from 'utils/formatDate';
import { formatInteger, formatNumber } from 'utils/formatNumber';
import { Flex } from '../Flex';
import { Button } from '../Button';
import { EmbedComponentProvider } from 'hooks/useEmbedComponent';
import * as XLSX from 'xlsx';
import { intToExcelCol } from 'excel-column-name';
import { ReactComponent as CheckBoxIcon } from './checkbox.svg';
import { ReactComponent as CheckBoxIndeterminateIcon } from './checkbox-indeterminate.svg';
import { ReactComponent as CheckBoxCheckedIcon } from './checkbox-checked.svg';
import styles from './index.module.scss';
import classNames from 'classnames/bind';

const cx = classNames.bind(styles);
const DataSymbol = Symbol.for('data');
const EditingSymbol = Symbol.for('editing');
const CHECKBOX_CELL_CLASS = 'checkbox-cell';
const CHECKBOX_CLASS = 'checkbox';

const getStyle = (column, row) => {
  const style = {};
  if (typeof column.backgroundColor === 'string') {
    style.backgroundColor = column.backgroundColor;
  } else if (typeof column.backgroundColor === 'function') {
    style.backgroundColor = column.backgroundColor(row[DataSymbol]);
  }

  if (typeof column.color === 'string') {
    style.color = column.color;
  } else if (typeof column.color === 'function') {
    style.color = column.color(row[DataSymbol]);
  }
  return style;
};

const getSortedData = (s, sortBy) => {
  for (const id of sortBy) {
    if (id[0] === '-') {
      return orderBy(s, id.substring(1), 'desc');
    } else {
      return orderBy(s, id, 'asc');
    }
  }

  return s;
};

const onClickColumn = (column, sortBy, onChangeSortBy) => {
  if (column.sort) {
    if (sortBy?.[0] === column.id) {
      onChangeSortBy?.([`-${column.id}`]);
    } else if (sortBy?.[0] === `-${column.id}`) {
      onChangeSortBy(null);
    } else {
      onChangeSortBy?.([column.id]);
    }
  }
};

const renderColumn = (column, sortBy, onChangeSortBy) => {
  let number;
  switch (column.type ?? 'string') {
    case 'integer':
    case 'time':
    case 'float':
    case 'percent':
      number = true;
      break;
    default:
      number = false;
      break;
  }

  let sorted = 0;
  const s = sortBy?.find(x => x === `-${column.id}` || x === column.id);
  if (s?.[0] === '-') {
    sorted = -1;
  } else if (s) {
    sorted = 1;
  }

  return (
    <th
      className={cx('column', {
        number,
        sortable: !!column.sort,
        'sorted-asc': sorted > 0,
        'sorted-desc': sorted < 0,
      })}
      key={column.name}>
      <span onClick={() => onClickColumn(column, sortBy, onChangeSortBy)}>{column.name}</span>
    </th>
  );
};

const onChangeCell = (row, column, event, columns, sortedData, setSortedData) => {
  let { value } = event.target;
  if (row[EditingSymbol]?.id === column.id) {
    row[EditingSymbol].value = value;
  }

  if (column.type === 'float') {
    value = parseFloat(value);
  }
  column.input.onChange?.(row[DataSymbol], value);

  for (const r of sortedData) {
    for (const c of columns) {
      if (c.function) {
        r[c.id] = c.function(r[DataSymbol]);
      } else if (c.input) {
        r[c.id] = c.input.value(r[DataSymbol]);
      }
    }
  }

  setSortedData(sortedData.slice());
};

const onFocusCell = (row, column) => {
  row[EditingSymbol] = {
    id: column.id,
    value: row[column.id],
  };

  if (column.type === 'float') {
    row[EditingSymbol].value = row[EditingSymbol].value.toFixed(column.decimalPlaces);
  }
};

const onBlurCell = (row, column) => {
  if (row[EditingSymbol]?.id === column.id) {
    delete row[EditingSymbol];
  }
};

const renderInputCell = (row, column, columns, sortedData, setSortedData) => {
  let value = row[column.id];
  if (column.input.render) {
    return (
      <td className={cx('cell', 'input', 'no-border')} key={column.id}>
        <span>
          {column.input.render(value, row, value =>
            onChangeCell(row, column, { target: { value } }, columns, sortedData, setSortedData),
          )}
        </span>
      </td>
    );
  }
  if (column.type === 'float') {
    if (row[EditingSymbol]?.id === column.id) {
      value = row[EditingSymbol]?.value;
    } else if (typeof value === 'undefined' || value === null) {
      value = '-';
    } else {
      value = formatNumber(value, column.decimalPlaces ?? 2);
    }
    number = true;
  } else if (column.type !== 'string' || column.type !== 'boolean') {
    number = true;
  }
  return (
    <td className={cx('cell', 'input', { number })} key={column.id}>
      <span>
        <input
          type="text"
          value={value}
          onChange={event => onChangeCell(row, column, event, columns, sortedData, setSortedData)}
          onFocus={() => onFocusCell(row, column)}
          onBlur={() => onBlurCell(row, column)}
        />
      </span>
    </td>
  );
};

const renderCell = (row, column, columns, sortedData, setSortedData) => {
  const value = row[column.id];
  let formattedValue;
  let number = false;

  if (column.input) {
    return renderInputCell(row, column, columns, sortedData, setSortedData);
  }

  switch (column.type) {
    case 'integer': {
      formattedValue = formatInteger(value);
      number = true;
      break;
    }
    case 'time': {
      formattedValue = formatTime(value);
      number = true;
      break;
    }
    case 'float': {
      if (typeof value === 'undefined' || value === null || value === '') {
        formattedValue = '-';
      } else {
        formattedValue = formatNumber(value, column.decimalPlaces ?? 2);
      }
      number = true;
      break;
    }
    case 'percent': {
      formattedValue = `${formatInteger(100 * value)}%`;
      number = true;
      break;
    }
    case 'boolean': {
      let Component;
      if (value) {
        Component = column.trueComponent ?? (() => <CheckIcon />);
      } else {
        Component = column.falseComponent ?? null;
      }
      formattedValue = Component ? <Component /> : null;
      break;
    }
    default: {
      formattedValue = value;
      break;
    }
  }

  const style = getStyle(column, row);
  let rendered = formattedValue;
  if (column.render) {
    rendered = column.render(formattedValue, row, column);
  }

  return (
    <td className={cx('cell', { number })} key={column.id}>
      <span style={style}>{rendered}</span>
    </td>
  );
};

const renderRow = (
  row,
  columns,
  sortedData,
  i,
  selection,
  selectedClassName,
  { setSortedData, onClickRow, onClickCheckBox, onSelectionChange },
) => {
  const selected = !!selection?.find(r => row[DataSymbol] === r);
  return (
    <EmbedComponentProvider selected={selected} key={`${i}`}>
      <tr
        className={cx('row', {
          selected,
          [selectedClassName]: selected,
          hoverable: !!onClickRow,
        })}
        onClick={event => onClickCheckBox(row, event)}>
        <td className={cx(CHECKBOX_CELL_CLASS)}>
          {!!onSelectionChange && (
            <div className={cx(CHECKBOX_CLASS)} onClick={() => onClickCheckBox(row)}>
              {selected ? <CheckBoxCheckedIcon /> : <CheckBoxIcon />}
            </div>
          )}
        </td>
        {columns
          .filter(column => column.show !== false)
          .map(column => renderCell(row, column, columns, sortedData, setSortedData))}
      </tr>
    </EmbedComponentProvider>
  );
};

const renderFooterCell = (footer, footerCell, columns, i, sortedData, setSortedData) => {
  const index = footer.slice(0, i).reduce((a, b) => a + (b.colSpan ?? 1), 0);

  if (footerCell.function) {
    const id = columns.filter(column => column.show ?? true)[index].id;
    const value = footerCell.function(sortedData.map(row => row[id]));
    return renderCell({ [id]: value }, { ...footerCell, id }, columns, sortedData, setSortedData);
  }

  return (
    <td key={`${i}`} colSpan={footerCell.colSpan ?? 1} className={cx('footer-label-cell')}>
      <span>{footerCell.label}</span>
    </td>
  );
};

const onClickExportToExcel = async (columns, sortedData) => {
  try {
    const wb = XLSX.utils.book_new();
    const data = [];
    const wscols = {};
    const headerRow = [];
    for (const column of columns) {
      if (column.show !== false) {
        headerRow.push(column.name);
        wscols[column.id] = Math.max(wscols[column.id] || 0, `${column.name}`.length);
      }
    }
    data.push(headerRow);

    for (const row of sortedData) {
      const nextRow = [];
      for (const column of columns) {
        if (column.show !== false) {
          nextRow.push(row[column.id]);
          wscols[column.id] = Math.max(wscols[column.id] || 0, `${row[column.id]}`.length);
        }
      }
      data.push(nextRow);
    }

    const ws = XLSX.utils.aoa_to_sheet(data);
    ws['!cols'] = Object.values(wscols).map(width => ({ width: width + 10 }));
    ws['!autofilter'] = {
      ref: `A1:${intToExcelCol(data[0].length)}${data.length}`,
    };
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet 1');

    XLSX.writeFile(wb, 'table.xlsx', { bookType: 'xlsx' });
  } catch (err) {
    alert(err.message);
  }
};

const DataTable = ({
  data,
  columns,
  footer,
  sortBy,
  loading,
  onSelectionChange,
  className,
  selectedClassName,
  onClickRow,
  selection,
  onChangeSortBy,
  exportToExcel,
  multiSelect = true,
}) => {
  if (typeof sortBy === 'string') {
    sortBy = [sortBy];
  }

  const table = useRef();
  const [sortedData, setSortedData] = useState([]);

  const getColID = (column, row) => {
    const columnInput = column.input ? column.input.value(row) : row[column.field];
    let id = column.function ? column.function(row) : columnInput;
    if (!column.type || column.type === 'string') {
      id = id ?? '';
    }
    return id;
  };

  useEffect(() => {
    if (!data) {
      setSortedData([]);
      onSelectionChange?.([]);
      return;
    }

    let s = [];
    let changed = false;
    for (const row of data) {
      const item = sortedData?.find(x => x[DataSymbol] === row) ?? {
        [DataSymbol]: row,
      };
      for (const column of columns) {
        const oldValue = item[column.id];
        item[column.id] = getColID(column, row);

        if (item[column.id] !== oldValue) {
          changed = true;
        }
      }
      s.push(item);
    }

    if (sortBy) {
      s = [...getSortedData(s, sortBy)];
    }

    setSortedData(s);
    const sel = selection?.filter(row => !!s.find(r => r[DataSymbol] === row)) || [];
    if (sel.length !== selection?.length || changed) {
      onSelectionChange?.(sel);
    }
  }, [data, columns, sortBy]);

  const onClickHeaderCheckBox = () => {
    if ((selection?.length ?? 0) === 0) {
      onSelectionChange?.(Array.from(sortedData).map(row => row[DataSymbol]));
    } else {
      onSelectionChange?.([]);
    }
  };

  const onClickCheckBox = (row, event) => {
    if (event && (event.target.closest('a') || event.target.closest(`.${cx('input')}`))) {
      return;
    }

    if (event) {
      onClickRow?.(row[DataSymbol]);
    }

    let s = new Set(selection?.map(rRaw => sortedData.find(rCalculated => rCalculated[DataSymbol] === rRaw)) ?? []);
    if (s.has(row)) {
      s.delete(row);
    } else {
      s.add(row);

      if (!multiSelect) {
        s = new Set([row]);
      }
    }

    onSelectionChange?.(Array.from(s).map(row => row[DataSymbol]));
  };

  return (
    <Flex column grow className={cx('container')}>
      <Flex row alignItems="center" className={cx('toolbar')}>
        {!!exportToExcel && <Button onClick={() => onClickExportToExcel(columns, sortedData)}>Export to Excel</Button>}
      </Flex>
      <Flex row>
        <div
          className={cx(className, 'table', {
            selectable: !!onSelectionChange,
          })}
          ref={table}>
          <table>
            <thead>
              <tr>
                <th className={cx(CHECKBOX_CELL_CLASS)}>
                  {!!onSelectionChange && (
                    <div className={cx(CHECKBOX_CLASS)} onClick={onClickHeaderCheckBox}>
                      {(selection?.length ?? 0) === 0 && <CheckBoxIcon />}
                      {selection?.length > 0 && selection?.length < sortedData.length && <CheckBoxIndeterminateIcon />}
                      {!!sortedData.length && selection?.length === sortedData.length && <CheckBoxCheckedIcon />}
                    </div>
                  )}
                </th>
                {columns &&
                  columns
                    .filter(column => column.show !== false)
                    .map(column => renderColumn(column, sortBy, onChangeSortBy))}
              </tr>
            </thead>
            <tbody>
              {sortedData.map((row, i) =>
                renderRow(row, columns, sortedData, i, selection, selectedClassName, {
                  setSortedData,
                  onClickRow,
                  onClickCheckBox,
                  onSelectionChange,
                }),
              )}
              {!!footer && (
                <tr className={cx('footer-row')}>
                  <th className={cx(CHECKBOX_CELL_CLASS)} />
                  {footer.map((footerCell, i) =>
                    renderFooterCell(footer, footerCell, columns, i, sortedData, setSortedData),
                  )}
                </tr>
              )}
            </tbody>
          </table>
          {loading && (
            <div className={cx('loading')}>
              <SpinnerIcon />
              <div>Loading...</div>
            </div>
          )}
        </div>
      </Flex>
    </Flex>
  );
};

DataTable.propTypes = {
  data: PropTypes.array.isRequired,
  columns: PropTypes.array.isRequired,
  footer: PropTypes.array,
  sortBy: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
  loading: PropTypes.bool,
  onSelectionChange: PropTypes.func,
  className: PropTypes.string,
  selectedClassName: PropTypes.string,
  onClickRow: PropTypes.func,
  selection: PropTypes.array,
  onChangeSortBy: PropTypes.func,
  exportToExcel: PropTypes.bool,
  multiSelect: PropTypes.bool,
};

DataTable.defaultProps = {
  loading: false,
  data: [],
};

export { DataTable };
