import { useEffect, useState } from 'react';
import { useStaticCall } from './useStaticCalls';

import staticCalls from 'utils/staticCalls.json';

const useFxConversionList = () => {
  const [fxConversionList, setFxConversionList] = useState([]);

  const fxConversionData = useStaticCall(staticCalls.FXDEFINITION);

  useEffect(() => {
    if (fxConversionData) {
      const conversions = [
        ...fxConversionData?.map(fx => [fx.from, fx.to]),
        ...fxConversionData?.map(fx => [fx.to, fx.from]),
      ];
      const list = Array.from(new Set(conversions?.map(x => JSON.stringify(x))), x => JSON.parse(x));
      setFxConversionList(list);
    }
  }, [fxConversionData]);

  return fxConversionList;
};

export { useFxConversionList };
