import { createContext, useContext } from 'react';
import PropTypes from 'prop-types';
import { useQuery } from 'react-query';
import { useAuth } from 'react-oidc-context';
import { apiFetch } from 'utils/apiService';

const StaticCallContext = createContext();

const StaticCallProvider = ({ children }) => {
  let data = {};
  const { user } = useAuth();
  const { data: sources } = useQuery({
    queryKey: ['/staticdata/Sources'],
    queryFn: ({ queryKey: [url] }) => apiFetch({ url, token: user?.access_token }),
    enabled: !!user?.access_token,
  });
  const { data: currencies } = useQuery({
    queryKey: ['/staticdata/ISO/Currencies'],
    queryFn: ({ queryKey: [url] }) => apiFetch({ url, token: user?.access_token }),
    enabled: !!user?.access_token,
  });
  const { data: pricingUnits } = useQuery({
    queryKey: ['/staticdata/PricingUnits'],
    queryFn: ({ queryKey: [url] }) => apiFetch({ url, token: user?.access_token }),
    enabled: !!user?.access_token,
  });
  const { data: commodities } = useQuery({
    queryKey: ['/staticdata/Commodities'],
    queryFn: ({ queryKey: [url] }) => apiFetch({ url, token: user?.access_token }),
    enabled: !!user?.access_token,
  });
  const { data: priceTypes } = useQuery({
    queryKey: ['/staticdata/PriceTypes'],
    queryFn: ({ queryKey: [url] }) => apiFetch({ url, token: user?.access_token }),
    enabled: !!user?.access_token,
  });
  const { data: fxDefinitionData } = useQuery({
    queryKey: ['/Raw/FxDefinition'],
    queryFn: ({ queryKey: [url] }) => apiFetch({ url, token: user?.access_token }),
    enabled: !!user?.access_token,
  });
  const { data: priceDefinitionData } = useQuery({
    queryKey: ['/Raw/PriceDefinition'],
    queryFn: ({ queryKey: [url] }) => apiFetch({ url, token: user?.access_token }),
    enabled: !!user?.access_token,
  });

  data = {
    sources,
    currencies,
    pricingUnits,
    commodities,
    priceTypes,
    fxDefinitionData,
    priceDefinitionData,
  };

  const getData = type => data[type];

  return <StaticCallContext.Provider value={getData}>{children}</StaticCallContext.Provider>;
};

StaticCallProvider.propTypes = {
  children: PropTypes.node,
};

const useStaticCall = type => useContext(StaticCallContext)(type);
export { StaticCallProvider, useStaticCall };
