import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Flex, Label } from 'library/components';
import Modal from 'react-bootstrap/Modal';
import { useStaticCall } from 'hooks/useStaticCalls';
import staticCalls from 'utils/staticCalls.json';

import styles from './index.module.scss';
import classNames from 'classnames/bind';

const cx = classNames.bind(styles);

const MoreDetailsModal = ({ show, onHideModal, selected }) => {
  const { name, source, unit, currency, reportingCurrency, reportingUnit, fxDetails, commodity } = selected;

  const commoditiesData = useStaticCall(staticCalls.COMMODITIES);

  const [conversionFactor, setConversionFactor] = useState(1);
  const isUnitConverted = reportingUnit && reportingUnit !== unit;
  const isCurrencyConverted = reportingCurrency && reportingCurrency !== currency;

  useEffect(() => {
    const unitConversion = commoditiesData?.find(({ code }) => code === commodity)?.unitConversionFactors;
    const unitMap = unitConversion?.reduce((acc, { unit, factor }) => {
      acc[unit] = factor;
      return acc;
    }, {});
    const baseUnit = unitConversion.find(({ factor }) => factor === 1).unit;
    const fromUnit = unit;
    const toUnit = reportingUnit;
    let factor;
    if (fromUnit === toUnit) {
      factor = 1;
    } else if (fromUnit === baseUnit) {
      factor = unitMap[toUnit];
    } else {
      factor = (1 / unitMap[fromUnit]) * unitMap[toUnit];
    }
    setConversionFactor(factor);
  }, [commoditiesData, commodity, reportingUnit, unit]);

  const contentGrp = 'content-grp';

  return (
    <Modal show={show} onHide={onHideModal} centered className={cx('modal')}>
      <Modal.Header closeButton>
        <Modal.Title>Curve Details</Modal.Title>
      </Modal.Header>
      <Modal.Body className={cx('modal-body')}>
        <Flex column className={cx(contentGrp)}>
          <b>Curve</b>
          <Flex row>
            <Label>Source: </Label>
            <div className={cx('text')}>{source}</div>
          </Flex>
          <Flex row>
            <Label>Definition Name: </Label>
            <div className={cx('text')}>{name}</div>
          </Flex>
        </Flex>
        <Flex column className={cx(contentGrp)}>
          <b>Unit</b>
          <Flex row>
            <Label>Base Unit: </Label>
            <div className={cx('text', { converted: isUnitConverted }, { basic: !isUnitConverted })}>{unit}</div>
          </Flex>
          <Flex row>
            <Label>Reporting Unit: </Label>
            <div className={cx('text')}>{reportingUnit ? reportingUnit : unit}</div>
          </Flex>
          {isUnitConverted && (
            <Flex row>
              <Label>Conversion Factor: </Label>
              <div className={cx('text')}>{conversionFactor}</div>
            </Flex>
          )}
        </Flex>
        <Flex column className={cx(contentGrp)}>
          <b>Currency</b>
          <Flex row>
            <Label>Base Currency: </Label>
            <div className={cx('text', { converted: isCurrencyConverted }, { basic: !isCurrencyConverted })}>
              {currency}
            </div>
          </Flex>
          <Flex row>
            <Label>Reporting Currency: </Label>
            <div className={cx('text')}>{reportingCurrency ? reportingCurrency : currency}</div>
          </Flex>
          {isCurrencyConverted && fxDetails && (
            <>
              <Flex row>
                <Label>FxCurveName: </Label>
                <div className={cx('text')}>{fxDetails['FXRequest.DefinitionName']}</div>
              </Flex>
              <Flex row>
                <Label>FxSource: </Label>
                <div className={cx('text')}>{fxDetails['FXRequest.Source']}</div>
              </Flex>
              <Flex row>
                <Label>isInversed: </Label>
                <div className={cx('text')}>{String(fxDetails['FXRequest.Inverse'])}</div>
              </Flex>
              {fxDetails['FXRequest.PublicationDate'] && (
                <Flex row>
                  <Label>FxPublicationDate: </Label>
                  <div className={cx('text')}>{fxDetails['FXRequest.PublicationDate']}</div>
                </Flex>
              )}
              {fxDetails['FXRequest.IsLivePrice'] && (
                <Flex row>
                  <Label>IsLivePrice: </Label>
                  <div className={cx('text')}>{String(fxDetails['FXRequest.IsLivePrice'])}</div>
                </Flex>
              )}
            </>
          )}
        </Flex>
      </Modal.Body>
    </Modal>
  );
};

MoreDetailsModal.propTypes = {
  show: PropTypes.bool,
  onHideModal: PropTypes.func,
  selected: PropTypes.object,
};

export { MoreDetailsModal };
