import { useState } from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-bootstrap/Modal';
import { DataTable, Button, Flex } from 'library/components';

import styles from './index.module.scss';
import classNames from 'classnames/bind';

const cx = classNames.bind(styles);
const columns = [
  { id: 'source', name: 'Source', field: 'source', sort: true },
  { id: 'name', name: 'Name', field: 'name', sort: true },
  {
    id: 'description',
    name: 'Description',
    field: 'description',
    sort: true,
  },
];

const FxDefinitionModal = ({ item, curve, show, onHideModal, reRenderTable }) => {
  const [selectedFxDefinition, setSelectedFxDefinition] = useState([]);

  const onFxDefinitionSelectionChange = value => setSelectedFxDefinition(value.slice(value.length - 1));

  const onClickSelect = () => {
    if (selectedFxDefinition) {
      const fxConversion = {
        source: selectedFxDefinition[0].source,
        priceDefinitionName: selectedFxDefinition[0].name,
        isInversed: selectedFxDefinition[0].inverse,
      };
      const reportingCurrency = selectedFxDefinition[0].inverse
        ? selectedFxDefinition[0].from
        : selectedFxDefinition[0].to;
      curve.fxConversion = fxConversion;
      curve.reportingCurrency = reportingCurrency;
      reRenderTable(fxConversion);
    }
  };

  return (
    <Modal show={show} onHide={onHideModal} centered>
      <Modal.Header closeButton>
        <Modal.Title>Select FxDefinition</Modal.Title>
      </Modal.Header>
      <Modal.Body className={cx('modal-body')}>
        <DataTable
          columns={columns}
          data={item}
          selection={selectedFxDefinition}
          onSelectionChange={onFxDefinitionSelectionChange}
          multiSelect={false}
        />
        <Flex row justifyContent="flex-end" className={cx('button-bar')}>
          <Button onClick={onClickSelect}>Select</Button>
        </Flex>
      </Modal.Body>
    </Modal>
  );
};

FxDefinitionModal.propTypes = {
  item: PropTypes.array,
  curve: PropTypes.object,
  show: PropTypes.bool,
  onHideModal: PropTypes.func,
  reRenderTable: PropTypes.func,
};

export { FxDefinitionModal };
