import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Flex, RangeSlider, Label } from 'library/components';
import moment from 'moment';

import { formatISODate } from 'utils/formatDate';

import styles from './index.module.scss';
import classNames from 'classnames/bind';

const cx = classNames.bind(styles);

const RangeSliderComponent = ({ minRange, maxRange, priceCurvesData, updateRangedData, timeseriesType }) => {
  const [range, setRange] = useState(null);

  useEffect(() => {
    if (!priceCurvesData?.length) {
      updateRangedData(null);
      return;
    }
    const filteredData = priceCurvesData.filter(d => !!d);

    const _range = range ?? {
      from: new Date(minRange),
      to: calculateRangeTo(new Date(minRange), new Date(maxRange)),
    };

    const rangedData = filteredData.map(dataSet =>
      Object.assign({}, dataSet, {
        timeseries: dataSet.timeseries.filter(x => {
          const d = new Date(x[timeseriesType]).getTime();
          return d >= _range.from.getTime() && d <= _range.to.getTime();
        }),
      }),
    );

    updateRangedData(rangedData);
  }, [priceCurvesData, range, minRange, maxRange, updateRangedData, timeseriesType]);

  const renderRangeLabel = value => {
    return value ? <Label>{formatISODate(new Date(value))}</Label> : '';
  };

  const calculateRangeTo = (min, max, today = new Date(Date.now())) => {
    max = moment.utc(max).startOf('day').toDate();
    today = moment.utc(today).startOf('day').toDate();

    const to = moment.utc(today).add(5, 'years').endOf('year').startOf('day').toDate();

    return moment
      .utc(new Date(Math.min(max.getTime(), to.getTime())))
      .startOf('day')
      .toDate();
  };

  const onChangeRange = value => setRange(value);

  return (
    <Flex column nowrap className={cx('range-slider')} alignItems={'stretch'}>
      <RangeSlider
        min={minRange}
        max={maxRange}
        toValue={value => new Date(value)}
        fromValue={value => (value ? new Date(value).getTime() : 0)}
        value={
          range ?? {
            from: minRange,
            to: calculateRangeTo(minRange, maxRange),
          }
        }
        onChange={onChangeRange}
        renderLabel={renderRangeLabel}
      />
    </Flex>
  );
};

RangeSliderComponent.propTypes = {
  minRange: PropTypes.any,
  maxRange: PropTypes.any,
  priceCurvesData: PropTypes.array,
  updateRangedData: PropTypes.func,
  timeseriesType: PropTypes.string,
};

export { RangeSliderComponent };
