import { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Flex, Button, Ribbon, DataTable } from 'library/components';

import { useStaticCall } from 'hooks/useStaticCalls';
import staticCalls from 'utils/staticCalls.json';

const PriceCurveCatalogue = ({ addSelectedPriceCurves, filteredData }) => {
  const [sortBy, setSortBy] = useState(null);
  const [columnType, setColumnType] = useState('desc');
  const [selectedPriceCurve, setSelectedPriceCurve] = useState([]);
  const [addSelection, setAddSelection] = useState(true);

  const unitsData = useStaticCall(staticCalls.PRICINGUNITS);

  const catalogueColumns = useMemo(
    () =>
      [
        {
          id: 'source',
          name: 'Source',
          field: 'source',
          sort: true,
        },
        {
          id: 'name',
          name: 'Name',
          field: 'name',
          sort: true,
          show: columnType === 'name',
        },
        {
          id: 'description',
          name: 'Description',
          field: 'description',
          sort: true,
          show: columnType === 'desc',
        },
        {
          id: 'unit',
          name: 'Unit',
          function: row => unitsData?.find(d => d.code === row.unit)?.name,
          sort: true,
        },
        { id: 'currency', name: 'Currency', field: 'currency', sort: true },
      ].filter(x => !!x),
    [unitsData, columnType],
  );

  const onAddClick = () => {
    addSelectedPriceCurves(selectedPriceCurve);
    setSelectedPriceCurve([]);
  };

  const onSelectedPriceCurveChange = row => {
    setSelectedPriceCurve(row.slice(0, 20));
    setAddSelection(row.length > 0 ? false : true);
  };

  return (
    <>
      <Flex row>
        <Button onClick={onAddClick} disabled={addSelection}>
          Add to Selection
        </Button>
        <Flex grow />
        <Ribbon value={columnType} onChange={value => setColumnType(value)}>
          <Ribbon.Item value="name">Name</Ribbon.Item>
          <Ribbon.Item value="desc">Desc</Ribbon.Item>
        </Ribbon>
      </Flex>
      <Flex row grow shrink overflow="auto" alignItems="stretch">
        <DataTable
          columns={catalogueColumns}
          data={filteredData}
          selection={selectedPriceCurve}
          onSelectionChange={onSelectedPriceCurveChange}
          sortBy={sortBy}
          onChangeSortBy={value => setSortBy(value)}
        />
      </Flex>
    </>
  );
};

PriceCurveCatalogue.propTypes = {
  addSelectedPriceCurves: PropTypes.func,
  filteredData: PropTypes.array,
};

export { PriceCurveCatalogue };
