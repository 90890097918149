import granularityType from './granularityType.json';

const granularityData = [
  { code: granularityType.CODE.DAILY, name: granularityType.NAME.DAILY },
  { code: granularityType.CODE.WEEK, name: granularityType.NAME.WEEK },
  {
    code: granularityType.CODE.HALF_MONTH,
    name: granularityType.NAME.HALF_MONTH,
  },
  { code: granularityType.CODE.MONTH, name: granularityType.NAME.MONTH },
  { code: granularityType.CODE.QUARTER, name: granularityType.NAME.QUARTER },
  { code: granularityType.CODE.SEASON, name: granularityType.NAME.SEASON },
  { code: granularityType.CODE.YEAR, name: granularityType.NAME.YEAR },
  {
    code: granularityType.CODE.GAS_YEAR,
    name: granularityType.NAME.GAS_YEAR,
  },
];

export { granularityData };
