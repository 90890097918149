import classNames from 'classnames/bind';
import { useStaticCall } from '../../hooks/useStaticCalls';
import { Label, Select } from '../../library/components';
import staticCalls from '../../utils/staticCalls.json';

import styles from './index.module.scss';
const cx = classNames.bind(styles);

const PriceTypeSelect = ({ value, onChange }: { value: string; onChange: (val: string) => void }) => {
  const priceTypesData = useStaticCall(staticCalls.PRICETYPES);

  return (
    <>
      <Label className={cx('label')}>Price Type</Label>
      <Select value={value ?? null} onChange={onChange}>
        <Select.Item value={null}>All Price Types</Select.Item>
        {priceTypesData?.map(priceType => (
          <Select.Item key={priceType.code} value={priceType.code}>
            {priceType.name}
          </Select.Item>
        ))}
      </Select>
    </>
  );
};

export { PriceTypeSelect };
