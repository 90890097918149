import CloseIcon from '@mui/icons-material/Close';

import { Button } from '../Button';
import styles from './index.module.scss';
import classNames from 'classnames/bind';

import PropTypes from 'prop-types';

const cx = classNames.bind(styles);

const CloseButton = ({ onClick }) => {
  return (
    <Button onClick={onClick} className={cx('close-button')}>
      <CloseIcon fontSize="small" />
    </Button>
  );
};

CloseButton.propTypes = {
  onClick: PropTypes.func,
};

export { CloseButton };
