import { useState } from 'react';
import PropTypes from 'prop-types';
import { DataTable, Ribbon, TextInput } from '../../../library/components';
import DeleteIcon from '@mui/icons-material/Delete';

import { useStaticCall } from 'hooks/useStaticCalls';
import staticCalls from 'utils/staticCalls.json';

import { FxDefinitionModal } from '../../shared/FxDefinitionModal';
import { ReportingUnitComponent } from '../../shared/ReportingUnitComponent';
import { ReportingCurrencyComponent } from '../../shared/ReportingCurrenyComponent';
import Tooltip from '@mui/material/Tooltip';

const useColumns = (formulaPriceCurveData, fxConversionData, setSelectedPriceCurve, onDeleteRow, setFxList) => {
  return [
    {
      id: 'source',
      name: 'Source',
      function: row => row.data.source,
      sort: true,
    },
    {
      id: 'curve',
      name: 'Curve',
      input: {
        value: row => {
          return { name: row.data.name, description: row.data.description };
        },
        render: value => (
          <div>
            <div>{value.name}</div>
            <div>{value.description}</div>
          </div>
        ),
      },
    },
    {
      id: 'reportingUnit',
      name: 'Reporting Unit',
      type: 'enum',
      input: {
        value: row => row.commodity.unit,
        onChange: (row, value) => {
          row.data.reportingUnit = value;
          row.commodity.unit = value;
        },
        render: (value, row, onChange) => {
          const commodity = formulaPriceCurveData?.find(priceDef => priceDef.data.name === row.curve.name)?.data
            ?.commodity;
          return <ReportingUnitComponent value={value} onChange={onChange} commodity={commodity} />;
        },
      },
    },
    {
      id: 'reportingCurrency',
      name: 'Reporting Currency',
      type: 'enum',
      input: {
        value: row => {
          return {
            currency: row.data.currency,
            selectedCurrency: row.data.reportingCurrency ?? row.data.currency,
            fx: row.fxConversion,
          };
        },
        onChange: (row, value) => {
          if (value !== row.data.currency) {
            const fxData = fxConversionData
              .filter(
                data =>
                  (data.from === row.data.currency && data.to === value) ||
                  (data.from === value && data.to === row.data.currency),
              )
              .map(data => {
                if (data.from === row.data.currency) {
                  return { ...data, inverse: false };
                } else {
                  return { ...data, inverse: true };
                }
              });
            if (fxData.length > 1) {
              setFxList(fxData);
              const selectedPriceCurveData = formulaPriceCurveData.find(a => a.data.name === row.data.name)?.data;
              setSelectedPriceCurve(selectedPriceCurveData);
            } else {
              row.fxConversion = {
                source: fxData[0].source,
                priceDefinitionName: fxData[0].name,
                isInversed: fxData[0].inverse,
              };
              row.data.reportingCurrency = value;
            }
          } else {
            row.fxConversion = null;
            row.data.reportingCurrency = value;
          }
        },
        render: (value, row, onChange) => {
          return <ReportingCurrencyComponent value={value} row={row} onChange={onChange} />;
        },
      },
    },
    {
      id: 'coefficient',
      name: 'Coefficient',
      field: 'coefficient',
      input: {
        value: row => +row.commodity.coefficient ?? 1,
        onChange: (row, value) => (row.commodity.coefficient = isNaN(value) || !isFinite(value) ? '' : +value),
        render: (value, row, onChange) => {
          return (
            <Tooltip title={value === 0 ? 'Coefficient value should not be 0' : ''} arrow placement="left">
              <div>
                <TextInput onChange={onChange} value={value} />
              </div>
            </Tooltip>
          );
        },
      },
      type: 'float',
    },
    {
      id: 'operator',
      name: 'Operator',
      field: 'operator',
      input: {
        value: row => row.operator ?? '',
        onChange: (row, value) => (row.operator = value),
        render: (value, row, onChange) => (
          <Ribbon value={value ?? ''} onChange={onChange}>
            <Ribbon.Item value="+">+</Ribbon.Item>
            <Ribbon.Item value="-">-</Ribbon.Item>
          </Ribbon>
        ),
      },
    },
    {
      id: 'delete',
      name: '',
      input: {
        value: row => row,
        onChange: row => onDeleteRow(row.data.id),
        render: (_, row, onChange) => (
          <div onClick={onChange}>
            <DeleteIcon />
          </div>
        ),
      },
    },
  ];
};

const FormulaPriceCurveTable = ({ formulaPriceCurveData, onDeleteRow }) => {
  const [fxList, setFxList] = useState([]);
  const [selectedPriceCurve, setSelectedPriceCurve] = useState([]);
  const [reRender, setReRender] = useState(false);

  const fxConversionData = useStaticCall(staticCalls.FXDEFINITION);

  const selectedColumns = useColumns(
    formulaPriceCurveData,
    fxConversionData,
    setSelectedPriceCurve,
    onDeleteRow,
    setFxList,
  );

  const reRenderTable = fxConversion => {
    const selectedPriceCurveData = formulaPriceCurveData.find(a => a.data.name === selectedPriceCurve.name);
    selectedPriceCurveData.fxConversion = fxConversion;
    setFxList([]);
    setReRender(!reRender);
  };

  return (
    <>
      <DataTable columns={selectedColumns} data={formulaPriceCurveData} />
      {fxList.length > 0 && (
        <FxDefinitionModal
          item={fxList}
          curve={selectedPriceCurve}
          show={!!fxList.length}
          onHideModal={() => setFxList([])}
          reRenderTable={reRenderTable}
        />
      )}
    </>
  );
};

FormulaPriceCurveTable.propTypes = {
  formulaPriceCurveData: PropTypes.array,
  onDeleteRow: PropTypes.func,
};

export { FormulaPriceCurveTable };
