import { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-bootstrap/Modal';
import { Button, CloseButton, DataTable, Flex } from 'library/components';
import { useStaticCall } from 'hooks/useStaticCalls';
import staticCalls from 'utils/staticCalls.json';
import moment from 'moment';

import styles from './AddModal.module.scss';
import classNames from 'classnames/bind';

const cx = classNames.bind(styles);

const AddModal = ({ show, onHideModal, curves, onSavedData }) => {
  const [filteredData, setFilteredData] = useState([]);
  const [sortBy, setSortBy] = useState(['source', 'name']);

  const data = useStaticCall(staticCalls.PRICEDEFINITION);
  const commoditiesData = useStaticCall(staticCalls.COMMODITIES);

  useEffect(() => {
    if (!data?.length) {
      setFilteredData([]);
      return;
    }

    let filteredData = data;

    filteredData = filteredData.filter(row => {
      const curve = curves.find(curve => curve.source === row.source && curve.priceDefinitionName === row.name);

      return !curve;
    });

    setFilteredData(filteredData);
  }, [curves, data]);

  const columns = useMemo(
    () => [
      { id: 'source', name: 'Source', field: 'source', sort: true },
      { id: 'name', name: 'Name', field: 'name', sort: true },
      { id: 'unit', name: 'Unit', field: 'unit', sort: true },
      { id: 'currency', name: 'Currency', field: 'currency', sort: true },
      {
        id: 'commodity',
        name: 'Commodity',
        function: row => commoditiesData?.find(d => d.code === row.commodity)?.name,
        sort: true,
      },
      {
        id: 'granularity',
        name: 'Granularity',
        field: 'granularity',
        render: value => moment.duration(value).humanize(),
        sort: true,
      },
      {
        id: 'description',
        name: 'Description',
        field: 'description',
        sort: true,
      },
    ],
    [commoditiesData],
  );

  const [selection, setSelection] = useState([]);

  const onSelectionChange = value => setSelection(value);

  const onClickAdd = () => {
    curves.splice(
      curves.length,
      0,
      ...selection.map(row => ({
        priceDefinitionName: row.name,
        reportingCurrency: row.currency,
        reportingUnit: row.unit,
        source: row.source,
      })),
    );

    onSavedData?.();
    onHideModal?.();
  };

  const onChangeSortBy = value => setSortBy(value);

  return (
    <Modal show={show} onHide={onHideModal} centered>
      <Modal.Header>
        <Modal.Title>Add Price Curves</Modal.Title>
        <CloseButton onClick={onHideModal} />
      </Modal.Header>
      <Modal.Body className={cx('modal-body')}>
        <Flex column grow shrink alignItems="stretch" overflow="auto" className={cx('table')}>
          <DataTable
            columns={columns}
            data={filteredData}
            selection={selection}
            onSelectionChange={onSelectionChange}
            sortBy={sortBy}
            onChangeSortBy={onChangeSortBy}
          />
        </Flex>
      </Modal.Body>
      <Flex row justifyContent="flex-end">
        <Button onClick={onClickAdd}>Add</Button>
      </Flex>
    </Modal>
  );
};

AddModal.propTypes = {
  show: PropTypes.bool,
  onHideModal: PropTypes.func,
  curves: PropTypes.array,
  onSavedData: PropTypes.func,
};

export { AddModal };
