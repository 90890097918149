import React from 'react';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';

import PropTypes from 'prop-types';

import { ReactComponent as SpinnerIcon } from './spinner.svg';

import styles from './index.module.scss';
import classNames from 'classnames/bind';

const cx = classNames.bind(styles);

const ButtonSplit = ({ selectedInitialIndex = 0, disabled = false, loading = false, menuItems, className }) => {
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);
  const [selectedIndex, setSelectedIndex] = React.useState(selectedInitialIndex);
  const handleMenuItemClick = (event, index) => {
    setSelectedIndex(index);
    setOpen(false);
  };
  const handleToggle = () => {
    if (!disabled && !loading) {
      setOpen(prevOpen => !prevOpen);
    }
  };
  const handleClick = () => {
    if (!disabled && !loading) {
      menuItems[selectedIndex]['action']();
    }
  };
  const handleClose = event => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  return (
    <React.Fragment>
      <ButtonGroup
        variant="contained"
        ref={anchorRef}
        aria-label="split button"
        className={cx('buttonGroup', className)}>
        <Button area-label="primary label" onClick={handleClick} disabled={disabled}>
          {menuItems[selectedIndex]['label']}
          {loading && <SpinnerIcon className={cx('spinner')} />}
        </Button>
        <Button
          size="small"
          aria-controls={open ? 'split-button-menu' : undefined}
          aria-expanded={open ? 'true' : undefined}
          aria-label="select merge strategy"
          aria-haspopup="menu"
          onClick={handleToggle}
          disabled={disabled}>
          <ArrowDropDownIcon />
        </Button>
      </ButtonGroup>
      <Popper open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal>
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
            }}>
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList id="split-button-menu" className={cx('buttonGroup', { disabled })}>
                  {menuItems.map(({ label, disabled: disabledItem }, index) => (
                    <MenuItem
                      key={label}
                      disabled={disabledItem}
                      selected={index === selectedIndex}
                      onClick={event => handleMenuItemClick(event, index)}>
                      {label}
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </React.Fragment>
  );
};

ButtonSplit.propTypes = {
  selectedInitialIndex: PropTypes.number,
  menuItems: PropTypes.arrayOf(PropTypes.object),
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
  className: PropTypes.string,
};

export { ButtonSplit };
