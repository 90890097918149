import { useState } from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-bootstrap/Modal';
import { Button, CloseButton, Flex, Label, TextInput } from 'library/components';
import classNames from 'classnames/bind';
import styles from './CreateModal.module.scss';
import { apiFetch } from 'utils/apiService';
import { useMutation } from 'react-query';
import { useAuth } from 'react-oidc-context';
const cx = classNames.bind(styles);

const CreateModal = ({ show, onHideModal }) => {
  const [name, setName] = useState('');

  const onChangeName = value => setName(value);
  const { user } = useAuth();
  const { mutateAsync: createPriceProfile } = useMutation(
    priceProfile =>
      apiFetch({
        method: 'POST',
        url: `/experience/PriceProfile`,
        token: user?.access_token,
        body: priceProfile,
      }),
    {
      queryKey: `POST /experience/PriceProfile`,
    },
  );

  const onClickCreate = async () => {
    await createPriceProfile({
      profileName: name,
      curves: [],
      tags: [],
    });
    onHideModal?.();
  };

  return (
    <Modal show={show} onHide={onHideModal} centered>
      <Modal.Header>
        <Modal.Title>Create Price Profile</Modal.Title>
        <CloseButton onClick={onHideModal} />
      </Modal.Header>
      <Modal.Body>
        <Flex row alignItems="center">
          <Label className={cx('label')}>Name </Label>
          <TextInput value={name} onChange={onChangeName} />
        </Flex>
        <Flex row justifyContent="flex-end" className={cx('button-bar')}>
          <Button onClick={onClickCreate}>Create</Button>
        </Flex>
      </Modal.Body>
    </Modal>
  );
};

CreateModal.propTypes = {
  show: PropTypes.bool,
  onHideModal: PropTypes.func,
};

export { CreateModal };
