import { useState, useEffect } from 'react';
import { Flex } from 'library/components';
import { useSnackbar } from 'notistack';
import { useAuth } from 'react-oidc-context';
import { useQuery, useQueries } from 'react-query';
import { useStaticCall } from 'hooks/useStaticCalls';
import { useFxConversionList } from 'hooks/useFxConversionList';
import staticCalls from 'utils/staticCalls.json';
import { getFXConversion, getProcessedData } from 'utils/common';
import { formatISODate } from 'utils/formatDate';
import interpolationType from 'utils/interpolationType.json';

import { CatalogueFilter } from './CatalogueFilter';
import { CurveDateRange } from './CurveDateRange';
import { CurveConversions } from '../shared/CurveConversions';
import { PriceCurveTable } from '../shared/PriceCurveTable';
import { PriceCurveViewer } from '../shared/PriceCurveViewer';
import contractTypeJson from './CurveDateRange/contractType.json';

import styles from './index.module.scss';
import classNames from 'classnames/bind';
import { apiFetch } from 'utils/apiService';

const cx = classNames.bind(styles);

const HistoricalProjection = () => {
  const [filters, setFilters] = useState({
    priceProfile: null,
    source: null,
    commodity: null,
    search: '',
  });
  const [publicationDate, setPublicationDate] = useState({
    start: new Date(Date.now() - 30 * 24 * 3600_000),
    end: new Date(Date.now() - 24 * 3600_000),
  });
  const [contractType, setContractType] = useState(contractTypeJson.ABS_YEAR);
  const [contractName, setContractName] = useState(null);
  const [contractDate, setContractDate] = useState({
    start: new Date(),
    end: new Date(),
  });
  const [granularity, setGranularity] = useState('Daily');
  const [processedData, setProcessedData] = useState([]);
  const [selectedPriceCurve, setSelectedPriceCurve] = useState([]);
  const [conversion, setConversion] = useState({
    reportingUnit: null,
    reportingCurrency: null,
  });

  const priceDefinitionData = useStaticCall(staticCalls.PRICEDEFINITION);
  const commoditiesData = useStaticCall(staticCalls.COMMODITIES);
  const fxConversionData = useStaticCall(staticCalls.FXDEFINITION);

  const fxConversionList = useFxConversionList();

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const { user } = useAuth();
  const { data: priceProfileData } = useQuery({
    queryKey: ['/experience/PriceProfile'],
    queryFn: ({ queryKey: [url] }) => apiFetch({ url, token: user?.access_token }),
    enabled: !!user?.access_token,
  });

  const params = selectedPriceCurve.map(priceDef => {
    const { reportingCurrency, reportingUnit } = conversion;
    const fxDef = getFXConversion(priceDef, fxConversionData, reportingCurrency);

    let fx = {};
    let pathType, extraPayload;

    if (fxDef?.length > 0) {
      fx = {
        'FXRequest.DefinitionName': fxDef[0].name,
        'FXRequest.Source': fxDef[0].source,
        'FXRequest.Inverse': fxDef[0].inverse,
      };
    }

    if (contractName?.type.includes('abs')) {
      pathType = 'Absolute';
      extraPayload = {
        CurveDateStart: formatISODate(contractDate.start),
        CurveDateEnd: formatISODate(contractDate.end),
      };
    } else if (contractName?.type.includes('rel')) {
      pathType = 'Relative';
      extraPayload = {
        RelativePeriod: contractName?.name,
      };
    }

    const params = {
      ...extraPayload,
      PublicationDateStart: formatISODate(publicationDate.start),
      PublicationDateEnd: formatISODate(publicationDate.end),
      Unit: reportingUnit ?? priceDef.profile?.reportingUnit ?? priceDef.unit,
      DefinitionName: priceDef.name,
      Source: priceDef.source,
      ...fx,
      'Granularity.GranularityType': granularity,
      'Granularity.InterpolationTypes': interpolationType.LINEAR,
    };

    return {
      queryKey: [`/core/HistoricalAnalysis/${pathType}`, params, user?.access_token],
      queryFn: ({ queryKey: [url] }) =>
        apiFetch({
          url,
          params,
          token: user?.access_token,
        }),
      enabled: !!user?.access_token,
      retry: false,
      onError: err => {
        const errorMessage = `${priceDef.name} (${priceDef.source}): ${err || 'Uknown error happened'}`;
        const key = enqueueSnackbar(errorMessage, {
          variant: 'error',
          preventDuplicate: true,
          onClick: () => {
            closeSnackbar(key);
          },
        });
      },
    };
    /*
     onErrorCallback: errResponse => {
        const parsedErrorRes = JSON.parse(errResponse);
        if (parsedErrorRes?.status === 404) {
          return { error: parsedErrorRes?.title };
        }
        
        return { error: parsedErrorRes?.errors[''][0] };
      },
      */
  });

  const resArray = useQueries(params);
  const priceCurvesData = resArray.map(res => res.data);
  const loading = resArray.findIndex(res => res.isLoading) >= 0;
  const error = resArray.find(res => res.isError)?.error;

  useEffect(() => {
    const processedData = getProcessedData(priceDefinitionData, filters, conversion, commoditiesData, fxConversionList);
    setProcessedData(processedData);
  }, [priceDefinitionData, filters, conversion]);

  const onChange = (key, value) => setFilters(state => ({ ...state, [key]: value }));

  const onPublicationDateChange = (key, value) => setPublicationDate(state => ({ ...state, [key]: value }));

  const onSelectedPriceCurveChange = row => setSelectedPriceCurve(row.slice(0, 10));

  const onConversionChange = (key, value) => {
    setConversion(state => ({ ...state, [key]: value }));
  };

  const onContractTypeChange = value => {
    setContractType(value);
  };

  const onContractNameChange = value => {
    setContractName(value);
    if (contractType?.includes('abs')) {
      setContractDate({
        start: new Date(value?.year, value?.month, 1),
        end: new Date(value?.year, value?.month + value?.monthsCount, 0),
      });
    }
  };

  const onGranularityChange = value => {
    setGranularity(value);
  };

  return (
    <Flex row grow shrink alignItems="stretch" overflow="hidden">
      <Flex column alignItems="stretch" className={cx('left-panel')}>
        <CurveDateRange
          publicationDate={publicationDate}
          onPublicationDateChange={onPublicationDateChange}
          contractType={contractType}
          onContractTypeChange={onContractTypeChange}
          contractName={contractName}
          onContractNameChange={onContractNameChange}
          granularity={granularity}
          onGranularityChange={onGranularityChange}
        />
        <CurveConversions conversion={conversion} onChange={onConversionChange} fxConversionList={fxConversionList} />
        <CatalogueFilter priceProfileData={priceProfileData} filters={filters} onChange={onChange} />
      </Flex>

      <Flex column grow shrink alignItems="stretch" className={cx('middle-panel')}>
        <PriceCurveTable
          filters={filters}
          conversion={conversion}
          processedPriceDefinitionsData={processedData}
          selectedPriceDefinitions={selectedPriceCurve}
          onPriceDefinitonsSelectionChange={onSelectedPriceCurveChange}
          fxConversionData={fxConversionData}
          type="historic"
        />
      </Flex>

      <PriceCurveViewer
        priceCurvesData={priceCurvesData}
        loading={loading}
        error={error}
        conversion={conversion}
        timeseriesType="publicationDate"
      />
    </Flex>
  );
};

export { HistoricalProjection };
