import { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as CalendarIcon } from './calendar.svg';
import { Calendar } from '../Calendar';
import { formatDate } from 'utils/formatDate';

import styles from './index.module.scss';
import classNames from 'classnames/bind';

const cx = classNames.bind(styles);

const DateSelect = ({ value, onChange, min, max, bizDayOnly = false }) => {
  const [show, setShow] = useState(false);
  const wrapper = useRef();

  const onClickLabel = () => {
    setShow(show => !show);
  };

  const onChangeCalendar = value => {
    setShow(false);
    onChange?.(value);
  };

  useEffect(() => {
    if (show) {
      const handler = event => {
        if (event.target.closest(`.${cx('wrapper')}`) !== wrapper.current) {
          setShow(false);
        }
      };

      window.addEventListener('mousedown', handler, false);
      return () => window.removeEventListener('mousedown', handler, false);
    }
    return undefined;
  }, [show]);

  return (
    <div ref={wrapper} className={cx('wrapper')}>
      <div className={cx('container')} onClick={onClickLabel}>
        <span className={cx('date-label')}>{formatDate(value)}</span>
        <CalendarIcon />
      </div>
      {show && (
        <div className={cx('drop-down')}>
          <Calendar value={value} onChange={onChangeCalendar} min={min} max={max} bizDayOnly={bizDayOnly} />
        </div>
      )}
    </div>
  );
};

DateSelect.propTypes = {
  value: PropTypes.instanceOf(Date),
  min: PropTypes.instanceOf(Date),
  max: PropTypes.instanceOf(Date),
  onChange: PropTypes.func,
  bizDayOnly: PropTypes.bool,
};

export { DateSelect };
