import React, { useState, useEffect } from 'react';
import { useAuth } from 'react-oidc-context';
import { useQueries, useQuery } from 'react-query';
import { formatISODate } from 'utils/formatDate';
import { DataTable, Flex, Label } from 'library/components';
import moment from 'moment';
import { CatalogueFilter } from './CatalogueFilter';
import { apiFetch } from 'utils/apiService';

import styles from './index.module.scss';
import classNames from 'classnames/bind';

const cx = classNames.bind(styles);

const arrayHasValue = arr => {
  return !(!arr || arr.length === 0);
};

const shiftSundayToFriday = _date => (moment(_date).isoWeekday() === 7 ? moment(_date).subtract(2, 'days') : _date);
const isWeekend = _date => [6, 7].includes(moment(_date).isoWeekday());

const CurveAvailabilityCheck = () => {
  const [filters, setFilters] = useState({
    granularity: 'Month',
    priceProfile: null,
    source: null,
    priceDefinitions: [],
    dateType: '1m',
    excludeWeekEnd: false,
    adjustEODDateForWeekEnd: false,
    startDate: moment(new Date(Date.now()), 'utc').add(-30, 'day').startOf('day').toDate(),
    endDate: moment(new Date(Date.now()), 'utc').startOf('day').toDate(),
  });

  const onFilterChange = targetFiters => {
    setFilters(state => ({ ...state, ...targetFiters }));
  };

  const [sortBy, setSortBy] = useState(null);
  const { user } = useAuth();

  const {
    data: priceDefinitionsData,
    isLoading: priceDefinitionsLoading,
    error: priceDefinitionsError,
  } = useQuery({
    queryKey: ['/Raw/PriceDefinition'],
    queryFn: ({ queryKey: [url] }) => apiFetch({ url, token: user?.access_token }),
    enabled: !!user?.access_token,
  });

  const { data: priceProfileData } = useQuery({
    queryKey: ['/experience/PriceProfile'],
    queryFn: ({ queryKey: [url] }) => apiFetch({ url, token: user?.access_token }),
    enabled: !!user?.access_token,
  });

  const queryParam = filters.priceDefinitions.map(key => {
    const [_source, ..._nameArray] = key.split('|');
    return {
      queryKey: ['/raw/PriceCurve/list', key],
      queryFn: ({ queryKey: [url] }) =>
        apiFetch({
          url,
          params: {
            Source: _source,
            PriceDefinitionName: _nameArray.join('|'),
          },
          token: user?.access_token,
        }),
      enabled: !!user?.access_token,
    };
  });

  const resArray = useQueries(queryParam);
  const priceCurvesLoading = resArray.findIndex(res => res.isLoading) >= 0;
  const priceCurvesError = resArray.find(res => res.isError)?.error;
  const priceCurvesData = resArray.map(res => res.data);
  const onChangeSortBy = value => setSortBy(value);

  const [columns, setColumns] = useState([]);
  useEffect(() => {
    const columnArray = [
      {
        id: 'publicationDate',
        name: 'Publication Date',
        field: 'publicationDate',
        render: value => formatISODate(value),
        sort: true,
      },
    ];
    filters.priceDefinitions.forEach(key => {
      const [_source, ..._nameArray] = key.split('|');
      columnArray.push({
        id: key,
        name: `[${_source}] ${_nameArray.join('|')}`,
        field: key,
        render: value => (value ? moment(value).format('HH:mm:SS') : '-'),
        sort: false,
      });
    });
    setColumns(columnArray);
  }, [filters.priceDefinitions]);

  const groupedData = React.useMemo(() => {
    if (!arrayHasValue(priceDefinitionsData) || !arrayHasValue(priceCurvesData)) {
      return [];
    }

    const priceDefDictionary = priceDefinitionsData.reduce((acc, { source, name, description }) => {
      acc[`${source}|${name}`] = description;
      return acc;
    }, {});
    const priceCurveDictionary = priceCurvesData
      .filter(x => !!x)
      .flat()
      .reduce((acc, { source, priceDefinitionName: name, ingestionDate, publicationDate }) => {
        const pastIngestionDate = acc[`${source}|${name}|${formatISODate(new Date(publicationDate))}`]?.ingestionDate;
        const newIngestionDate = new Date(ingestionDate);
        const maxIngestionDate =
          !pastIngestionDate || pastIngestionDate < ingestionDate ? newIngestionDate : pastIngestionDate;
        acc[`${source}|${name}|${formatISODate(new Date(publicationDate))}`] = {
          ingestionDate: maxIngestionDate,
          publicationDate: new Date(publicationDate),
          source,
          name,
          description: priceDefDictionary[`${source}|${name}`],
        };
        return acc;
      }, {});

    const combinedData = Object.values(priceCurveDictionary);
    const aggData = {};
    const momentDate = moment(filters.startDate);
    while (momentDate.isBefore(moment(filters.endDate))) {
      const key = formatISODate(momentDate.toDate());
      aggData[key] = { publicationDate: momentDate.toDate() };
      momentDate.add(1, 'days');
    }
    combinedData
      .map(({ publicationDate, source, ...rest }) => {
        const _publicationDate =
          source === 'Aligne' && filters.adjustEODDateForWeekEnd
            ? shiftSundayToFriday(publicationDate)
            : publicationDate;
        return { ...rest, source, publicationDate: _publicationDate };
      })
      .filter(({ publicationDate }) => publicationDate >= filters.startDate && publicationDate <= filters.endDate)
      .reduce((acc, { publicationDate, ingestionDate, source, name }) => {
        const key = formatISODate(publicationDate);
        acc[key] = {
          ...acc[key],
          publicationDate,
          [`${source}|${name}`]: ingestionDate,
        };
        return acc;
      }, aggData);
    return Object.entries(aggData)
      .sort((a, b) => a[0].localeCompare(b[0]))
      .filter(([key]) => !filters.excludeWeekEnd || (filters.excludeWeekEnd && !isWeekend(key)))
      .map(val => val[1]);
  }, [
    priceDefinitionsData,
    priceCurvesData,
    filters.startDate,
    filters.endDate,
    filters.adjustEODDateForWeekEnd,
    filters.excludeWeekEnd,
  ]);

  return (
    <Flex row alignItems="stretch" grow shrink overflow="hidden">
      <CatalogueFilter filters={filters} onChange={onFilterChange} priceProfileData={priceProfileData} />
      <Flex column alignItems="stretch" grow shrink overflow="hidden">
        <Flex column alignItems="stretch" grow shrink overflow="auto" className={cx('main')}>
          {filters.priceDefinitions && filters.priceDefinitions.length > 0 && (
            <DataTable
              loading={priceDefinitionsLoading || priceCurvesLoading}
              error={priceDefinitionsError ?? priceCurvesError}
              columns={columns}
              data={groupedData}
              sortBy={sortBy}
              onChangeSortBy={onChangeSortBy}
              className={cx('data-table')}
            />
          )}
          {(!filters.priceDefinitions || !filters.priceDefinitions.length) && (
            <Label>Please select Curve(s) or Profile to be review</Label>
          )}
        </Flex>
      </Flex>
    </Flex>
  );
};

export { CurveAvailabilityCheck };
