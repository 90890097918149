import PropTypes from 'prop-types';
import { Flex, Label } from 'library/components';

import styles from './index.module.scss';
import classNames from 'classnames/bind';

const cx = classNames.bind(styles);

const Panel = ({ title, children }) => {
  return (
    <Flex column alignItems="stretch" className={cx('panel')}>
      <Flex row className={cx('panel-title-container')}>
        <Label className={cx('panel-title')}>{title}</Label>
      </Flex>
      <Flex column className={cx('panel-content')}>
        {children}
      </Flex>
    </Flex>
  );
};

Panel.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node,
};

export { Panel };
