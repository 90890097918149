import React, { createContext, useContext, useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as DropDownIcon } from './dropdown.svg';
import { ReactComponent as CheckIcon } from './check.svg';

import OrderedUUID from 'ordered-uuid';

import styles from './index.module.scss';
import classNames from 'classnames/bind';
const cx = classNames.bind(styles);

const SelectContext = createContext();

const MultiSelect = ({ className, value, onChange, children }) => {
  const [isOpen, setOpen] = useState(false);
  const [searchFocused, setSearchFocused] = useState(false);
  const [search, setSearch] = useState('');
  const [searchTerms, setSearchTerms] = useState([]);
  const [additionalItems, setAdditionalItems] = useState([]);
  const container = useRef();

  children = React.Children.toArray(children);
  children.push(
    ...additionalItems
      .filter(item => {
        if (children.find(({ type, props }) => type === MultiSelectItem && props.value === item)) {
          return false;
        }

        return true;
      })
      .map(item => (
        <MultiSelectItem value={item} key={OrderedUUID.generate()}>
          {item}
        </MultiSelectItem>
      )),
  );

  let options = React.Children.toArray(children);
  options = options.filter(({ type }) => type === MultiSelectItem);
  options = options.map(({ props }) => props);

  const currentOptions = options.filter(({ value: v }) => value?.includes(v));

  const onClickSelect = () => {
    setOpen(isOpen => !isOpen);
  };

  const onClickSelectAll = () => {
    if (searchTerms.length) {
      onChange?.(options.filter(({ children }) => searchFilter(children)).map(({ value }) => value));
    } else {
      onChange?.(options.map(({ value }) => value));
    }
  };

  const onClickDeselectAll = () => {
    onChange?.([]);
  };

  const onChangeSearch = event => {
    setSearch(event.target.value);
    const terms = event.target.value.trim().toLowerCase().split(/\s+/g);
    setSearchTerms(terms);
  };

  const onFocusSearch = () => {
    setSearchFocused(true);
  };

  const onBlurSearch = () => {
    setSearchFocused(false);
  };

  const onKeyDown = event => {
    if (event.which === 0x0d) {
      const v = event.target.value.trim().replace(/\s+/g, ' ');
      if (!options?.find(({ value: v1 }) => v1 === v)) {
        const addedItems = additionalItems.slice();
        addedItems.push(v);
        setAdditionalItems(addedItems);
      }
      value = value?.slice() ?? [];
      value.push(v);
      onChange?.(value);
      setSearch('');
    }
  };

  useEffect(() => {
    if (isOpen) {
      const handler = event => {
        if (event.target.closest(`.${cx('container')}`) !== container.current) {
          setOpen(false);
        }
      };

      window.addEventListener('mousedown', handler, false);
      return () => window.removeEventListener('mousedown', handler, false);
    }
    return undefined;
  }, [isOpen]);

  const searchFilter = children => {
    const label = children.toLowerCase().split(/[_-\s]+/g);
    for (const s of label) {
      for (const term of searchTerms) {
        if (s.startsWith(term)) {
          return true;
        }
      }
    }
    return false;
  };

  if (search.trim()) {
    children = React.Children.toArray(children).filter(child => {
      if (child.type === MultiSelectItem) {
        return searchFilter(child.props.children);
      } else {
        return true;
      }
    });
  }

  return (
    <SelectContext.Provider value={{ selected: value, onChange, setOpen }}>
      <div ref={container} className={cx('container')}>
        <span className={cx('select', className)} onClick={onClickSelect}>
          <span>
            {currentOptions
              .map(option => option.children)
              .map((children, i) => (
                <span className={cx('tag')} key={`${i}`}>
                  {children}
                </span>
              ))}
            <input
              type="text"
              value={search}
              onChange={onChangeSearch}
              onFocus={onFocusSearch}
              onBlur={onBlurSearch}
              onKeyDown={onKeyDown}
            />
          </span>
          <DropDownIcon />
        </span>
        {(searchFocused || isOpen) && (
          <div className={cx('options')}>
            <div className={cx('scroll')}>{children}</div>
            <div className={cx('toolbar')}>
              <div className={cx('select-all-button')} onClick={onClickSelectAll}>
                Select All
              </div>
              <div className={cx('deselect-all-button')} onClick={onClickDeselectAll}>
                Deselect All
              </div>
            </div>
          </div>
        )}
      </div>
    </SelectContext.Provider>
  );
};

MultiSelect.propTypes = {
  value: PropTypes.arrayOf(PropTypes.any),
  onChange: PropTypes.func.isRequired,
  children: PropTypes.node,
  className: PropTypes.string,
};

const MultiSelectItem = ({ value, children }) => {
  const { selected, onChange } = useContext(SelectContext);

  const onClick = () => {
    const sel = selected?.slice() ?? [];
    if (sel.includes(value)) {
      const index = sel.indexOf(value);
      sel.splice(index, 1);
    } else {
      sel.push(value);
    }
    onChange?.(sel);
  };

  return (
    <div onClick={onClick} className={cx('option', { selected: selected?.includes(value) })}>
      {children}

      {selected?.includes(value) && <CheckIcon />}
    </div>
  );
};

MultiSelectItem.propTypes = {
  value: PropTypes.any,
  children: PropTypes.node,
};

MultiSelect.Item = MultiSelectItem;

export { MultiSelect, MultiSelectItem };
