import React from 'react';
import { Select, Option } from '../Select';
import { Flex } from '../Flex';

import styles from './index.module.scss';
import classNames from 'classnames/bind';

import PropTypes from 'prop-types';

const cx = classNames.bind(styles);

const TreeSelect = ({ value, onChange, children, splitter, joiner, disabled }) => {
  const items = React.Children.toArray(children).filter(({ type }) => type === TreeSelectItem);

  const itemValues = items.map(({ props: { value } }) => {
    return splitter?.(value) ?? [];
  });

  const values = splitter?.(value)?.filter(x => !!x) ?? [];

  const possibleValues = itemValues.filter(array => {
    for (let i = 0; i < values.length; i++) {
      if (array[i] !== values[i]) {
        return false;
      }
    }
    return true;
  });

  if (possibleValues.find(x => x.length > values.length)) {
    values.push(null);
  }

  const _onChange = (i, value) => {
    const v = values.slice(0, i);
    v.push(value);
    onChange?.(joiner?.(v));
  };

  return (
    <Flex grow row alignItems="center">
      {values.map((v, i) => (
        <Flex
          basis={`${100 / values.length}%`}
          grow
          shrink
          nowrap
          column
          alignItems="stretch"
          className={cx('column')}
          key={`${i}`}>
          <Select value={v} disabled={disabled} onChange={value => _onChange(i, value)}>
            {Array.from(
              new Set(
                itemValues
                  .filter(array => {
                    for (let j = 0; j < i; j++) {
                      if (array[j] !== values[j]) {
                        return false;
                      }
                    }

                    return true;
                  })
                  .map(x => x[i]),
              ),
            ).map(value => (
              <Option value={value} key={value}>
                {value}
              </Option>
            ))}
          </Select>
        </Flex>
      ))}
    </Flex>
  );
};

const TreeSelectItem = () => {
  return null;
};

TreeSelect.Item = TreeSelectItem;

TreeSelect.propTypes = {
  value: PropTypes.any,
  onChange: PropTypes.func,
  children: PropTypes.node,
  splitter: PropTypes.func,
  joiner: PropTypes.func,
  disabled: PropTypes.bool,
};

export { TreeSelect, TreeSelectItem };
