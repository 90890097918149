import PropTypes from 'prop-types';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';

import classNames from 'classnames/bind';
import styles from './toggle.module.scss';
const cx = classNames.bind(styles);

export function DateToggle({ dateType, setDateType }) {
  const handleChange = (event, newDateType) => {
    if (newDateType !== null) {
      setDateType(newDateType);
    }
  };

  return (
    <ToggleButtonGroup
      value={dateType}
      exclusive
      onChange={handleChange}
      aria-label="date type"
      className={cx('wrapper')}>
      <ToggleButton value="ytd" aria-label="year to date">
        ytd
      </ToggleButton>
      <ToggleButton value="1m" aria-label="past month">
        1m
      </ToggleButton>
      <ToggleButton value="6m" aria-label="6 months">
        6m
      </ToggleButton>
      <ToggleButton value="1y" aria-label="1 year">
        1y
      </ToggleButton>
      <ToggleButton value="other" aria-label="other">
        other
      </ToggleButton>
    </ToggleButtonGroup>
  );
}

DateToggle.propTypes = {
  dateType: PropTypes.string,
  setDateType: PropTypes.func,
};
