import { useState, useEffect } from 'react';
import jwt_decode from 'jwt-decode';
import { User } from 'oidc-client-ts';

const useAuthorize = (isAuthenticated: boolean, jWToken: User | any) => {
  const [authorized, setAuthorized] = useState<string>('');

  useEffect(() => {
    if (isAuthenticated) {
      const decodedAccessToken: IAccessToken = jwt_decode(jWToken.access_token || '') || null;
      if (
        decodedAccessToken.realm_access.roles.includes('sep-user') ||
        decodedAccessToken.realm_access.roles.includes('sep-lead')
      ) {
        setAuthorized('true');
      } else {
        setAuthorized('false');
      }
    }
  }, [isAuthenticated, jWToken]);

  return authorized;
};

export { useAuthorize };
