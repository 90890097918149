import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Flex, Label, Select, TextInput } from 'library/components';

import { useStaticCall } from 'hooks/useStaticCalls';
import staticCalls from 'utils/staticCalls.json';

import styles from './index.module.scss';
import classNames from 'classnames/bind';

const cx = classNames.bind(styles);

const CatalogueFilter = ({ priceProfileData, filters, onChange }) => {
  const priceDefinitionData = useStaticCall(staticCalls.PRICEDEFINITION);
  const unitsData = useStaticCall(staticCalls.PRICINGUNITS);
  const commoditiesData = useStaticCall(staticCalls.COMMODITIES);
  const currencyData = useStaticCall(staticCalls.CURRENCIES);

  const [sources, setSources] = useState([]);
  const [units, setUnits] = useState([]);
  const [currencies, setCurrencies] = useState([]);

  useEffect(() => {
    if (!priceDefinitionData) {
      setSources([]);
      setUnits([]);
      setCurrencies([]);
      return;
    }
    setSources(Array.from(new Set(priceDefinitionData.map(row => row.source))));

    // Units filter
    const rowUnits = Array.from(new Set(priceDefinitionData.map(row => row.unit)));
    const unitList = unitsData?.filter(unit => rowUnits.includes(unit.code));
    setUnits(unitList);

    // Currency filter
    const rowCurrencies = Array.from(new Set(priceDefinitionData.map(row => row.currency)));
    const currencyList = currencyData?.filter(currency => rowCurrencies.includes(currency.code));
    setCurrencies(currencyList);
  }, [currencyData, priceDefinitionData, unitsData]);

  return (
    <Flex column alignItems="stretch" overflow-y="auto" className={cx('filter')}>
      <Label className={cx('label')}>Price Profile:</Label>
      <Select value={filters.priceProfile} onChange={value => onChange('priceProfile', value)}>
        <Select.Item value={null}>(none)</Select.Item>
        {priceProfileData?.map(profile => (
          <Select.Item value={profile} key={profile.id}>
            {profile.profileName}
          </Select.Item>
        ))}
      </Select>

      <Label className={cx('label')}>Source</Label>
      <Select value={filters.source ?? null} onChange={value => onChange('source', value)}>
        <Select.Item value={null}>All Sources</Select.Item>
        {sources.map(source => (
          <Select.Item key={source} value={source}>
            {source}
          </Select.Item>
        ))}
      </Select>

      <Label className={cx('label')}>Unit</Label>
      <Select value={filters.unit ?? null} onChange={value => onChange('unit', value)}>
        <Select.Item value={null}>All Units</Select.Item>
        {units?.map(unit => (
          <Select.Item key={unit.code} value={unit.code}>
            {unit.name} - {unit.code}
          </Select.Item>
        ))}
      </Select>

      <Label className={cx('label')}>Currency</Label>
      <Select value={filters.currency ?? null} onChange={value => onChange('currency', value)}>
        <Select.Item value={null}>All Currencies</Select.Item>
        {currencies?.map(currency => (
          <Select.Item key={currency.code} value={currency.code}>
            {currency.name} - {currency.code}
          </Select.Item>
        ))}
      </Select>

      <Label className={cx('label')}>Commodity</Label>
      <Select value={filters.commodity ?? null} onChange={value => onChange('commodity', value)}>
        <Select.Item value={null}>All Commodities</Select.Item>
        {commoditiesData?.map(commodity => (
          <Select.Item key={commodity.code} value={commodity.code}>
            {commodity.group} - {commodity.name}
          </Select.Item>
        ))}
      </Select>

      <Label className={cx('label')}>Price Definition Search:</Label>
      <TextInput value={filters.search} onChange={value => onChange('search', value)} placeholder="Search..." />
    </Flex>
  );
};

CatalogueFilter.propTypes = {
  priceProfileData: PropTypes.array,
  onChange: PropTypes.func,
  filters: PropTypes.shape({
    priceProfile: PropTypes.object,
    source: PropTypes.string,
    unit: PropTypes.string,
    currency: PropTypes.string,
    commodity: PropTypes.string,
    search: PropTypes.string,
  }),
};

export { CatalogueFilter };
