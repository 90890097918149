import { useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { formatISODate, formatMonthYear, formatDayOfMonth, formatDayOfWeek } from 'utils/formatDate';

import { ReactComponent as ChevronLeftDoubleIcon } from './chevron-left-double.svg';
import { ReactComponent as ChevronLeftIcon } from './chevron-left.svg';
import { ReactComponent as ChevronRightIcon } from './chevron-right.svg';
import { ReactComponent as ChevronRightDoubleIcon } from './chevron-right-double.svg';

import styles from './index.module.scss';
import classNames from 'classnames/bind';

const cx = classNames.bind(styles);

const calculateMonth = date => {
  const weeks = [[]];

  const end = moment(date, 'utc').startOf('day').endOf('month').endOf('isoweek').toDate();

  const i = moment(date, 'utc').startOf('day').startOf('month').startOf('isoweek');

  for (; i.toDate() <= end; i.add(1, 'day')) {
    if (weeks[weeks.length - 1].length === 7) {
      weeks.push([]);
    }
    const week = weeks[weeks.length - 1];
    week.push(i.toDate());
  }

  return weeks;
};

const isDateInMonth = (date, value) => {
  const end = moment(value, 'utc').startOf('day').endOf('month').toDate();

  const start = moment(value, 'utc').startOf('day').startOf('month').toDate();

  return date >= start && date <= end;
};

const isDateInRange = (date, { from, to }, bizDayOnly = false) => {
  return (
    (!from || date >= from) && (!to || date <= to) && (!bizDayOnly || (bizDayOnly && moment(date).isoWeekday() < 6))
  );
};

const Calendar = ({ value, onChange, min, max, bizDayOnly = false }) => {
  value = moment(value, 'utc').startOf('day').toDate();
  min = min && moment(min, 'utc').startOf('day').toDate();
  max = max && moment(max, 'utc').startOf('day').toDate();

  const [start, setStart] = useState(() => moment(value, 'utc').startOf('month').toDate());

  const weeks = calculateMonth(start);

  const onClickBackYear = () => {
    if (min && moment(start, 'utc').add(-1, 'year').toDate() < min) {
      return;
    }

    setStart(start => moment(start, 'utc').add(-1, 'year').toDate());
  };

  const onClickBackMonth = () => {
    if (min && moment(start, 'utc').add(-1, 'month').endOf('month').toDate() < min) {
      return;
    }

    setStart(start => moment(start, 'utc').add(-1, 'month').toDate());
  };

  const onClickNextMonth = () => {
    if (max && moment(start, 'utc').add(1, 'month').startOf('month').toDate() > max) {
      return;
    }

    setStart(start => moment(start, 'utc').add(1, 'month').toDate());
  };

  const onClickNextYear = () => {
    if (max && moment(start, 'utc').add(1, 'year').startOf('month').toDate() > max) {
      return;
    }

    setStart(start => moment(start, 'utc').add(1, 'year').toDate());
  };

  const onClickDayOfMonth = date => {
    if (isDateInMonth(date, start) && isDateInRange(date, { from: min, to: max })) {
      onChange(date);
    } else {
      if (isDateInRange(date, { from: min, to: max })) {
        setStart(moment(date, 'utc').startOf('month').toDate());
      }
    }
  };

  return (
    <div className={cx('container')}>
      <div className={cx('header')}>
        <ChevronLeftDoubleIcon
          onClick={onClickBackYear}
          className={cx({
            disabled: !isDateInRange(
              moment(start, 'utc').add(-1, 'year').endOf('month').toDate(),
              { from: min, to: max },
              bizDayOnly,
            ),
          })}
        />
        <ChevronLeftIcon
          onClick={onClickBackMonth}
          className={cx({
            disabled: !isDateInRange(
              moment(start, 'utc').add(-1, 'month').endOf('month').toDate(),
              { from: min, to: max },
              bizDayOnly,
            ),
          })}
        />
        <div className={cx('month-year')}>{formatMonthYear(start)}</div>
        <ChevronRightIcon
          onClick={onClickNextMonth}
          className={cx({
            disabled: !isDateInRange(moment(start, 'utc').add(1, 'month').toDate(), { from: min, to: max }, bizDayOnly),
          })}
        />
        <ChevronRightDoubleIcon
          onClick={onClickNextYear}
          className={cx({
            disabled: !isDateInRange(moment(start, 'utc').add(1, 'year').toDate(), { from: min, to: max }, bizDayOnly),
          })}
        />
      </div>
      <div className={cx('weekdays')}>
        {weeks[0].map((day, i) => (
          <div className={cx('weekday')} key={i}>
            {formatDayOfWeek(day)}
          </div>
        ))}
      </div>
      {weeks.map((week, i) => (
        <div className={cx('week')} key={i}>
          {week.map((day, j) => (
            <div
              className={cx('day', {
                'in-month': isDateInMonth(day, start),
                disabled: !isDateInRange(day, { from: min, to: max }, bizDayOnly),
              })}
              key={j}
              onClick={() => onClickDayOfMonth(day)}
              data-testid={`day-${formatISODate(day)}`}>
              {formatDayOfMonth(day)}
            </div>
          ))}
        </div>
      ))}
    </div>
  );
};

Calendar.propTypes = {
  value: PropTypes.instanceOf(Date),
  min: PropTypes.instanceOf(Date),
  max: PropTypes.instanceOf(Date),
  onChange: PropTypes.func,
  bizDayOnly: PropTypes.bool,
};

export { Calendar };
