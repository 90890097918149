import React, { createContext, useContext } from 'react';
import PropTypes from 'prop-types';
import { useEmbedComponent } from 'hooks/useEmbedComponent';

import styles from './index.module.scss';
import classNames from 'classnames/bind';

const cx = classNames.bind(styles);

const RibbonContext = createContext();

const Ribbon = ({ value, onChange, children }) => {
  const { embedded } = useEmbedComponent();

  let items = React.Children.toArray(children);
  items = items.filter(({ type }) => type === RibbonItem);

  return (
    <RibbonContext.Provider value={{ selected: value, onChange }}>
      <div className={cx('ribbon', { embedded })}>{items}</div>
    </RibbonContext.Provider>
  );
};

Ribbon.propTypes = {
  value: PropTypes.any,
  onChange: PropTypes.func,
  children: PropTypes.node,
};

const RibbonItem = ({ value, children }) => {
  const { selected, onChange } = useContext(RibbonContext);

  const onClick = () => {
    onChange?.(value);
  };

  return (
    <div className={cx('item', { selected: value === selected })} onClick={onClick}>
      {children}
    </div>
  );
};

RibbonItem.propTypes = {
  value: PropTypes.any,
  children: PropTypes.node,
};

Ribbon.Item = RibbonItem;

export { Ribbon, RibbonItem };
