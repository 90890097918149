import { createContext, useContext } from 'react';
import { useQuery } from 'react-query';
import { useAuth } from 'react-oidc-context';
import PropTypes from 'prop-types';
import { apiFetch } from 'utils/apiService';

const PermissionsContext = createContext();

const PermissionsProvider = ({ children }) => {
  const { user } = useAuth();
  const { data: permissions } = useQuery({
    queryKey: ['/api/MyPermissions'],
    queryFn: ({ queryKey: [url] }) => apiFetch({ url, token: user?.access_token }),
    enabled: !!user?.access_token,
  });
  const permissionsList = {};
  permissions?.forEach(data => {
    const splitValue = data.split('.');
    const permissionType = splitValue[0];
    const permissionValue = splitValue[1];
    permissionsList[permissionType] = permissionsList[permissionType]
      ? [...permissionsList[permissionType], permissionValue]
      : [permissionValue];
  });

  const allowedPermissions = type => {
    const list = { read: false, write: false };
    if (Object.prototype.hasOwnProperty.call(permissionsList, type)) {
      if (permissionsList[type].includes('read')) {
        list.read = true;
      }
      if (permissionsList[type].includes('write')) {
        list.write = true;
      }
    }
    return list;
  };

  return <PermissionsContext.Provider value={allowedPermissions}>{children}</PermissionsContext.Provider>;
};

PermissionsProvider.propTypes = {
  children: PropTypes.node,
};

const usePermissions = type => useContext(PermissionsContext)(type);

const IfPermission = ({ children, type, check }) => {
  const permissions = usePermissions(type);
  return permissions[check] ? children : null;
};

IfPermission.propTypes = {
  children: PropTypes.node,
  type: PropTypes.any,
  check: PropTypes.any,
};

const ElsePermission = ({ children, type, check }) => {
  const permissions = usePermissions(type);
  return !permissions[check] ? children : null;
};

ElsePermission.propTypes = {
  children: PropTypes.node,
  type: PropTypes.any,
  check: PropTypes.any,
};

export { usePermissions, PermissionsProvider, IfPermission, ElsePermission };
