const formatInteger = (() => {
  const formatter = new Intl.NumberFormat('en-GB', {
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  });

  return value => formatter.format(value);
})();

const formatNumber = (() => {
  const formatters = {};

  return (value, decimalPlaces = 2) => {
    if (!formatters[decimalPlaces]) {
      formatters[decimalPlaces] = new Intl.NumberFormat('en-GB', {
        minimumFractionDigits: decimalPlaces,
        maximumFractionDigits: decimalPlaces,
      });
    }

    const formatter = formatters[decimalPlaces];
    return formatter.format(value);
  };
})();

export { formatInteger, formatNumber };
