type ApiRequestType = { url: string; token?: string } & (
  | {
      params?: Record<string, string>;
      method?: 'GET';
    }
  | {
      body?: any;
      method: 'POST' | 'PUT' | 'DELETE';
    }
  | {
      method: 'DELETE';
    }
);

const getFetchOptions = (request: ApiRequestType): RequestInit => {
  if (request.method === 'POST' || request.method === 'PUT') {
    return {
      method: request.method,
      headers: {
        Authorization: `Bearer ${request.token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(request.body),
    };
  }
  return {
    method: request.method,
    headers: {
      Authorization: `Bearer ${request.token}`,
    },
  };
};

export async function apiFetch(request: ApiRequestType) {
  const { url, token } = request;
  let _url = `${process.env.REACT_APP_API_URL}${url}`;
  if (!token) {
    throw new Error('Token is missing.');
  }
  if ((!request.method || request.method === 'GET') && !!request.params) {
    const searchParams = new URLSearchParams(request.params);
    _url = `${_url}?${searchParams.toString()}`;
  }
  const response = await fetch(_url, getFetchOptions(request));
  if (!response.ok) {
    const err = await response.text();
    throw new Error('API call has failed', { cause: err });
  }
  try {
    return await response.json();
  } catch (e) {
    throw new Error('Failed while reading the API response.', { cause: e });
  }
}

export async function putQuery<T>({ url, token, body }: { url: string; body?: T; token?: string }) {
  const _url = `${process.env.REACT_APP_API_URL}${url}`;
  if (!token) {
    throw new Error('Token is missing.');
  }
  const response = await fetch(_url, {
    method: 'PUT',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(body),
  });
  if (!response.ok) {
    const err = await response.text();
    throw new Error('API call has failed', { cause: err });
  }
  try {
    return await response.json();
  } catch (e) {
    throw new Error('Failed while reading the API response.', { cause: e });
  }
}
