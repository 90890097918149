import HelpIcon from '@mui/icons-material/Help';
import { Button, DataTable, Flex, Label, Ribbon } from 'library/components';
import PropTypes from 'prop-types';
import { useEffect, useMemo, useState } from 'react';

import { useDebounce } from 'hooks/useDebounce';
import { useStaticCall } from 'hooks/useStaticCalls';
import { getFXParams, getSearchedText } from 'utils/common';
import { formatDateTime } from 'utils/formatDate';
import lineColors from 'utils/lineColors.json';
import staticCalls from 'utils/staticCalls.json';
import { MoreDetailsModal } from './MoreDetailsModal';

import classNames from 'classnames/bind';
import styles from './index.module.scss';

const cx = classNames.bind(styles);

const getReportingValues = (conversion, row) => {
  const { reportingCurrency, reportingUnit } = conversion;
  let rowReportingCurrency = reportingCurrency;
  let rowReportingUnit = reportingUnit;
  if (row?.profile) {
    rowReportingCurrency = reportingCurrency ? reportingCurrency : row?.profile?.reportingCurrency;
    rowReportingUnit = reportingUnit ? reportingUnit : row?.profile?.reportingUnit;
  }
  return { rowReportingCurrency, rowReportingUnit };
};

const PriceCurveTable = ({
  type,
  filters,
  showUnit = true,
  conversion,
  showCurrency = true,
  onSelectClick,
  disableAddButton,
  fxConversionData,
  displayAddButton,
  selectedPriceDefinitions,
  processedPriceDefinitionsData,
  onPriceDefinitonsSelectionChange,
}) => {
  const [sortBy, setSortBy] = useState(null);
  const [selectedRow, setSelectedRow] = useState({});
  const [columnType, setColumnType] = useState('desc');
  const [showMoreDetails, setShowMoreDetails] = useState(false);
  const [filteredPriceDefinitionsData, setFilteredPriceDefinitionsData] = useState([]);

  const debouncedSearch = useDebounce(filters.search, 300);
  const commoditiesData = useStaticCall(staticCalls.COMMODITIES);

  useEffect(() => {
    const filteredData = getSearchedText(processedPriceDefinitionsData, debouncedSearch);
    setFilteredPriceDefinitionsData(filteredData);
  }, [processedPriceDefinitionsData, debouncedSearch]);

  const columns = useMemo(
    () => [
      {
        id: 'color',
        name: '',
        function: row => {
          const index = selectedPriceDefinitions.indexOf(row);
          if (index === -1) {
            return null;
          }
          return lineColors[index];
        },
        render: color => {
          return <div className={cx('legend')} style={{ backgroundColor: color }} />;
        },
        backgroundColor: 'transparent',
        sort: true,
      },
      {
        id: 'source',
        name: 'Source',
        field: 'source',
        sort: true,
      },
      {
        id: 'name',
        name: 'Name',
        field: 'name',
        sort: true,
        show: columnType === 'name',
      },
      {
        id: 'desc',
        name: 'Description',
        field: 'description',
        sort: true,
        show: columnType === 'desc',
      },
      {
        id: 'ingestionDate',
        name: 'Ingestion Date',
        function: row => row?.priceCurves && row?.priceCurves[0]?.ingestionDate,
        render: value => (value ? formatDateTime(new Date(value)) : 'N/A'),
        sort: true,
        show: type === 'future' && filters.live,
      },
      {
        id: 'unit',
        name: 'Unit',
        function: row => conversion.reportingUnit ?? row.profile?.reportingUnit ?? row.unit,
        show: showUnit,
        sort: true,
      },
      {
        id: 'currency',
        name: 'Currency',
        function: row => conversion.reportingCurrency ?? row.profile?.reportingCurrency ?? row.currency,
        show: showCurrency,
        sort: true,
      },
      {
        id: 'commodity',
        name: 'Commodity',
        function: row => commoditiesData?.find(d => d.code === row.commodity)?.name,
        sort: true,
        show: type === 'historic',
      },
      {
        id: 'details',
        name: 'Details',
        input: {
          value: row => {
            return row;
          },
          onChange: row => {
            if (row) {
              const fxDetails = getFXParams(row, fxConversionData, conversion, filters);
              const { rowReportingCurrency, rowReportingUnit } = getReportingValues(conversion, row);
              const val = {
                ...row,
                fxDetails,
                reportingCurrency: rowReportingCurrency,
                reportingUnit: rowReportingUnit,
              };
              setShowMoreDetails(true);
              setSelectedRow(val);
            }
          },
          render: (value, row, onChange) => {
            return (
              <div className={cx('details-container')}>
                <div onClick={onChange} className={cx('details', { selected: row?.color })}>
                  <HelpIcon />
                </div>
              </div>
            );
          },
        },
      },
    ],
    [
      columnType,
      type,
      filters,
      showUnit,
      showCurrency,
      selectedPriceDefinitions,
      conversion,
      commoditiesData,
      fxConversionData,
    ],
  );

  const onChangeSortBy = value => setSortBy(value);
  const onHideMoreDetailsModal = () => setShowMoreDetails(false);

  return (
    <>
      <Flex row alignItems="center" className={cx('box', 'box-row')}>
        <Label className={cx('label')}>Price Curves:</Label>
        <Flex grow />
        <Ribbon value={columnType} onChange={value => setColumnType(value)}>
          <Ribbon.Item value="name">Name</Ribbon.Item>
          <Ribbon.Item value="desc">Desc</Ribbon.Item>
        </Ribbon>
      </Flex>
      {displayAddButton ? (
        <Flex row alignItems="center" className={cx('box', 'box-row')}>
          <Button onClick={onSelectClick} className={cx('button')} disabled={disableAddButton}>
            Add to Selection
          </Button>
        </Flex>
      ) : (
        <></>
      )}
      <Flex grow column shrink overflow="auto" alignItems="stretch" className={cx('box', 'box-column')}>
        <DataTable
          sortBy={sortBy}
          columns={columns}
          onChangeSortBy={onChangeSortBy}
          data={filteredPriceDefinitionsData}
          selection={selectedPriceDefinitions}
          onSelectionChange={onPriceDefinitonsSelectionChange}
        />
      </Flex>
      {showMoreDetails && (
        <MoreDetailsModal show={showMoreDetails} selected={selectedRow} onHideModal={onHideMoreDetailsModal} />
      )}
    </>
  );
};

PriceCurveTable.propTypes = {
  type: PropTypes.string,
  showUnit: PropTypes.bool,
  filters: PropTypes.object,
  showCurrency: PropTypes.bool,
  conversion: PropTypes.object,
  displayAddButton: PropTypes.bool,
  fxConversionData: PropTypes.array,
  selectedPriceDefinitions: PropTypes.array,
  processedPriceDefinitionsData: PropTypes.array,
  onPriceDefinitonsSelectionChange: PropTypes.func,
  onSelectClick: PropTypes.func,
  disableAddButton: PropTypes.bool,
};

export { PriceCurveTable };
