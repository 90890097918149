import { useEffect, useState } from 'react';
import { Flex, Label, TextInput, Button, DataTable } from 'library/components';
import { useHistory } from 'react-router';

import { useDebounce } from 'hooks/useDebounce';
import { useStaticCall } from 'hooks/useStaticCalls';
import staticCalls from 'utils/staticCalls.json';
import { getSearchedText, getProcessedData } from 'utils/common';

import { CatalogueFilter } from '../../shared/CatalogueFilter';
import { FormulaPriceCurveTable } from '../FormulaPriceCurveTable';
import { PriceCurveCatalogue } from '../PriceCurveCatalogue';

import styles from './index.module.scss';
import classNames from 'classnames/bind';

import { useAuth } from 'react-oidc-context';
import { useMutation } from 'react-query';
import { apiFetch } from 'utils/apiService';
import { useSnackbar } from 'notistack';

const cx = classNames.bind(styles);

const headerColumns = [
  { id: 'unit', name: 'Unit', field: 'unit', sort: true },
  { id: 'currency', name: 'Currency', field: 'currency', sort: true },
  { id: 'commodity', name: 'Commodity', field: 'commodity', sort: true },
  {
    id: 'granularity',
    name: 'Granularity',
    field: 'granularity',
    sort: true,
  },
];

const CreateFormula = () => {
  const history = useHistory();
  const { id, name, description, unit, currency, commodity, granularity, fixedAdder, curves } = history.location.state;
  const headerData = [history.location.state];

  const [filters, setFilters] = useState({
    priceProfile: null,
    source: null,
    unit: null,
    currency: null,
    commodity: null,
    search: '',
  });
  const [processedData, setProcessedData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [formulaPriceCurveData, setFormulaPriceCurveData] = useState([]);
  const [inputFixedAdder, setInputFixedAdder] = useState(fixedAdder || 0);

  const priceDefinitionData = useStaticCall(staticCalls.PRICEDEFINITION);
  const fxConversionData = useStaticCall(staticCalls.FXDEFINITION);

  const debouncedSearch = useDebounce(filters.search, 300);
  const { user } = useAuth();
  const { enqueueSnackbar } = useSnackbar();

  const { mutate: priceFormulaRequest } = useMutation(
    priceFormula =>
      apiFetch({
        method: 'POST',
        url: `/raw/PriceFormula`,
        token: user?.access_token,
        body: priceFormula,
      }),
    {
      queryKey: `POST /raw/PriceFormula`,
      onSuccess: () => onBackClick(),
      onError: error => {
        enqueueSnackbar(`An error has occured while saving: ${error}`, {
          variant: 'error',
          preventDuplicate: true,
        });
      },
    },
  );
  const { mutate: priceFormulaUpdateRequest } = useMutation(
    priceFormula =>
      apiFetch({
        method: 'PUT',
        url: `/raw/PriceFormula/${priceFormula.id}`,
        token: user?.access_token,
        body: priceFormula,
      }),
    {
      queryKey: `POST /raw/PriceFormula/{formulaId}`,
      onSuccess: () => onBackClick(),
      onError: error => {
        enqueueSnackbar(`An error has occured while saving: ${error}`, {
          variant: 'error',
          preventDuplicate: true,
        });
      },
    },
  );

  useEffect(() => {
    const processedData = getProcessedData(priceDefinitionData, filters);
    setProcessedData(processedData);
  }, [priceDefinitionData, filters]);

  useEffect(() => {
    const filteredData = getSearchedText(processedData, debouncedSearch);
    setFilteredData(filteredData);
  }, [processedData, debouncedSearch]);

  useEffect(() => {
    let curveData = [];
    curves?.map(curve => {
      const { commodity, fxConversion } = curve;
      const existingSelectedPriceDef = filteredData.find(data => data.name === commodity.priceDefinitionName);

      if (existingSelectedPriceDef) {
        if (fxConversion) {
          const fxData = fxConversionData?.find(
            data => data.name === fxConversion.priceDefinitionName && data.source === fxConversion.source,
          );
          existingSelectedPriceDef['reportingCurrency'] = fxConversion.isInversed ? fxData.from : fxData.to;
        } else {
          existingSelectedPriceDef['reportingCurrency'] = null;
        }

        curveData = [...curveData, { data: existingSelectedPriceDef, ...curve }];
      }
    });
    if (formulaPriceCurveData.length === 0) {
      setFormulaPriceCurveData(curveData);
    }
  }, [curves, filteredData, formulaPriceCurveData.length, fxConversionData]);

  const addSelectedPriceCurves = selectedPriceCurve => {
    const curvesList = selectedPriceCurve.map(data => {
      return {
        data,
        commodity: {
          source: data.source,
          priceDefinitionName: data.name,
          unit: data.unit,
          coefficient: 1,
        },
        fxConversion: null,
        operator: '+',
      };
    });
    const newCurvesList = [...new Set([...formulaPriceCurveData, ...curvesList])];
    setFormulaPriceCurveData(newCurvesList);
  };

  const onFilterChange = (key, value) => setFilters(state => ({ ...state, [key]: value }));

  const onDeleteRow = id => {
    const index = formulaPriceCurveData.findIndex(el => el.data.id === id);
    const updatedSelectedData = [...formulaPriceCurveData];
    updatedSelectedData.splice(index, 1);
    setFormulaPriceCurveData(updatedSelectedData);
  };

  const onSaveClick = async () => {
    const curvesCopy = [...formulaPriceCurveData];
    const curves = curvesCopy.map(curve => {
      return { ...curve, data: undefined };
    });
    const payload = {
      name,
      description,
      unit,
      currency,
      commodity,
      granularity,
      curves,
      fixedAdder: inputFixedAdder,
    };
    if (id) {
      priceFormulaUpdateRequest({ id, ...payload });
    } else {
      priceFormulaRequest(payload);
    }
  };

  const onBackClick = () => {
    history.push({ pathname: '/price-catalogue/formula' });
  };

  const convertDigits = val => {
    if (isNaN(val)) {
      return 0;
    } else if (val.indexOf('.') !== -1) {
      val = val.toString();
      val = val.slice(0, val.indexOf('.') + 6);
    }
    return val;
  };
  return (
    <Flex row grow shrink alignItems="stretch" overflow="hidden">
      <Flex column alignItems="stretch" className={cx('left-panel')}>
        <CatalogueFilter filters={filters} onChange={onFilterChange} />
      </Flex>
      <Flex column grow shrink alignItems="stretch" className={cx('middle-panel')}>
        <PriceCurveCatalogue addSelectedPriceCurves={addSelectedPriceCurves} filteredData={filteredData} />
      </Flex>
      <Flex column className={cx('right-panel')}>
        <Flex row justifyContent="flex-end" className={cx('button-bar')}>
          <Button onClick={onBackClick}>Back</Button>
          <Button onClick={onSaveClick}>Save</Button>
        </Flex>
        <Flex row>
          <Label className={cx('label')}>Name</Label>
          <TextInput value={name} disabled />
        </Flex>
        <Flex row>
          <Label className={cx('label')}>Description</Label>
          <TextInput value={description} disabled />
        </Flex>
        <Flex row className={cx('header-table')}>
          <DataTable columns={headerColumns} data={headerData} />
        </Flex>
        <Flex row>
          <Label className={cx('label')}>Fixed Adder</Label>
          <TextInput
            value={inputFixedAdder}
            onChange={value => setInputFixedAdder(convertDigits(value))}
            maxLength={11}
          />
        </Flex>
        <Flex column grow shrink overflow="auto" alignItems="stretch">
          <FormulaPriceCurveTable formulaPriceCurveData={formulaPriceCurveData} onDeleteRow={onDeleteRow} />
        </Flex>
      </Flex>
    </Flex>
  );
};

export { CreateFormula };
