import PropTypes from 'prop-types';
import { Select } from 'library/components';

import { useStaticCall } from 'hooks/useStaticCalls';
import staticCalls from 'utils/staticCalls.json';

const ReportingUnitComponent = ({ value, onChange, commodity }) => {
  const unitsData = useStaticCall(staticCalls.PRICINGUNITS);
  const commoditiesData = useStaticCall(staticCalls.COMMODITIES);
  const commodityDetail = commoditiesData?.filter(data => data.code === commodity);
  const filteredUnitsData = unitsData?.filter(unit =>
    commodityDetail[0]?.unitConversionFactors?.map(unitConversion => unitConversion?.unit)?.includes(unit.code),
  );
  return (
    <Select value={value} onChange={onChange}>
      {filteredUnitsData?.map(unit => (
        <Select.Item value={unit.code} key={unit.code}>
          {unit.code}
        </Select.Item>
      ))}
    </Select>
  );
};

ReportingUnitComponent.propTypes = {
  commodity: PropTypes.any,
  value: PropTypes.string,
  onChange: PropTypes.func,
};

export { ReportingUnitComponent };
