import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Flex, Label, Select, MultiSelect, Ribbon } from 'library/components';
import { Panel } from './panel';

import { useStaticCall } from 'hooks/useStaticCalls';
import staticCalls from 'utils/staticCalls.json';

import styles from './index.module.scss';
import classNames from 'classnames/bind';

const cx = classNames.bind(styles);

const filterItemClass = 'filter-item';
const filterControlClass = 'filter-control';

const SelectorRibbon = ({ value, onChange }) => (
  <Flex row className={cx(filterItemClass)}>
    <Label className={cx('label')}>Select By</Label>
    <Ribbon value={value} onChange={onChange}>
      <Ribbon.Item value={true}>Profile</Ribbon.Item>
      <Ribbon.Item value={false}>Individual Curves</Ribbon.Item>
    </Ribbon>
  </Flex>
);

const ProfileSelection = ({ filters, priceProfileData, onChange }) => {
  return (
    <Flex row className={cx(filterItemClass)}>
      <Label className={cx('label')}>Price Profile:</Label>
      <Select
        value={filters.priceProfile}
        onChange={value => {
          onChange({
            priceProfile: value,
            priceDefinitions: priceProfileData
              .find(({ profileName }) => profileName === value)
              .curves.map(({ priceDefinitionName: name, source }) => `${source}|${name}`),
          });
        }}
        className={cx(filterControlClass)}>
        <Select.Item value={null}>(none)</Select.Item>
        {priceProfileData?.map(profile => (
          <Select.Item value={profile.profileName} key={profile.id}>
            {profile.profileName}
          </Select.Item>
        ))}
      </Select>
    </Flex>
  );
};

const PriceCurveSelection = ({ filters, onChange }) => {
  const onChangeFilter = (key, value) => onChange({ [key]: value });
  const priceDefinitionData = useStaticCall(staticCalls.PRICEDEFINITION);
  const commoditiesData = useStaticCall(staticCalls.COMMODITIES);
  const [sources, setSources] = useState([]);

  useEffect(() => {
    if (!priceDefinitionData) {
      setSources([]);
      return;
    }
    setSources(Array.from(new Set(priceDefinitionData.map(row => row.source))));
  }, [priceDefinitionData]);

  return (
    <Flex column className={cx('filter-group')}>
      <Flex row className={cx(filterItemClass)}>
        <Label className={cx('label')}>Source</Label>
        <Select
          value={filters.source ?? null}
          onChange={value => onChangeFilter('source', value)}
          className={cx(filterControlClass)}>
          <Select.Item value={null}>All Sources</Select.Item>
          {sources.map(source => (
            <Select.Item key={source} value={source}>
              {source}
            </Select.Item>
          ))}
        </Select>
      </Flex>

      <Flex row className={cx(filterItemClass)}>
        <Label className={cx('label')}>Commodity</Label>
        <Select
          value={filters.commodity ?? null}
          onChange={value => onChangeFilter('commodity', value)}
          className={cx(filterControlClass)}>
          <Select.Item value={null}>All Commodities</Select.Item>
          {commoditiesData?.map(commodity => (
            <Select.Item key={commodity.code} value={commodity.code}>
              {commodity.group} - {commodity.name}
            </Select.Item>
          ))}
        </Select>
      </Flex>

      <Flex row className={cx(filterItemClass)}>
        <Label className={cx('label')}>Price Definition:</Label>
        <MultiSelect
          value={filters.priceDefinitions ?? null}
          onChange={value => onChangeFilter('priceDefinitions', value)}
          className={cx(filterControlClass)}>
          {priceDefinitionData?.map(pDef => (
            <MultiSelect.Item key={pDef.id} value={`${pDef.source}|${pDef.name}`}>
              {pDef.description}
            </MultiSelect.Item>
          ))}
        </MultiSelect>
      </Flex>
    </Flex>
  );
};

const CurveControlPanel = ({ priceProfileData, filters, onChange }) => {
  const [selectByProfileFlag, setSelectByProfileFlag] = useState(true);

  return (
    <Panel title="Curve Selection">
      <SelectorRibbon value={selectByProfileFlag} onChange={() => setSelectByProfileFlag(!selectByProfileFlag)} />
      {selectByProfileFlag ? (
        <ProfileSelection priceProfileData={priceProfileData} filters={filters} onChange={onChange} />
      ) : (
        <PriceCurveSelection filters={filters} onChange={onChange} />
      )}
    </Panel>
  );
};

const commonProps = {
  priceProfileData: PropTypes.array,
  onChange: PropTypes.func.isRequired,
  filters: PropTypes.shape({
    priceProfile: PropTypes.string,
    source: PropTypes.string,
    commodity: PropTypes.string,
    priceDefinitions: PropTypes.array,
    startDate: PropTypes.instanceOf(Date),
    endDate: PropTypes.instanceOf(Date),
    dateType: PropTypes.string,
  }).isRequired,
};
CurveControlPanel.propTypes = commonProps;
ProfileSelection.propTypes = commonProps;
PriceCurveSelection.propTypes = {
  onChange: commonProps.onChange,
  filters: commonProps.filters,
};
SelectorRibbon.propTypes = {
  onChange: commonProps.onChange,
  value: PropTypes.bool,
};

export { CurveControlPanel };
