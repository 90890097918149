import { useState, createContext, useContext } from 'react';
import { Button } from '../Button';
import MenuRoundedIcon from '@mui/icons-material/MenuRounded';
import MenuOpenRoundedIcon from '@mui/icons-material/MenuOpenRounded';

import styles from './index.module.scss';
import classNames from 'classnames/bind';

const cx = classNames.bind(styles);

import PropTypes from 'prop-types';

const HamburgerContext = createContext();

const HamburgerContainer = ({ className, children, renderSidebar, sidebarWidth, onOpen, onClose, open = false }) => {
  const [isOpen, setOpen] = useState(open);

  const onClickHamburger = () => {
    const open = !isOpen;

    if (open) {
      onOpen?.();
    } else {
      onClose?.();
    }

    setOpen(open);
  };

  return (
    <HamburgerContext.Provider value={{ isOpen, onClickHamburger }}>
      <div className={cx('container')}>
        {isOpen && (
          <div className={cx('sidebar-container')} style={{ width: sidebarWidth }}>
            <div
              className={cx('sidebar', { open: isOpen })}
              style={{
                transform: isOpen ? `translateX(0px)` : `translateX(${-sidebarWidth}px)`,
              }}>
              {renderSidebar?.()}
            </div>
          </div>
        )}
        <div className={cx('content', className, { open: isOpen })}>{children}</div>
      </div>
    </HamburgerContext.Provider>
  );
};

const HamburgerButton = ({ className }) => {
  const { isOpen, onClickHamburger } = useContext(HamburgerContext);

  return (
    <Button onClick={onClickHamburger} className={cx('button', className)}>
      {!isOpen && <MenuRoundedIcon />}
      {isOpen && <MenuOpenRoundedIcon />}
    </Button>
  );
};

const Hamburger = {
  Container: HamburgerContainer,
  Button: HamburgerButton,
};

HamburgerContainer.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  renderSidebar: PropTypes.func,
  sidebarWidth: PropTypes.number,
  onOpen: PropTypes.func,
  onClose: PropTypes.func,
  open: PropTypes.bool,
};

HamburgerButton.propTypes = {
  className: PropTypes.string,
};

export { Hamburger, HamburgerContainer, HamburgerButton };
