import { DataTable, Flex, Label, MultiSelect, Select, Tabs, TextInput } from 'library/components';
import classNames from 'classnames/bind';
import { FormulaCatalogue } from 'components/FormulaCatalogue';
import { CreateFormula } from 'components/FormulaCatalogue/CreateFormula';
import { FXCatalogue } from 'components/FXCatalogue';
import { PriceCurve } from 'components/PriceCurve';
import { useDebounce } from 'hooks/useDebounce';
import { useStaticCall } from 'hooks/useStaticCalls';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { Redirect, Route, Switch, useHistory, useLocation } from 'react-router';
import staticCalls from 'utils/staticCalls.json';
import { CurveAvailabilityCheck } from '../CurveAvailabilityCheck';
import styles from './index.module.scss';

import { useQuery } from 'react-query';
import { useAuth } from 'react-oidc-context';
import { apiFetch } from 'utils/apiService';
import { getProcessedData, getSearchedText } from 'utils/common';

const cx = classNames.bind(styles);

const Navigator = () => {
  const location = useLocation();
  const history = useHistory();

  const onChangeTab = value => history.push(value);

  return (
    <Flex row className={cx('box', 'box-row')}>
      <Tabs value={location.pathname} onChange={onChangeTab} position="bottom" matchPrefix>
        <Tabs.Item value={'/price-catalogue/commodity'}>Active Catalogue</Tabs.Item>
        <Tabs.Item value={'/price-catalogue/fx'}>FX Catalogue</Tabs.Item>
        <Tabs.Item value={'/price-catalogue/ingestion-log'}>Ingestion Logs</Tabs.Item>
        <Tabs.Item value={'/price-catalogue/curve-catalogue'}>Curve Data Integrity Check</Tabs.Item>
        <Tabs.Item value={'/price-catalogue/formula'}>Formula Catalogue</Tabs.Item>
      </Tabs>
    </Flex>
  );
};

const getActiveCatalogueColumns = (source, unitsData, commoditiesData, priceTypes) =>
  [
    {
      id: 'source',
      name: 'Source',
      field: 'source',
      sort: true,
    },
    source !== 'Aligne' && {
      id: 'name',
      name: 'Name',
      field: 'name',
      sort: true,
    },
    source === 'Aligne' && {
      id: 'market',
      name: 'Market',
      function: row => row.name.split(/\|/g)[0],
      sort: true,
    },
    source === 'Aligne' && {
      id: 'component',
      name: 'Component',
      function: row => row.name.split(/\|/g)[1],
      sort: true,
    },
    {
      id: 'unit',
      name: 'Unit',
      function: row => unitsData?.find(d => d.code === row.unit)?.name,
      sort: true,
    },
    { id: 'currency', name: 'Currency', field: 'currency', sort: true },
    {
      id: 'commodity',
      name: 'Commodity',
      function: row => commoditiesData?.find(d => d.code === row.commodity)?.name,
      sort: true,
    },
    {
      id: 'granularity',
      name: 'Granularity',
      function: row =>
        moment.duration(['T', 'P'].includes(row.granularity[0]) ? row.granularity : `P${row.granularity}`).humanize(),
      sort: true,
    },
    {
      id: 'priceType',
      name: 'Price Type',
      function: row => priceTypes?.find(d => d.code === row.priceType)?.name,
      sort: true,
    },
    {
      id: 'description',
      name: 'Description',
      field: 'description',
      sort: true,
    },
    {
      id: 'tags',
      name: 'Tags',
      field: 'tags',
      render: tags => (
        <Flex row wrap className={cx('tags')}>
          {tags.map((tag, i) => (
            <div className={cx('tag')} key={`${i}`}>
              {tag}
            </div>
          ))}
        </Flex>
      ),
    },
  ].filter(x => !!x);

const ActiveCatalogue = () => {
  const [sources, setSources] = useState([]);
  const [source, setSource] = useState(null);

  const [markets, setMarkets] = useState([]);
  const [market, setMarket] = useState(null);

  const [components, setComponents] = useState([]);
  const [component, setComponent] = useState(null);

  const [units, setUnits] = useState([]);
  const [unit, setUnit] = useState(null);

  const [currencies, setCurrencies] = useState([]);
  const [currency, setCurrency] = useState(null);

  const [commodity, setCommodity] = useState(null);

  const [tags, setTags] = useState([]);
  const [selectedTags, setSelectedTags] = useState([]);

  const [priceType, setPriceType] = useState(null);

  const [search, setSearch] = useState('');
  const debouncedSearch = useDebounce(search, 300);

  const [sortBy, setSortBy] = useState(null);
  const unitsData = useStaticCall(staticCalls.PRICINGUNITS);
  const commoditiesData = useStaticCall(staticCalls.COMMODITIES);
  const priceTypes = useStaticCall(staticCalls.PRICETYPES);
  const currencyData = useStaticCall(staticCalls.CURRENCIES);

  const { user } = useAuth();
  const {
    data,
    isLoading: loading,
    isError: error,
  } = useQuery({
    queryKey: ['/Raw/PriceDefinition'],
    queryFn: ({ queryKey: [url] }) => apiFetch({ url, token: user?.access_token }),
    enabled: !!user?.access_token,
  });

  const [processedData, setProcessedData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);

  useEffect(() => {
    if (!data) {
      setSources([]);
      setMarkets([]);
      setComponents([]);
      setUnits([]);
      setCurrencies([]);
      setTags([]);
      return;
    }

    setSources(Array.from(new Set(data.map(row => row.source))));
    setMarkets(Array.from(new Set(data.map(row => row.name.split(/\|/g)[0]))));
    setComponents(Array.from(new Set(data.map(row => row.name.split(/\|/g)[1]))));

    // Units filter
    const rowUnits = Array.from(new Set(data.map(row => row.unit)));
    const unitList = unitsData?.filter(unit => rowUnits.includes(unit.code));
    setUnits(unitList);

    // Currency filter
    const rowCurrencies = Array.from(new Set(data.map(row => row.currency)));
    const currencyList = currencyData?.filter(currency => rowCurrencies.includes(currency.code));
    setCurrencies(currencyList);
    setTags(
      Array.from(
        data.reduce((a, b) => {
          b.tags.forEach(tag => a.add(tag));
          return a;
        }, new Set()),
      ),
    );
  }, [data, currencyData, unitsData]);

  useEffect(() => {
    const filters = {
      source,
      unit,
      currency,
      commodity,
      priceType,
      selectedTags,
    };
    let processedData = getProcessedData(data, filters);
    if (market !== null) {
      processedData = processedData.filter(row => row.name.split(/\|/g)[0] === market);
    }
    if (component != null) {
      processedData = processedData.filter(row => row.name.split(/\|/g)[1] === component);
    }
    setProcessedData(processedData);
  }, [data, source, market, component, unit, currency, commodity, selectedTags, priceType]);

  useEffect(() => {
    const filteredData = getSearchedText(processedData, debouncedSearch);
    setFilteredData(filteredData);
  }, [processedData, debouncedSearch]);

  const columns = getActiveCatalogueColumns(source, unitsData, commoditiesData, priceTypes);

  const onChangeSource = value => setSource(value);
  const onChangeMarket = value => setMarket(value);
  const onChangeComponent = value => setComponent(value);
  const onChangeUnit = value => setUnit(value);
  const onChangeCurrency = value => setCurrency(value);
  const onChangeCommodity = value => setCommodity(value);
  const onChangeSelectedTags = value => setSelectedTags(value.slice());
  const onChangePriceType = value => setPriceType(value);
  const onChangeSearch = value => setSearch(value);
  const onChangeSortBy = value => setSortBy(value);

  return (
    <Flex row alignItems="stretch" grow shrink overflow="hidden">
      <Flex column alignItems="stretch" overflow="hidden" grow shrink>
        <Flex row alignItems="center" className={cx('toolbar')}>
          <Flex grow />
          <Label className={cx('label')}>Tags:</Label>
          <MultiSelect value={selectedTags} onChange={onChangeSelectedTags}>
            {tags.map(tag => (
              <MultiSelect.Item value={tag} key={tag}>
                {tag}
              </MultiSelect.Item>
            ))}
          </MultiSelect>
        </Flex>
        <Flex column alignItems="stretch" grow shrink overflow="auto" className={cx('main-data-table')}>
          <DataTable
            columns={columns}
            data={filteredData}
            loading={loading}
            error={error}
            sortBy={sortBy}
            onChangeSortBy={onChangeSortBy}
          />
        </Flex>
      </Flex>

      <Flex column alignItems="stretch" flexBasis="250px" overflow="auto" className={cx('sidebar')}>
        <Label className={cx('label')}>Source</Label>
        <Select value={source ?? null} onChange={onChangeSource}>
          <Select.Item value={null}>All Sources</Select.Item>
          {sources.map(source => (
            <Select.Item key={source} value={source}>
              {source}
            </Select.Item>
          ))}
        </Select>

        {source === 'Aligne' && (
          <>
            <Label className={cx('label')}>Market</Label>
            <Select value={market ?? null} onChange={onChangeMarket}>
              <Select.Item value={null}>All Markets</Select.Item>
              {markets.map(market => (
                <Select.Item key={market} value={market}>
                  {market}
                </Select.Item>
              ))}
            </Select>
          </>
        )}

        {source === 'Aligne' && (
          <>
            <Label className={cx('label')}>Component</Label>
            <Select value={component ?? null} onChange={onChangeComponent}>
              <Select.Item value={null}>All Components</Select.Item>
              {components.map(component => (
                <Select.Item key={component} value={component}>
                  {component}
                </Select.Item>
              ))}
            </Select>
          </>
        )}

        <Label className={cx('label')}>Unit</Label>
        <Select value={unit ?? null} onChange={onChangeUnit}>
          <Select.Item value={null}>All Units</Select.Item>
          {units?.map(unit => (
            <Select.Item key={unit.code} value={unit.code}>
              {unit.name} - {unit.code}
            </Select.Item>
          ))}
        </Select>

        <Label className={cx('label')}>Currency</Label>
        <Select value={currency ?? null} onChange={onChangeCurrency}>
          <Select.Item value={null}>All Currencies</Select.Item>
          {currencies?.map(currency => (
            <Select.Item key={currency.code} value={currency.code}>
              {currency.name} - {currency.code}
            </Select.Item>
          ))}
        </Select>

        <Label className={cx('label')}>Commodity</Label>
        <Select value={commodity ?? null} onChange={onChangeCommodity}>
          <Select.Item value={null}>All Commodities</Select.Item>
          {commoditiesData?.map(commodity => (
            <Select.Item key={commodity.code} value={commodity.code}>
              {commodity.group} - {commodity.name}
            </Select.Item>
          ))}
        </Select>

        <Label className={cx('label')}>Price Type</Label>
        <Select value={priceType ?? null} onChange={onChangePriceType}>
          <Select.Item value={null}>All Price Types</Select.Item>
          {priceTypes?.map(priceType => (
            <Select.Item key={priceType.code} value={priceType.code}>
              {priceType.name}
            </Select.Item>
          ))}
        </Select>

        <Label className={cx('label')}>Price Definition Search:</Label>
        <TextInput value={search} onChange={onChangeSearch} placeholder="Search..." />
      </Flex>
    </Flex>
  );
};

const PriceDefinition = () => {
  return (
    <Flex column grow shrink alignItems="stretch" overflow="hidden">
      <Switch>
        <Route path="/price-catalogue/ingestion-log" exact>
          <PriceCurve />
        </Route>
        <Route path="/price-catalogue/curve-catalogue" exact>
          <CurveAvailabilityCheck />
        </Route>
        <Route path="/price-catalogue/fx" exact>
          <FXCatalogue />
        </Route>
        <Route path="/price-catalogue/commodity">
          <ActiveCatalogue />
        </Route>
        <Route path="/price-catalogue/formula" exact>
          <FormulaCatalogue />
        </Route>
        <Route path="/price-catalogue/formula/syntax" exact>
          <CreateFormula />
        </Route>
        <Route path="/price-catalogue" exact>
          <Redirect to="/price-catalogue/commodity" />
        </Route>
      </Switch>
      <Navigator />
    </Flex>
  );
};

export { PriceDefinition };
