import { useState } from 'react';

import styles from './index.module.scss';
import classNames from 'classnames/bind';
import { useAuth } from 'react-oidc-context';

const cx = classNames.bind(styles);

const HeaderMenu = () => {
  const { user } = useAuth();
  const [show, setShow] = useState(false);

  if (!user?.profile) {
    return null;
  }

  const initials = [user?.profile?.given_name?.[0] ?? '', user?.profile?.family_name?.[0] ?? ''].join('').toUpperCase();

  const onClickContainer = () => {
    setShow(show => !show);
  };

  const onClickSignOut = () => {
    window.location.reload();
  };

  return (
    <div className={cx('wrapper')}>
      <div className={cx('container')} onClick={onClickContainer}>
        <div className={cx('initials')}>{initials}</div>
        <div className={cx('body')}>
          <div className={cx('username')}>
            {user?.profile?.given_name ?? ''} {user?.profile?.family_name ?? ''}
          </div>
          <div className={cx('email')}>{user?.profile?.email ?? ''}</div>
        </div>
      </div>
      {show && (
        <div className={cx('menu')}>
          <div onClick={onClickSignOut}>Sign out</div>
        </div>
      )}
    </div>
  );
};

export { HeaderMenu };
