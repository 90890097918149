import { formatISODate } from './formatDate';

const updateUserProfile = (currentUserProfile, type, newData) => {
  let parsedData = currentUserProfile && JSON.parse(currentUserProfile);
  if (!parsedData) {
    parsedData = {};
  }
  if (parsedData[type]) {
    parsedData[type] = { ...parsedData[type], ...newData };
  } else {
    parsedData[type] = { ...newData };
  }
  return JSON.stringify(parsedData);
};

const getSearchedText = (data, search) => {
  let filteredData = data;
  if (search.trim()) {
    const terms = search
      .trim()
      .split(/[\s|]+/g)
      .filter(x => !!x)
      .map(x => x.toLowerCase());
    filteredData = filteredData.filter(row => {
      for (const term of terms) {
        for (const component of row.searchTerms) {
          if (component.includes(term)) {
            return true;
          }
        }
      }
      return false;
    });
  }
  return filteredData;
};

const getFXConversion = (priceDefinition, fxConversionData, reportingCurrency) => {
  const resolvedCurrency = reportingCurrency ?? priceDefinition?.profile?.reportingCurrency ?? null;

  if (resolvedCurrency === null) {
    return [];
  }

  if (resolvedCurrency === priceDefinition.currency) {
    return [];
  }

  return fxConversionData
    .filter(
      row =>
        (row.from === priceDefinition.currency && row.to === resolvedCurrency) ||
        (row.from === resolvedCurrency && row.to === priceDefinition.currency),
    )
    .map(row => {
      if (row.from === priceDefinition.currency) {
        return { ...row, inverse: false };
      } else {
        return { ...row, inverse: true };
      }
    });
};

const getProcessedData = (priceDefinitionData, filters, conversion, commoditiesData, fxConversionList) => {
  let processedData = priceDefinitionData ?? [];
  const { priceProfile, source, unit, currency, commodity, priceType, selectedTags } = filters;

  if (priceType === 'LIVE' || priceType === 'EOD') {
    processedData = processedData.filter(row => row.priceType === priceType);
  }

  if (priceProfile) {
    processedData = processedData
      .filter(row => {
        const curve = priceProfile.curves.find(
          profile => profile.source === row?.source && profile.priceDefinitionName === row?.name,
        );
        return !!curve;
      })
      .map(row => {
        const profile = priceProfile.curves.find(
          profile => profile.source === row?.source && profile.priceDefinitionName === row?.name,
        );
        row.profile = profile;
        return row;
      });
  }

  if (source) {
    processedData = processedData.filter(row => row?.source === source);
  }

  if (unit) {
    processedData = processedData.filter(row => row?.unit === unit);
  }
  if (currency) {
    processedData = processedData.filter(row => row?.currency === currency);
  }

  if (commodity) {
    processedData = processedData.filter(row => row?.commodity === commodity);
  }

  if (selectedTags?.length) {
    processedData = processedData.filter(row => {
      if (row?.tags) {
        return row?.tags?.some(tag => selectedTags?.includes(tag));
      }
      return false;
    });
  }

  if (conversion) {
    processedData = getConvertedProcessedData(processedData, conversion, commoditiesData, fxConversionList);
  }

  processedData.forEach(row => {
    const nameLower = row?.name
      .trim()
      .toLowerCase()
      .split(/[|]/g)
      .filter(x => !!x);

    const descLower = row?.description
      ?.trim()
      ?.toLowerCase()
      ?.split(/[|]| /g)
      ?.filter(x => !!x);

    row.searchTerms = [...nameLower, ...descLower];
  });

  return processedData;
};

const getConvertedProcessedData = (processedData, conversion, commoditiesData, fxConversionList) => {
  const { reportingUnit, reportingCurrency } = conversion;
  if (reportingUnit) {
    const allowedCommodities = commoditiesData
      .filter(commodity => {
        return commodity?.unitConversionFactors?.map(unitConversion => unitConversion?.unit)?.includes(reportingUnit);
      })
      .map(data => data.code);

    processedData = processedData.filter(row => {
      return allowedCommodities.includes(row.commodity);
    });
  }

  if (reportingCurrency) {
    const allConversionList = [...fxConversionList, [reportingCurrency, reportingCurrency]];

    processedData = processedData.filter(row =>
      allConversionList.find(data => JSON.stringify(data) === JSON.stringify([row.currency, reportingCurrency])),
    );
  }
  return processedData;
};

const getFXParams = (priceDefinition, fxConversionData, conversion, filters) => {
  const conversions = getFXConversion(priceDefinition, fxConversionData, conversion.reportingCurrency);

  let fx = {};
  if (conversions.length > 0) {
    if (filters.live && conversions?.find(row => row.priceType === 'LIVE')) {
      const conversion = conversions?.find(row => row.priceType === 'LIVE' && row.default === true);
      if (!conversion) {
        return null;
      }
      fx = {
        'FXRequest.DefinitionName': conversion.name,
        'FXRequest.Source': conversion.source,
        'FXRequest.IsLivePrice': true,
        'FXRequest.Inverse': conversion.inverse,
      };
    } else {
      const conversion = conversions?.find(row => row.priceType === 'EOD' && row.default === true);
      if (!conversion) {
        return null;
      }
      fx = {
        'FXRequest.DefinitionName': conversion.name,
        'FXRequest.Source': conversion.source,
        'FXRequest.PublicationDate': formatISODate(filters.publicationDate),
        'FXRequest.Inverse': conversion.inverse,
      };
    }
  }
  return fx;
};

const checkFxConversion = (fxConversionData, currency) => {
  let uniqueSetAllowedCurrency = [];
  if (fxConversionData) {
    let allowedCurrency = [
      ...fxConversionData?.map(fx => [fx.from, fx.to]),
      ...fxConversionData?.map(fx => [fx.to, fx.from]),
    ];
    if (currency) {
      allowedCurrency = [...allowedCurrency, [currency, currency]];
    }
    uniqueSetAllowedCurrency = Array.from(
      new Set(allowedCurrency.map(currency => JSON.stringify(currency))),
      currency => JSON.parse(currency),
    );
  }
  return uniqueSetAllowedCurrency;
};

export { updateUserProfile, getSearchedText, getFXConversion, getProcessedData, getFXParams, checkFxConversion };
