import React, { createContext, useContext, useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

import { useEmbedComponent } from 'hooks/useEmbedComponent';
import { ReactComponent as DropDownIcon } from './dropdown.svg';
import { ReactComponent as CheckIcon } from './check.svg';

import styles from './index.module.scss';

const cx = classNames.bind(styles);
const SelectContext = createContext();
const Select = ({ value, onChange, disabled, className, children, bottom }) => {
  const { embedded } = useEmbedComponent();
  const [isOpen, setOpen] = useState(false);
  const container = useRef();

  let options = React.Children.toArray(children);
  options = options.filter(({ type }) => type === Option);
  options = options.map(({ props }) => props);

  const currentOption = options.find(({ value: v }) => v === value);

  const onClickSelect = () => {
    if (disabled) {
      return;
    }

    setOpen(isOpen => !isOpen);
  };

  useEffect(() => {
    if (isOpen) {
      const handler = event => {
        if (
          (event.target.closest(`.${cx('container')}`) !== container.current &&
            event.target.closest(`.${cx('container')}`)) ||
          !event.target.closest(`.${cx('container')}`)
        ) {
          setOpen(false);
        }
      };

      window.addEventListener('mousedown', handler, false);
      return () => window.removeEventListener('mousedown', handler, false);
    } else {
      return () => {
        return;
      };
    }
  }, [isOpen]);

  return (
    <SelectContext.Provider value={{ selected: value, onChange, setOpen }} className="select-wrapper">
      <div ref={container} className={cx('container', { embedded })}>
        <span className={cx('select', className, { disabled })} onClick={onClickSelect}>
          <span>{currentOption?.children}</span>
          <DropDownIcon />
        </span>
        {isOpen && <div className={cx('options', bottom ? 'optionsBottom' : 'optionsTop')}>{children}</div>}
      </div>
    </SelectContext.Provider>
  );
};

Select.propTypes = {
  value: PropTypes.any,
  onChange: PropTypes.func,
  children: PropTypes.node.isRequired,
  bottom: PropTypes.bool,
  disabled: PropTypes.bool,
  className: PropTypes.string,
};

const Option = ({ value, children }) => {
  const { selected, onChange, setOpen } = useContext(SelectContext);
  const onClick = () => {
    onChange?.(value);
    setOpen(false);
  };

  return (
    <div onClick={onClick} className={cx('option', { selected: selected === value })}>
      {children}
      {selected === value && <CheckIcon />}
    </div>
  );
};

Option.propTypes = {
  value: PropTypes.any,
  children: PropTypes.node,
};
Select.Item = Option;
export { Select, Option };
