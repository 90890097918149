import { getFXConversion } from 'utils/common';
import { formatISODate } from 'utils/formatDate';

const getFXParams = (priceDefinition, fxConversionData, conversion, filters) => {
  const conversions = getFXConversion(priceDefinition, fxConversionData, conversion.reportingCurrency);

  let fx = {};
  if (conversions.length > 0) {
    if (filters.live && conversions?.find(row => row.priceType === 'LIVE')) {
      const conversion = conversions?.find(row => row.priceType === 'LIVE' && row.default === true);
      if (!conversion) {
        return null;
      }
      fx = {
        'FXRequest.DefinitionName': conversion.name,
        'FXRequest.Source': conversion.source,
        'FXRequest.IsLivePrice': true,
        'FXRequest.Inverse': conversion.inverse,
      };
    } else {
      const conversion = conversions?.find(row => row.priceType === 'EOD' && row.default === true);
      if (!conversion) {
        return null;
      }
      fx = {
        'FXRequest.DefinitionName': conversion.name,
        'FXRequest.Source': conversion.source,
        'FXRequest.PublicationDate': formatISODate(filters.publicationDate),
        'FXRequest.Inverse': conversion.inverse,
      };
    }
  }
  return fx;
};

const getCombinedPriceDef = (priceDefinitionsData, priceCurvesData, combinedPriceDefinitionsData, priceFormulaData) => {
  const combinedData = [];

  if (priceFormulaData) {
    combinedData.push(...priceFormulaData);
  }
  for (const row of priceDefinitionsData) {
    const previousRow = combinedPriceDefinitionsData.find(x => x.source === row.source && x.name === row.name);

    const priceCurves = priceCurvesData
      .filter(curve => curve.source === row.source && curve.priceDefinitionName === row.name)
      .sort((a, b) => {
        if (a.ingestionDate > b.ingestionDate) {
          return -1;
        }
        if (a.ingestionDate < b.ingestionDate) {
          return 1;
        }
        return 0;
      })
      .map(x => ({ ...x }));

    if (previousRow) {
      combinedData.push(Object.assign(previousRow, { priceCurves }));
    } else {
      combinedData.push({ ...row, priceCurves });
    }
  }

  return combinedData;
};

const getNewInjectionDate = (combinedPriceDefinitionsData, previousIngestionDates) => {
  const newIngestionDates = {};
  for (const definition of combinedPriceDefinitionsData) {
    if (definition.priceCurves?.[0]) {
      newIngestionDates[`${definition.source}:${definition.name}`] = {
        source: definition.source,
        name: definition.name,
        ingestionDate: definition.priceCurves[0].ingestionDate,
      };
    }
  }
  const changed = [];

  for (const k in previousIngestionDates) {
    const previousIngestionDate = previousIngestionDates[k];
    if (newIngestionDates[k] && newIngestionDates[k].ingestionDate !== previousIngestionDate.ingestionDate) {
      changed.push(previousIngestionDate);
    }
  }

  return { newIngestionDates, changed };
};

const curveErrorCallback = errResponse => {
  return { error: JSON.parse(errResponse)?.errors[''][0] };
};

export { getFXParams, getCombinedPriceDef, getNewInjectionDate, curveErrorCallback };
