const formatDate = (() => {
  const formatter = new Intl.DateTimeFormat('en-GB', {
    year: 'numeric',
    month: 'short',
    day: '2-digit',
  });

  return date => {
    const parts = formatter.formatToParts(date);

    const year = parts.find(({ type }) => type === 'year').value;
    const month = parts.find(({ type }) => type === 'month').value;
    const day = parts.find(({ type }) => type === 'day').value;

    return `${day} ${month} ${year}`;
  };
})();

const formatTime = (() => {
  const formatter = new Intl.DateTimeFormat('en-GB', {
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
  });

  return date => {
    const parts = formatter.formatToParts(date);

    const hour = parts.find(({ type }) => type === 'hour').value;
    const minute = parts.find(({ type }) => type === 'minute').value;
    const second = parts.find(({ type }) => type === 'second').value;

    return `${hour}:${minute}:${second}`;
  };
})();

const formatDateTime = date => {
  return `${formatDate(date)} ${formatTime(date)}`;
};

const formatISODate = (() => {
  const formatter = new Intl.DateTimeFormat('en-GB', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
  });

  return date => {
    const parts = formatter.formatToParts(date);

    const year = parts.find(({ type }) => type === 'year').value;
    const month = parts.find(({ type }) => type === 'month').value;
    const day = parts.find(({ type }) => type === 'day').value;

    return `${year}-${month}-${day}`;
  };
})();

const formatMonthYear = (() => {
  const formatter = new Intl.DateTimeFormat('en-GB', {
    year: 'numeric',
    month: 'short',
  });

  return date => {
    const parts = formatter.formatToParts(date);

    const year = parts.find(({ type }) => type === 'year').value;
    const month = parts.find(({ type }) => type === 'month').value;

    return `${month} ${year}`;
  };
})();

const formatDayOfMonth = (() => {
  const formatter = new Intl.DateTimeFormat('en-GB', {
    day: 'numeric',
  });

  return date => {
    const parts = formatter.formatToParts(date);

    const day = parts.find(({ type }) => type === 'day').value;

    return `${day}`;
  };
})();

const formatDayOfWeek = (() => {
  const formatter = new Intl.DateTimeFormat('en-GB', {
    weekday: 'short',
  });

  return date => {
    const parts = formatter.formatToParts(date);

    const weekday = parts.find(({ type }) => type === 'weekday').value;

    return `${weekday}`;
  };
})();

export { formatDate, formatTime, formatDateTime, formatISODate, formatMonthYear, formatDayOfMonth, formatDayOfWeek };
