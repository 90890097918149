import classNames from 'classnames/bind';
import { useStaticCall } from 'hooks/useStaticCalls';
import { Button, Label, SearchSelect } from 'library/components';
import { useEffect, useState } from 'react';
import { checkFxConversion } from 'utils/common';
import staticCalls from '../../utils/staticCalls.json';
import PropTypes from 'prop-types';

import styles from './index.module.scss';
const cx = classNames.bind(styles);

const ApplyReportingCurrency = ({
  priceProfileSelection,
  priceCurveSelection,
  setPriceProfileSelection,
  getErrorMessage,
  onClickSave,
}) => {
  const [currency, setCurrency] = useState(null);
  const [filteredCurrenciesData, setFilteredCurrenciesData] = useState([]);

  const currenciesData = useStaticCall(staticCalls.CURRENCIES);
  const fxConversionData = useStaticCall(staticCalls.FXDEFINITION);
  const priceDefinitionData = useStaticCall(staticCalls.PRICEDEFINITION);

  useEffect(() => {
    if (currenciesData) {
      const conversionList = checkFxConversion(fxConversionData);
      const validCurrencyList = [...new Set(conversionList?.map(x => x[0]))];
      const filterCurrency = currenciesData?.filter(currency => validCurrencyList.includes(currency.code));
      setFilteredCurrenciesData(filterCurrency);
    }
  }, [currenciesData, fxConversionData]);

  const onClickApplyCurrency = async () => {
    const selected = priceProfileSelection?.[0];
    const selectedList = priceCurveSelection?.length > 0 ? priceCurveSelection : selected.curves;
    if (!currency) {
      selectedList.forEach(row => {
        const originalCurrency = priceDefinitionData?.find(
          priceDefinition => priceDefinition.name === row.priceDefinitionName,
        )?.currency;
        row.reportingCurrency = originalCurrency;
      });
    } else {
      const fxConversions = checkFxConversion(fxConversionData, currency);
      const incompatibleList = [];
      selectedList.forEach(row => {
        if (fxConversions?.find(data => JSON.stringify(data) === JSON.stringify([row.reportingCurrency, currency]))) {
          row.reportingCurrency = currency;
        } else {
          incompatibleList.push(row);
        }
      });
      if (incompatibleList.length) {
        getErrorMessage('currency');
      }
    }
    selected.curves = selected.curves.slice();
    await onClickSave();
    setPriceProfileSelection([selected]);
  };

  return (
    <>
      <Label className={cx('label')}>Reporting Currency:</Label>
      <SearchSelect
        value={currency}
        onChange={value => setCurrency(value)}
        disabled={priceProfileSelection?.length === 0}>
        {filteredCurrenciesData?.map(currency => (
          <SearchSelect.Item value={currency.code} key={currency.code}>
            {currency.name && currency.code ? `${currency.name} - ${currency.code}` : `${currency.name}`}
          </SearchSelect.Item>
        ))}
      </SearchSelect>
      <Button onClick={onClickApplyCurrency} className={cx('button')} disabled={priceProfileSelection?.length === 0}>
        Apply
      </Button>
    </>
  );
};

ApplyReportingCurrency.propTypes = {
  priceProfileSelection: PropTypes.array,
  priceCurveSelection: PropTypes.array,
  setPriceProfileSelection: PropTypes.func,
  getErrorMessage: PropTypes.func,
  onClickSave: PropTypes.func,
};

export { ApplyReportingCurrency };
