import { useCallback } from 'react';
import PropTypes from 'prop-types';
import { Flex, Label, DateSelect, TextInput } from 'library/components';

import { DateToggle } from './dateToggle';

import styles from './index.module.scss';
import classNames from 'classnames/bind';

import moment from 'moment';
import { formatDate } from 'utils/formatDate';

import Switch from '@mui/material/Switch';
import { Panel } from './panel';

const cx = classNames.bind(styles);

const filterItemClass = 'filter-item';
const filterColumnClass = 'filter-column';

const DateDisplay = ({ value, min, max, className, onChange, disabled = false }) => {
  if (disabled) {
    return (
      <div className={cx('disabled-label')}>
        <TextInput disabled value={formatDate(value)} />
      </div>
    );
  }
  return <DateSelect value={value} onChange={onChange} min={min} max={max} className={className} />;
};

DateDisplay.propTypes = {
  value: PropTypes.instanceOf(Date),
  min: PropTypes.instanceOf(Date),
  max: PropTypes.instanceOf(Date),
  className: PropTypes.any,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
};

const getDatesFromType = dateType => {
  let _startDate;
  let _endDate;
  switch (dateType) {
    case 'ytd':
      _startDate = moment().startOf('year');
      _endDate = moment().startOf('day');
      break;
    case '1m':
      _startDate = moment().add(-1, 'month').startOf('day');
      _endDate = moment().startOf('day');
      break;
    case '6m':
      _startDate = moment().add(-6, 'month').startOf('day');
      _endDate = moment().startOf('day');
      break;
    case '1y':
      _startDate = moment().add(-1, 'year').startOf('day');
      _endDate = moment().startOf('day');
      break;
  }
  return [_startDate.toDate(), _endDate.toDate()];
};

const DateControlPanel = ({ filters, onChange }) => {
  const onChangeFilter = useCallback(
    ({ _startDate, _endDate }) => {
      const _localStartDate = typeof _startDate === 'string' ? moment(_startDate).toDate() : _startDate;
      const _localEndDate = typeof _startDate === 'string' ? moment(_startDate).toDate() : _endDate;

      let updatedStartDate = filters.startDate;
      let updatedEndDate = filters.endDate;

      if (_localStartDate && _localEndDate) {
        updatedStartDate = _startDate;
        updatedEndDate = _endDate;
      } else if (_localStartDate) {
        updatedStartDate = _localStartDate;
        updatedEndDate = updatedEndDate < _localStartDate ? _localStartDate : updatedEndDate;
      } else if (_localEndDate) {
        updatedStartDate = updatedStartDate > _localEndDate ? _localEndDate : updatedStartDate;
        updatedEndDate = _localEndDate;
      }
      onChange({ startDate: updatedStartDate, endDate: updatedEndDate });
    },
    [onChange, filters.startDate, filters.endDate],
  );

  const setDateType = val => {
    if (val === 'other') {
      onChange({ dateType: val });
      return;
    }
    const [startDate, endDate] = getDatesFromType(val);
    onChange({ dateType: val, startDate, endDate });
  };

  return (
    <Panel title="Date Controls">
      <Flex row className={cx(filterItemClass, 'filter-item-distributed')}>
        <DateToggle dateType={filters.dateType} setDateType={setDateType} />
      </Flex>
      <Flex column className={cx('filter-group')}>
        <Flex row className={cx('filter-row')}>
          <Flex column className={cx(filterColumnClass)}>
            <Label className={cx('label')}>Start Date</Label>
          </Flex>
          <Flex column className={cx(filterColumnClass)}>
            <Label className={cx('label')}>End Date</Label>
          </Flex>
        </Flex>
        <Flex row className={cx('filter-row')}>
          <Flex column className={cx(filterColumnClass)}>
            <Flex column alignItems="stretch" grow shrink className={cx('date-picker')}>
              <DateDisplay
                disabled={filters.dateType !== 'other'}
                value={filters.startDate}
                onChange={_startDate => onChangeFilter({ _startDate })}
                min={moment(new Date(Date.now()), 'utc').add(-1, 'day').add(-2, 'year').startOf('day').toDate()}
                max={moment(new Date(Date.now()), 'utc').startOf('day').toDate()}
                className={cx('filter-control')}
              />
            </Flex>
          </Flex>
          <Flex column className={cx(filterColumnClass)}>
            <Flex column alignItems="stretch" grow shrink className={cx('date-picker')}>
              <DateDisplay
                value={filters.endDate}
                disabled={filters.dateType !== 'other'}
                onChange={_endDate => onChangeFilter({ _endDate })}
                min={filters.startDate}
                max={moment(new Date(Date.now()), 'utc').startOf('day').toDate()}
                className={cx('filter-control')}
              />
            </Flex>
          </Flex>
        </Flex>
      </Flex>
      <Flex row className={cx(filterItemClass)}>
        <Flex column className={cx('filter-col-label')}>
          <Label>Exclude Week-ends</Label>
        </Flex>
        <Flex column className={cx('filter-col-control')}>
          <Switch
            checked={filters.excludeWeekEnd}
            size="small"
            onChange={() => onChange({ excludeWeekEnd: !filters.excludeWeekEnd })}
            className={cx('switch-wrapper')}
          />
        </Flex>
      </Flex>
      <Flex row className={cx(filterItemClass)}>
        <Flex column className={cx('filter-col-label')}>
          <Label>Adjust EOD curve for Week-ends</Label>
        </Flex>
        <Flex column className={cx('filter-col-control')}>
          <Switch
            checked={filters.adjustEODDateForWeekEnd}
            size="small"
            onChange={() =>
              onChange({
                adjustEODDateForWeekEnd: !filters.adjustEODDateForWeekEnd,
              })
            }
            className={cx('switch-wrapper')}
          />
        </Flex>
      </Flex>
    </Panel>
  );
};

DateControlPanel.propTypes = {
  onChange: PropTypes.func,
  filters: PropTypes.shape({
    startDate: PropTypes.instanceOf(Date),
    endDate: PropTypes.instanceOf(Date),
    dateType: PropTypes.string,
    excludeWeekEnd: PropTypes.bool,
    adjustEODDateForWeekEnd: PropTypes.bool,
  }),
};

export { DateControlPanel };
