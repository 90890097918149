import styles from './index.module.scss';
import classNames from 'classnames/bind';

import PropTypes from 'prop-types';

const cx = classNames.bind(styles);

const Flex = ({
  className,
  children,
  row,
  column,
  grow,
  shrink,
  basis,
  wrap,
  alignItems,
  justifyContent,
  overflow,
  hidden,
}) => {
  const flexDirection = column ? 'column' : '';
  return (
    <div
      className={cx(className, 'container', 'flex', {
        'flex-row': row,
        'flex-column': column,
      })}
      style={{
        flexFlow: `${row ? 'row' : flexDirection}${wrap ? ' wrap' : ' nowrap'}`,
        flexGrow: grow && !hidden ? '1' : '0',
        flexShrink: shrink ? '1' : '0',
        flexBasis: basis ? basis : undefined,
        alignItems,
        justifyContent,
        overflow,
        contentVisibility: hidden ? 'hidden' : 'visible',
      }}>
      {children}
    </div>
  );
};
Flex.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  row: PropTypes.any,
  column: PropTypes.any,
  grow: PropTypes.bool,
  shrink: PropTypes.bool,
  basis: PropTypes.any,
  wrap: PropTypes.bool,
  alignItems: PropTypes.any,
  justifyContent: PropTypes.any,
  overflow: PropTypes.any,
  hidden: PropTypes.bool,
};
export { Flex };
