import { useMemo } from 'react';
import PropTypes from 'prop-types';
import DeleteIcon from '@mui/icons-material/Delete';

import { DataTable } from 'library/components';
import styles from './index.module.scss';
import classNames from 'classnames/bind';

const cx = classNames.bind(styles);

const SelectedPrice = ({ selectedData, onDeleteRow }) => {
  const selectedColumns = useMemo(
    () => [
      {
        id: 'source',
        name: 'Source',
        field: 'source',
        sort: true,
      },
      {
        id: 'name',
        name: 'Name',
        field: 'name',
        sort: true,
      },
      {
        id: 'description',
        name: 'Description',
        field: 'description',
        sort: true,
      },
      {
        id: 'delete',
        name: '',
        input: {
          value: row => {
            return row;
          },
          onChange: row => {
            onDeleteRow(row);
          },
          render: (value, row, onChange) => {
            return (
              <div className={cx('delete-icon-container')}>
                <div onClick={onChange} className={cx('delete-icon', { selected: row?.color })}>
                  <DeleteIcon />
                </div>
              </div>
            );
          },
        },
      },
    ],
    [onDeleteRow],
  );
  return <DataTable columns={selectedColumns} data={selectedData} />;
};

SelectedPrice.propTypes = {
  selectedData: PropTypes.array,
  onDeleteRow: PropTypes.func,
};

export { SelectedPrice };
