import { useState, useRef, useEffect } from 'react';

import styles from './index.module.scss';
import classNames from 'classnames/bind';
import PropTypes from 'prop-types';

const cx = classNames.bind(styles);

const RangeSlider = ({ min, max, value, onChange, fromValue, toValue, renderLabel }) => {
  const container = useRef();
  const [left, setLeft] = useState(0);
  const [width, setWidth] = useState(0);
  const [mouse, setMouse] = useState(null);

  const updateSlider = () => {
    if (container.current) {
      const rect = container.current.getBoundingClientRect();
      const _min = fromValue(min);
      const _max = fromValue(max);
      const _value = {
        from: fromValue(value?.from),
        to: fromValue(value?.to),
      };

      _value.from = Math.max(_min, Math.min(_value.from, _max));
      _value.to = Math.max(_min, Math.min(_value.to, _max));

      let left = (_value.from - _min) * (rect.width / (_max - _min));
      let width = (_value.to - _min) * (rect.width / (_max - _min)) - left;

      if (width < 16) {
        left = Math.min(left, rect.width - 16);
        width = 16;
      }

      if (left < 0) {
        left = Math.max(left, 0);
        width = Math.min(width, rect.width);
      }

      setLeft(left);
      setWidth(width);
    } else {
      setLeft(0);
      setWidth(0);
    }
  };

  const onRef = ref => {
    container.current = ref;
    updateSlider();
  };

  useEffect(() => {
    updateSlider();
    let containerRef = null;
    if (container.current) {
      containerRef = container.current;
      const resizeObserver = new ResizeObserver(() => updateSlider());

      resizeObserver.observe(containerRef);
      return () => {
        resizeObserver.unobserve(containerRef);
      };
    }
    return undefined;
  }, [min, max, value]);

  useEffect(() => {
    if (mouse) {
      window.addEventListener('mousemove', onThumbMouseMove, false);
      window.addEventListener('mouseup', onThumbMouseUp, false);
      return () => {
        window.removeEventListener('mousemove', onThumbMouseMove, false);
        window.removeEventListener('mouseup', onThumbMouseUp, false);
      };
    }
    return undefined;
  }, [mouse]);

  const onThumbMouseDown = (thumb, event) => {
    if (mouse) {
      return;
    }

    setMouse({
      thumb,
      left,
      width,
      x: event.pageX,
      y: event.pageY,
      value: Object.assign({}, value ?? { from: null, to: null }),
    });
  };

  const onThumbMouseMove = event => {
    if (!mouse) {
      return;
    }

    const rect = container.current.getBoundingClientRect();
    const value = Object.assign({}, mouse.value);
    const _min = fromValue(min);
    const _max = fromValue(max);

    if (mouse.thumb === 'left') {
      let newValue = _min + ((_max - _min) * (mouse.left + event.pageX - mouse.x)) / rect.width;

      if (newValue < _min) {
        newValue = _min;
      }
      if (newValue > _max) {
        newValue = _max;
      }

      if (newValue > fromValue(value.to)) {
        newValue = fromValue(value.to);
      }
      value.from = toValue(newValue);
    } else {
      let newValue = _min + ((_max - _min) * (mouse.left + mouse.width + event.pageX - mouse.x)) / rect.width;

      if (newValue < _min) {
        newValue = _min;
      }
      if (newValue > _max) {
        newValue = _max;
      }

      if (newValue < fromValue(value.from)) {
        newValue = fromValue(value.from);
      }
      value.to = toValue(newValue);
    }

    onChange?.(value);
  };

  const onThumbMouseUp = () => {
    if (!mouse) {
      return;
    }

    setMouse(null);
  };

  const onThumbLeftMouseDown = event => {
    onThumbMouseDown('left', event);
  };

  const onThumbRightMouseDown = event => {
    onThumbMouseDown('right', event);
  };

  return (
    <div className={cx('container')} ref={onRef}>
      <div className={cx('selection')} style={{ left, width }} />
      <div className={cx('thumb', 'thumb-left')} style={{ left: left - 8 }} onMouseDown={onThumbLeftMouseDown} />
      <div className={cx('label', 'label-left')} style={{ left: left }}>
        {renderLabel(value?.from)}
      </div>
      <div
        className={cx('thumb', 'thumb-right')}
        style={{ left: left + width - 8 }}
        onMouseDown={onThumbRightMouseDown}
      />
      <div className={cx('label', 'label-right')} style={{ left: left + width }}>
        {renderLabel(value?.to)}
      </div>
    </div>
  );
};

RangeSlider.propTypes = {
  min: PropTypes.any,
  max: PropTypes.any,
  value: PropTypes.object,
  onChange: PropTypes.func,
  fromValue: PropTypes.func,
  toValue: PropTypes.func,
  renderLabel: PropTypes.func,
};

export { RangeSlider };
