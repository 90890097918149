import { createContext, useContext, useMemo } from 'react';
import PropTypes from 'prop-types';

const EmbedComponentContext = createContext({
  embedded: false,
  selected: false,
});

const EmbedComponentProvider = ({ children, selected = false }) => {
  const context = useMemo(() => {
    return {
      embedded: true,
      selected,
    };
  }, [selected]);

  return <EmbedComponentContext.Provider value={context}>{children}</EmbedComponentContext.Provider>;
};

EmbedComponentProvider.propTypes = {
  selected: PropTypes.bool,
  children: PropTypes.node,
};

const useEmbedComponent = () => useContext(EmbedComponentContext);

export { EmbedComponentProvider, useEmbedComponent };
