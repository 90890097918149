import styles from './index.module.scss';
import classNames from 'classnames/bind';

import PropTypes from 'prop-types';

const cx = classNames.bind(styles);

const Label = ({ children, className }) => {
  return <div className={`${className ?? ''} ${cx('container')}`}>{children}</div>;
};

Label.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
};

export { Label };
