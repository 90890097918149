import React, { useState, useEffect } from 'react';
import { Button, Flex, Label, Select, DateSelect } from 'library/components';
import moment from 'moment';
import PropTypes from 'prop-types';

import { useAuth } from 'react-oidc-context';
import { useQuery, useMutation } from 'react-query';
import { granularityData } from 'utils/granularityData';
import { interpolationData } from 'utils/interpolationData';

import { SelectedPrice } from './SelectedPrice';
import styles from './index.module.scss';
import classNames from 'classnames/bind';
import { apiFetch } from 'utils/apiService';

const cx = classNames.bind(styles);

const DataDownload = ({ timer, selectedData, setSelectedData, bulkRequestId, setBulkRequestId }) => {
  const monthCode = granularityData.find(gd => gd.code === 'Month')?.code;
  const linearCode = interpolationData.find(id => id.code === 'Linear')?.code;
  const [selectedGranularity, setSelectedGranularity] = useState(monthCode);
  const [selecttedInterpolation, setSelectedInterpolation] = useState(linearCode);
  const [publicationStartDate, setPublicationStartDate] = useState(() => new Date(Date.now() - 24 * 3600_000));
  const [publicationEndDate, setPublicationEndDate] = useState(() => new Date(Date.now() - 24 * 3600_000));
  const [file, setFile] = useState(null);
  const [loading, setLoading] = useState(true);
  const [btnDisable, setBtnDisable] = useState(false);
  const { user } = useAuth();

  const { mutateAsync: bulkPriceRequest } = useMutation(
    bulkRequest =>
      apiFetch({
        method: 'POST',
        url: `/raw/PriceCurve/bulk`,
        token: user?.access_token,
        body: bulkRequest,
      }),
    {
      queryKey: `POST /experience/PriceProfile`,
    },
  );

  const { data: bulkRequestData, refetch: refetchBulkRequest } = useQuery({
    queryKey: [`/raw/PriceCurve/bulk/${bulkRequestId}`],
    queryFn: ({ queryKey: [url] }) => apiFetch({ url, token: user?.access_token }),
    enabled: !!user?.access_token && !!bulkRequestId,
  });

  useEffect(() => {
    let timerId = -1;
    if (bulkRequestId && bulkRequestData?.status !== 'Done' && !timer.current) {
      timerId = setInterval(() => refetchBulkRequest(), 5_000);
      timer.current = timerId;
    }

    return () => {
      clearInterval(timerId);
      timer.current = null;
    };
  }, [bulkRequestData?.status, bulkRequestId, refetchBulkRequest, timer]);

  useEffect(() => {
    if (bulkRequestData?.status === 'Done') {
      clearInterval(timer);
      setLoading(false);
      if (bulkRequestData?.blobUrl) {
        setFile(bulkRequestData?.blobUrl);
        setBtnDisable(false);
      }
    }
  }, [bulkRequestData, timer]);

  const resetAll = () => {
    setBulkRequestId(null);
    clearInterval(timer);
    timer.current = null;
  };

  const onDeleteRow = row => {
    const index = selectedData.findIndex(el => el.id === row.id);
    const updatedSelectedData = [...selectedData];
    updatedSelectedData.splice(index, 1);
    setSelectedData(updatedSelectedData);
    resetAll();
  };

  const onChangePublicationStartDate = value => {
    setPublicationStartDate(value);
    resetAll();
  };
  const onChangePublicationEndDate = value => {
    setPublicationEndDate(value);
    resetAll();
  };
  const onChangeGranularity = value => {
    setSelectedGranularity(value);
    resetAll();
  };
  const onChangeInterpolation = value => {
    setSelectedInterpolation(value);
    resetAll();
  };

  const onExtractClick = async () => {
    const definitions = selectedData?.map(data => {
      return { name: data.name, source: data.source };
    });
    const resp = await bulkPriceRequest({
      definitions,
      publicationStartDate,
      publicationEndDate,
      granularity: {
        granularityType: selectedGranularity,
        interpolationType: selecttedInterpolation ?? interpolationData[0].code,
      },
    });
    if (resp?.status) {
      setBulkRequestId(resp?.id);
      setBtnDisable(true);
      setLoading(true);
    }
  };

  const onClearClick = () => {
    setSelectedData([]);
    resetAll();
  };

  return (
    <Flex row grow shrink alignItems="stretch" overflow="hidden">
      <Flex column alignItems="stretch" className={cx('right-container')}>
        <Flex row>
          <Flex column alignItems="stretch" grow shrink className={cx('date-picker')}>
            <Label className={cx('label')}>Publication Start Date:</Label>
            <DateSelect
              value={publicationStartDate}
              onChange={onChangePublicationStartDate}
              min={moment(new Date(Date.now()), 'utc').add(-1, 'day').add(-2, 'year').startOf('day').toDate()}
              max={publicationEndDate}
            />
          </Flex>
          <Flex column alignItems="stretch" grow shrink className={cx('date-picker')}>
            <Label className={cx('label')}>Publication End Date:</Label>
            <DateSelect
              value={publicationEndDate}
              onChange={onChangePublicationEndDate}
              min={publicationStartDate}
              max={moment(new Date(Date.now()), 'utc').startOf('day').toDate()}
            />
          </Flex>
        </Flex>
        <Flex row alignItems="stretch">
          <Flex column alignItems="stretch" grow shrink className={cx('select')}>
            <Label className={cx('label')}>Granularity:</Label>
            <Select value={selectedGranularity} onChange={onChangeGranularity}>
              {granularityData?.map(granularity => (
                <Select.Item key={granularity.code} value={granularity.code}>
                  {granularity.name}
                </Select.Item>
              ))}
            </Select>
          </Flex>
          <Flex column alignItems="stretch" grow shrink className={cx('select')}>
            <Label className={cx('label')}>Interpolation:</Label>
            <Select value={selecttedInterpolation} onChange={onChangeInterpolation}>
              {interpolationData?.map(interpolation => (
                <Select.Item key={interpolation.code} value={interpolation.code}>
                  {interpolation.name}
                </Select.Item>
              ))}
            </Select>
          </Flex>
        </Flex>
        <Flex row alignItems="stretch">
          <Flex column grow shrink className={cx('export')}>
            {!bulkRequestId ? (
              <Button disabled={!selectedData.length} onClick={onExtractClick}>
                Extract
              </Button>
            ) : (
              <Button
                loading={loading}
                disabled={btnDisable}
                onClick={() => {
                  // onclick required on LIBRARY BUTTON
                  return;
                }}>
                <a href={file} download>
                  Download
                </a>
              </Button>
            )}
          </Flex>
        </Flex>
        <Flex column grow shrink overflow="auto" className={cx('selected')}>
          <Flex row className={cx('button')}>
            <Button disabled={!selectedData.length} onClick={onClearClick}>
              Clear Selection
            </Button>
          </Flex>
          <Flex row>
            <SelectedPrice selectedData={selectedData} onDeleteRow={onDeleteRow} />
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
};

DataDownload.propTypes = {
  timer: React.MutableRefObject,
  selectedData: PropTypes.array,
  setSelectedData: PropTypes.func,
  bulkRequestId: PropTypes.any,
  setBulkRequestId: PropTypes.func,
};

export { DataDownload };
