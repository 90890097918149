import classNames from 'classnames/bind';
import { Label, MultiSelect } from 'library/components';

import styles from './index.module.scss';
const cx = classNames.bind(styles);

export const TagsMultiSelect = ({
  value,
  onChange,
  tags,
}: {
  value: string[];
  onChange: (val: string) => void;
  tags: string[];
}) => {
  return (
    <>
      <Label className={cx('label')}>Tags:</Label>
      <MultiSelect value={value} onChange={onChange}>
        {tags.map(tag => (
          <MultiSelect.Item value={tag} key={tag}>
            {tag}
          </MultiSelect.Item>
        ))}
      </MultiSelect>
    </>
  );
};
