import { ReactComponent as CheckIcon } from './check.svg';
import { ReactComponent as CrossIcon } from './cross.svg';
import { ReactComponent as InfoIcon } from './info.svg';
import { ReactComponent as QuestionIcon } from './question.svg';
import { ReactComponent as WarningIcon } from './warning.svg';

import styles from './index.module.scss';
import classNames from 'classnames/bind';

const cx = classNames.bind(styles);

import PropTypes from 'prop-types';

const Alert = ({ type, children }) => {
  let Icon;
  switch (type) {
    case 'success':
      Icon = CheckIcon;
      break;
    case 'error':
      Icon = CrossIcon;
      break;
    case 'question':
      Icon = QuestionIcon;
      break;
    case 'warning':
      Icon = WarningIcon;
      break;
    default:
      Icon = InfoIcon;
      break;
  }

  return (
    <div className={cx('container', type)}>
      <Icon />
      <div className={cx('content')}>{children}</div>
    </div>
  );
};

Alert.propTypes = {
  type: PropTypes.string,
  children: PropTypes.node,
};

export { Alert };
