import PropTypes from 'prop-types';
import { Flex } from 'library/components';

import styles from './index.module.scss';
import classNames from 'classnames/bind';
import { CurveControlPanel } from './curveControlPanel';
import { DateControlPanel } from './dateControlPanel';

const cx = classNames.bind(styles);

const CatalogueFilter = ({ priceProfileData, filters, onChange }) => {
  return (
    <Flex column alignItems="stretch" className={cx('sidebar')}>
      <DateControlPanel filters={filters} onChange={onChange} />
      <CurveControlPanel priceProfileData={priceProfileData} filters={filters} onChange={onChange} />
    </Flex>
  );
};

CatalogueFilter.propTypes = {
  priceProfileData: PropTypes.array,
  onChange: PropTypes.func,
  filters: PropTypes.shape({
    priceProfile: PropTypes.string,
    source: PropTypes.string,
    commodity: PropTypes.string,
    priceDefinitions: PropTypes.array,
    startDate: PropTypes.instanceOf(Date),
    endDate: PropTypes.instanceOf(Date),
    dateType: PropTypes.string,
    excludeWeekEnd: PropTypes.bool,
    adjustEODDateForWeekEnd: PropTypes.bool,
  }),
};

export { CatalogueFilter };
