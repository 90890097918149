import PropTypes from 'prop-types';
import { Select } from 'library/components';
import Tooltip from '@mui/material/Tooltip';

import { checkFxConversion } from 'utils/common';
import { useStaticCall } from 'hooks/useStaticCalls';
import staticCalls from 'utils/staticCalls.json';

const ReportingCurrencyComponent = ({ value, row, onChange }) => {
  const currenciesData = useStaticCall(staticCalls.CURRENCIES);
  const fxConversionData = useStaticCall(staticCalls.FXDEFINITION);

  const { currency, selectedCurrency } = value;
  const { reportingCurrency } = row;

  const fxConversions = checkFxConversion(fxConversionData, currency);
  const fxConversionsList = [
    ...new Set(fxConversions?.filter(data => data.includes(currency))?.map(fxConversion => fxConversion[0])),
  ];
  const filteredCurrenciesData = currenciesData?.filter(currency => fxConversionsList?.includes(currency.code));
  return (
    <Tooltip title={reportingCurrency?.fx?.priceDefinitionName || ''} arrow placement="right">
      <div>
        <Select value={selectedCurrency} onChange={onChange}>
          {filteredCurrenciesData?.map(currency => (
            <Select.Item value={currency.code} key={currency.code}>
              {currency.code}
            </Select.Item>
          ))}
        </Select>
      </div>
    </Tooltip>
  );
};

ReportingCurrencyComponent.propTypes = {
  value: PropTypes.object,
  row: PropTypes.object,
  onChange: PropTypes.func,
};

export { ReportingCurrencyComponent };
