import { useState, useMemo, useEffect } from 'react';
import { Flex, Button, DataTable } from 'library/components';
import moment from 'moment';

import { useStaticCall } from 'hooks/useStaticCalls';
import staticCalls from 'utils/staticCalls.json';
import { formatDateTime } from 'utils/formatDate';

import { NewFormulaModal } from './NewFormulaModal';

import styles from './index.module.scss';
import classNames from 'classnames/bind';

import { useQuery } from 'react-query';
import { useAuth } from 'react-oidc-context';
import { apiFetch } from 'utils/apiService';

const cx = classNames.bind(styles);

const FormulaCatalogue = () => {
  const [existingFormulaData, setExistingFormulaData] = useState([]);
  const [sortBy, setSortBy] = useState(null);
  const [selected, setSelected] = useState(null);

  const commoditiesData = useStaticCall(staticCalls.COMMODITIES);
  const { user } = useAuth();
  const {
    data: priceFormulaData,
    isLoading: loading,
    isError: error,
    refetch: refetchPriceFormula,
  } = useQuery({
    queryKey: ['/raw/PriceFormula/list'],
    queryFn: ({ queryKey: [url] }) => apiFetch({ url, token: user?.access_token }),
    enabled: !!user?.access_token,
  });

  useEffect(() => {
    setExistingFormulaData(priceFormulaData);
  }, [priceFormulaData]);

  const columns = useMemo(
    () => [
      { id: 'id', name: 'ID', field: 'id', show: false },
      { id: 'name', name: 'Name', field: 'name', sort: true },
      {
        id: 'description',
        name: 'Description',
        field: 'description',
        sort: true,
      },
      { id: 'unit', name: 'Unit', field: 'unit', sort: true },
      { id: 'currency', name: 'Currency', field: 'currency', sort: true },
      {
        id: 'commodity',
        name: 'Commodity',
        function: row => commoditiesData?.find(d => d.code === row.commodity)?.name,
        sort: true,
      },
      {
        id: 'granularity',
        name: 'Granularity',
        field: 'granularity',
        render: value => moment.duration(value).humanize(),
        sort: true,
      },
      {
        id: 'lastUpdatedBy',
        name: 'Last Updated By',
        field: 'lastUpdatedBy',
        sort: true,
      },
      {
        id: 'lastUpdatedOn',
        name: 'Last Updated On',
        function: row => new Date(row.lastUpdatedOn),
        render: value => formatDateTime(value),
        sort: true,
      },
    ],
    [commoditiesData],
  );

  const onClickNewFormula = () => setSelected({});
  const onChangeSortBy = value => setSortBy(value);
  const onHideModal = async (refetch = false) => {
    if (refetch) {
      await refetchPriceFormula();
    }
    setSelected(null);
  };
  const onClickRow = row => setSelected(row);

  return (
    <>
      <Flex row alignItems="center" className={cx('box', 'box-row')}>
        <Button onClick={onClickNewFormula}>New Formula</Button>
      </Flex>
      <Flex className={cx('box')} column grow shrink alignItems="stretch" overflow="auto">
        <DataTable
          columns={columns}
          data={existingFormulaData}
          loading={loading}
          error={error}
          sortBy={sortBy}
          onChangeSortBy={onChangeSortBy}
          onClickRow={onClickRow}
        />
      </Flex>
      {selected && <NewFormulaModal item={selected} show={!!selected} onHideModal={onHideModal} />}
    </>
  );
};

export { FormulaCatalogue };
