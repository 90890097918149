import classNames from 'classnames/bind';
import { Label, Select } from 'library/components';

import styles from './index.module.scss';
const cx = classNames.bind(styles);

const SourceSelect = ({
  value,
  onChange,
  sources,
}: {
  value: string;
  onChange: (val: string) => void;
  sources: string[];
}) => {
  return (
    <>
      <Label className={cx('label')}>Source</Label>
      <Select value={value ?? null} onChange={onChange}>
        <Select.Item value={null}>All Sources</Select.Item>
        {sources.map(source => (
          <Select.Item key={source} value={source}>
            {source}
          </Select.Item>
        ))}
      </Select>
    </>
  );
};

export { SourceSelect };
