import { useState, useEffect } from 'react';

import { formatISODate } from 'utils/formatDate';
import { useSnackbar } from 'notistack';
import { useAuth } from 'react-oidc-context';
import { useQueries, useQuery } from 'react-query';
import { useStaticCall } from 'hooks/useStaticCalls';
import { useFxConversionList } from 'hooks/useFxConversionList';

import { getProcessedData } from 'utils/common';
import staticCalls from 'utils/staticCalls.json';
import { Flex, Hamburger } from 'library/components';

import interpolationType from 'utils/interpolationType.json';
import { SideBarContainer } from './SideBarContainer';
import { PriceCurveViewer } from '../shared/PriceCurveViewer';
import { PriceCurveTable } from '../shared/PriceCurveTable';
import { getFXParams, getCombinedPriceDef, getNewInjectionDate } from './commonFunctions';
import styles from './index.module.scss';
import classNames from 'classnames/bind';
import { none, Month } from 'utils/constants';
import { apiFetch } from 'utils/apiService';

const cx = classNames.bind(styles);

const PriceViewer = () => {
  const [filters, setFilters] = useState({
    selectedTags: [],
    live: true,
    publicationDate: new Date(Date.now() - 24 * 3600_000),
    priceType: null,
    search: '',
    granularity: Month,
    priceProfile: null,
    profileName: none,
    source: null,
  });
  const [conversion, setConversion] = useState({
    reportingUnit: null,
    reportingCurrency: null,
  });
  const [combinedPriceDefData, setCombinedPriceDefData] = useState([]);
  const [processedPriceDefData, setProcessedPriceDefData] = useState([]);
  const [selectedPriceDefinitions, setSelectedPriceDefinitions] = useState([]);
  const [previousIngestionDates, setPreviousIngestionDates] = useState({});

  const commoditiesData = useStaticCall(staticCalls.COMMODITIES);
  const priceDefinitionsData = useStaticCall(staticCalls.PRICEDEFINITION);
  const fxConversionData = useStaticCall(staticCalls.FXDEFINITION);

  const fxConversionList = useFxConversionList();

  const { enqueueSnackbar } = useSnackbar();
  const { user } = useAuth();
  const { data: priceCurvesData, refetch: refetchPriceCurveList } = useQuery({
    queryKey: [
      filters.live ? '/raw/LivePrices/list' : '/raw/PriceCurve/list',
      filters.live ? null : filters.publicationDate,
    ],
    queryFn: ({ queryKey: [url] }) =>
      apiFetch({
        url,
        params: {
          ...(filters.live ? {} : { PublicationDate: formatISODate(filters.publicationDate) }),
        },
        token: user?.access_token,
      }),
    enabled: !!user?.access_token,
  });

  const { data: priceFormulaData } = useQuery({
    queryKey: ['/raw/PriceFormula/list'],
    queryFn: ({ queryKey: [url] }) => apiFetch({ url, token: user?.access_token }),
    enabled: !!user?.access_token,
  });

  const params = selectedPriceDefinitions
    .map(priceDefinition => {
      const { live, publicationDate, granularity } = filters;
      const { name, source, profile, unit } = priceDefinition;
      const fx = getFXParams(priceDefinition, fxConversionData, conversion, filters);

      const payload = {
        Unit: conversion.reportingUnit ?? profile?.reportingUnit ?? unit,
        ...fx,
        'Granularity.GranularityType': granularity,
        'Granularity.InterpolationTypes': interpolationType.LINEAR,
        IsLivePrice: live,
        ...(live ? {} : { PublicationDate: formatISODate(publicationDate) }),
      };

      if (priceDefinition?.entityType === 'PriceFormula') {
        const _params = {
          PriceFormulaName: name,
          PriceFormulaIsPrivate: priceDefinition.isPrivate,
          ...payload,
        };
        return {
          queryKey: ['/core/PriceFormula', _params],
          queryFn: ({ queryKey: [url] }) =>
            apiFetch({
              url,
              params: _params,
              token: user?.access_token,
            }),
          enabled: !!user?.access_token,
          onError: error =>
            enqueueSnackbar(`An Error has occured while fetching FORMULA: ${priceDefinition.name}: ${error}`, {
              variant: 'error',
              preventDuplicate: true,
            }),
        };
      } else {
        const _params = {
          DefinitionName: name,
          Source: source,
          ...payload,
        };
        return {
          queryKey: ['/core/PriceCurve', _params],
          queryFn: ({ queryKey: [url] }) =>
            apiFetch({
              url,
              params: _params,
              token: user?.access_token,
            }),
          enabled: !!user?.access_token,
          onError: error =>
            enqueueSnackbar(
              `An Error has occured while fetching CURVE: ${priceDefinition.name} (${priceDefinition.source}): ${error}`,
              {
                variant: 'error',
                preventDuplicate: true,
              },
            ),
        };
      }
    })
    .filter(x => !!x);

  const resArray = useQueries(params);
  const data = resArray.map(res => res.data);
  const loading = resArray.findIndex(res => res.isLoading) >= 0;
  const error = resArray.find(res => res.isError)?.error;
  const refetchPriceCurves = idx => resArray[idx].refetch();

  useEffect(() => {
    let timeout;
    let cancelled = false;
    if (filters.live) {
      const handler = async () => {
        await refetchPriceCurveList();
        if (!cancelled) {
          timeout = setTimeout(handler, 5_000);
        }
      };
      timeout = setTimeout(handler, 5_000);
    }
    return () => {
      cancelled = true;
      clearTimeout(timeout);
    };
  }, [filters.live, refetchPriceCurveList]);

  useEffect(() => {
    const { newIngestionDates, changed } = getNewInjectionDate(combinedPriceDefData, previousIngestionDates);
    setPreviousIngestionDates(newIngestionDates);

    for (const curve of changed) {
      const selected = selectedPriceDefinitions.findIndex(x => x.source === curve.source && x.name === curve.name);
      if (selected !== -1) {
        refetchPriceCurves(selected);
      }
    }
  }, [combinedPriceDefData, selectedPriceDefinitions]);

  useEffect(() => {
    if (!priceDefinitionsData || !priceCurvesData) {
      setCombinedPriceDefData([]);
      return;
    }
    const combinedData = getCombinedPriceDef(
      priceDefinitionsData,
      priceCurvesData,
      combinedPriceDefData,
      priceFormulaData,
    );
    setCombinedPriceDefData(combinedData);
  }, [priceDefinitionsData, priceCurvesData]);

  useEffect(() => {
    const filteredProcessedData = combinedPriceDefData
      .slice()
      .filter(row => row?.priceCurves?.length || row?.curves?.length);

    let processedData = getProcessedData(filteredProcessedData, filters, conversion, commoditiesData, fxConversionList);

    processedData = processedData.filter(x => x.source !== 'AligneHrly');

    setProcessedPriceDefData(processedData);
  }, [combinedPriceDefData, filters, conversion]);

  const onFilterChange = (key, value) => setFilters(state => ({ ...state, [key]: value }));

  const onConversionChange = (key, value) => setConversion(state => ({ ...state, [key]: value }));

  const onPriceDefinitonsSelectionChange = selection => setSelectedPriceDefinitions(selection.slice(0, 10));

  const sideBar = () => (
    <SideBarContainer
      filters={filters}
      conversion={conversion}
      onFilterChange={onFilterChange}
      onConversionChange={onConversionChange}
      priceDefinitionsData={combinedPriceDefData}
      fxConversionList={fxConversionList}
    />
  );

  return (
    <Hamburger.Container open renderSidebar={sideBar} sidebarWidth={300}>
      <Flex column alignItems="stretch" className={cx('sidebar-left')}>
        <Hamburger.Button />
        <PriceCurveTable
          filters={filters}
          conversion={conversion}
          processedPriceDefinitionsData={processedPriceDefData}
          selectedPriceDefinitions={selectedPriceDefinitions}
          onPriceDefinitonsSelectionChange={onPriceDefinitonsSelectionChange}
          fxConversionData={fxConversionData}
          type="future"
        />
      </Flex>
      <PriceCurveViewer
        priceCurvesData={data}
        loading={loading}
        error={error}
        conversion={conversion}
        timeseriesType="localTimestamp"
      />
    </Hamburger.Container>
  );
};

export { PriceViewer };
