import styles from './index.module.scss';
import classNames from 'classnames/bind';
import PropTypes from 'prop-types';

const cx = classNames.bind(styles);

const TextInput = ({ value, onChange, placeholder, disabled, maxLength }) => {
  const _onChange = event => {
    onChange?.(event.target.value);
  };

  return (
    <input
      className={cx('input')}
      type="text"
      value={value ?? ''}
      onChange={_onChange}
      placeholder={placeholder}
      disabled={disabled}
      maxLength={maxLength}
    />
  );
};

TextInput.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  maxLength: PropTypes.number,
};

export { TextInput };
