import React, { createContext, useContext, useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { useEmbedComponent } from 'hooks/useEmbedComponent';

import { ReactComponent as DropDownIcon } from './dropdown.svg';
import { ReactComponent as CheckIcon } from './check.svg';

import styles from './index.module.scss';
import classNames from 'classnames/bind';
const cx = classNames.bind(styles);

const SelectContext = createContext();

const SearchSelect = ({ value, onChange, disabled, children }) => {
  const { embedded } = useEmbedComponent();
  const [isOpen, setOpen] = useState(false);
  const [searchFocused, setSearchFocused] = useState(false);
  const [search, setSearch] = useState('');
  const [searchTerms, setSearchTerms] = useState([]);
  const container = useRef();

  let options = React.Children.toArray(children);
  options = options.filter(({ type }) => type === SearchSelectItem);
  options = options.map(({ props }) => props);

  const currentOption = options.find(({ value: v }) => value === v);
  let textContent = currentOption?.children ?? '';
  if (Array.isArray(textContent)) {
    textContent = textContent.join('');
  }

  useEffect(() => {
    setSearch(textContent);
  }, [textContent]);

  useEffect(() => {
    const terms = search
      .trim()
      .toLowerCase()
      .split(/[^a-z0-9]+/g)
      .filter(x => !!x);
    setSearchTerms(terms);
  }, [search]);

  const onClickSelect = () => {
    if (disabled) {
      setOpen(false);
      return;
    }

    setOpen(isOpen => !isOpen);
  };

  const onChangeSearch = event => {
    setSearch(event.target.value);
  };

  const onFocusSearch = event => {
    setSearchFocused(true);
    setTimeout(() => event.target.select(), 0);
  };

  const onBlurSearch = () => {
    setSearchFocused(false);
  };

  const onClickMore = () => {
    setSearch('');
  };

  useEffect(() => {
    if (isOpen) {
      const handler = event => {
        if (event.target.closest(`.${cx('container')}`) !== container.current) {
          setOpen(false);
        }
      };

      window.addEventListener('mousedown', handler, false);
      return () => window.removeEventListener('mousedown', handler, false);
    }

    if (!isOpen) {
      setSearch(textContent);
    }
    return undefined;
  }, [isOpen, textContent]);

  const searchFilter = children => {
    if (Array.isArray(children)) {
      children = children.join('');
    }
    const label = children
      .toLowerCase()
      .split(/[^a-z0-9]+/g)
      .filter(x => !!x);
    for (const s of label) {
      for (const term of searchTerms) {
        if (s.startsWith(term)) {
          return true;
        }
      }
    }
    return false;
  };

  if (search.trim()) {
    children = React.Children.toArray(children).filter(child => {
      if (child.type === SearchSelectItem) {
        return searchFilter(child.props.children);
      } else {
        return true;
      }
    });
  }

  return (
    <SelectContext.Provider value={{ selected: value, onChange, setOpen }}>
      <div ref={container} className={cx('container', { embedded })}>
        <span className={cx('select', { disabled })} onClick={onClickSelect}>
          <span>
            <input
              type="text"
              value={search}
              disabled={disabled}
              onChange={onChangeSearch}
              onFocus={onFocusSearch}
              onBlur={onBlurSearch}
            />
          </span>
          <DropDownIcon />
        </span>
        {(searchFocused || isOpen) && (
          <div className={cx('options')}>
            <div className={cx('scroll')}>
              {children}
              {options.length > children.length && (
                <button className={cx('more-button')} onClick={onClickMore}>
                  + {options.length - children.length} more
                </button>
              )}
            </div>
          </div>
        )}
      </div>
    </SelectContext.Provider>
  );
};

SearchSelect.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
  children: PropTypes.node,
  disabled: PropTypes.bool,
};

const SearchSelectItem = ({ value, children }) => {
  const { selected, onChange, setOpen } = useContext(SelectContext);

  const onClick = () => {
    onChange?.(value);
    setOpen(false);
  };

  return (
    <div onClick={onClick} className={cx('option', { selected: selected === value })}>
      {children}

      {selected === value && <CheckIcon />}
    </div>
  );
};

SearchSelectItem.propTypes = {
  value: PropTypes.string,
  children: PropTypes.node,
};

SearchSelect.Item = SearchSelectItem;

export { SearchSelect, SearchSelectItem };
