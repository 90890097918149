import { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Flex, Label, SearchSelect } from 'library/components';

import { useStaticCall } from 'hooks/useStaticCalls';
import staticCalls from 'utils/staticCalls.json';

import styles from './index.module.scss';
import classNames from 'classnames/bind';
const cx = classNames.bind(styles);

const CurveConversions = ({ conversion, onChange, fxConversionList }) => {
  const unitsData = useStaticCall(staticCalls.PRICINGUNITS);
  const filteredUnitsData = useMemo(() => [{ code: null, name: 'Default' }, ...(unitsData || [])], [unitsData]);
  const currencyData = useStaticCall(staticCalls.CURRENCIES);
  const filteredCurrenciesData = useMemo(() => {
    const validCurrencyList = [...new Set(fxConversionList?.map(x => x[0]))];
    const filterCurrency = currencyData?.filter(currency => validCurrencyList.includes(currency.code));
    return [{ code: null, name: 'Default' }, ...(filterCurrency || [])];
  }, [currencyData, fxConversionList]);

  return (
    <Flex column alignItems="stretch" className={cx('curve-conversions')}>
      <Label className={cx('label')}>Reporting Unit:</Label>
      <SearchSelect value={conversion.reportingUnit} onChange={value => onChange('reportingUnit', value)}>
        {filteredUnitsData?.map(unit => (
          <SearchSelect.Item key={unit.code} value={unit.code}>
            {unit.name && unit.code ? `${unit.name} - ${unit.code}` : `${unit.name}`}
          </SearchSelect.Item>
        ))}
      </SearchSelect>

      <Label className={cx('label')}>Reporting Currency:</Label>
      <SearchSelect value={conversion.reportingCurrency} onChange={value => onChange('reportingCurrency', value)}>
        {filteredCurrenciesData?.map(currency => (
          <SearchSelect.Item key={currency.code} value={currency.code}>
            {currency.name && currency.code ? `${currency.name} - ${currency.code}` : `${currency.name}`}
          </SearchSelect.Item>
        ))}
      </SearchSelect>
    </Flex>
  );
};

CurveConversions.propTypes = {
  onChange: PropTypes.func,
  conversion: PropTypes.object,
  fxConversionList: PropTypes.array,
};

export { CurveConversions };
