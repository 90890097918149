import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Flex, Label, TextInput, Select, SearchSelect, Button, CloseButton } from 'library/components';
import Modal from 'react-bootstrap/Modal';
import { useHistory } from 'react-router';

import { useStaticCall } from 'hooks/useStaticCalls';
import staticCalls from 'utils/staticCalls.json';
import granularityDuration from 'utils/granularityDuration.json';

import styles from './index.module.scss';
import classNames from 'classnames/bind';
import { isNullEmptyBlank } from 'utils/validation';

import { useAuth } from 'react-oidc-context';
import { useMutation } from 'react-query';
import { apiFetch } from 'utils/apiService';
import { useSnackbar } from 'notistack';

const cx = classNames.bind(styles);

const NewFormulaModal = ({ item, show, onHideModal }) => {
  const history = useHistory();
  const [state, setState] = useState(() => ({ ...item }));
  const [unitsList, setUnitsList] = useState([]);
  const [isAddFormula, setIsAddFormula] = useState(true);

  const units = useStaticCall(staticCalls.PRICINGUNITS);
  const currencies = useStaticCall(staticCalls.CURRENCIES);
  const commodities = useStaticCall(staticCalls.COMMODITIES);
  const { user } = useAuth();
  const { enqueueSnackbar } = useSnackbar();

  const { mutate: priceFormulaRequest } = useMutation(
    priceFormula =>
      apiFetch({
        method: 'POST',
        url: `/raw/PriceFormula`,
        token: user?.access_token,
        body: priceFormula,
      }),
    {
      queryKey: `POST /raw/PriceFormula`,
      onSuccess: () => onHideModal(true),
      onError: error =>
        enqueueSnackbar(`An Error has occured while saving: ${error}`, {
          variant: 'error',
          preventDuplicate: true,
        }),
    },
  );
  const { mutate: priceFormulaUpdateRequest } = useMutation(
    priceFormula =>
      apiFetch({
        method: 'PUT',
        url: `/raw/PriceFormula/${priceFormula.id}`,
        token: user?.access_token,
        body: priceFormula,
      }),
    {
      queryKey: `POST /raw/PriceFormula/{formulaId}`,
      onSuccess: () => onHideModal(true),
      onError: error =>
        enqueueSnackbar(`An Error has occured while saving: ${error}`, {
          variant: 'error',
          preventDuplicate: true,
        }),
    },
  );

  useEffect(() => {
    if (state.commodity) {
      const selectedCommodityData = commodities.find(data => data.code === state.commodity);
      const commodityUnits = selectedCommodityData?.unitConversionFactors.map(data => data.unit);
      const validUnits = units.filter(data => commodityUnits?.includes(data.code));
      setUnitsList(validUnits);
    }
  }, [units, commodities, state.commodity]);

  useEffect(() => {
    if (
      state &&
      state['name'] &&
      state['description'] &&
      state['commodity'] &&
      state['currency'] &&
      state['granularity'] &&
      state['unit'] &&
      !isNullEmptyBlank(state['name']) &&
      !isNullEmptyBlank(state['description'])
    ) {
      setIsAddFormula(false);
    } else {
      setIsAddFormula(true);
    }
  }, [state]);
  const onChange = (key, value) => {
    if (key === 'commodity') {
      setState(state => ({ ...state, ['unit']: '' }));
    }
    setState(state => ({ ...state, [key]: value }));
  };

  const onClickSyntax = () => {
    history.push({
      pathname: '/price-catalogue/formula/syntax',
      state,
    });
  };

  const onClickSave = async () => {
    const { id, name, description, unit, currency, commodity, granularity, curves, fixedAdder } = state;

    const payload = {
      name,
      description,
      unit,
      currency,
      commodity,
      granularity,
      curves,
      fixedAdder,
    };

    if (id) {
      priceFormulaUpdateRequest({ id, ...payload });
    } else {
      priceFormulaRequest(payload);
    }
  };

  return (
    <Modal show={show} onHide={onHideModal} centered>
      <Modal.Header>
        <Modal.Title>Formula Definition Details</Modal.Title>
        <CloseButton onClick={onHideModal} />
      </Modal.Header>
      <Modal.Body className={cx('modal-body')}>
        <Label>PLEASE FILL OUT ALL FIELDS MARKED BY *asterisk*</Label>
        <Flex column grow alignItems="stretch">
          {!!state.id && (
            <Flex row>
              <Label className={cx('label')}>ID</Label>
              <TextInput value={state.id} disabled />
            </Flex>
          )}
          <Flex row>
            <Label className={cx('label')}>Name *</Label>
            <TextInput value={state.name} onChange={value => onChange('name', value)} />
          </Flex>
          <Flex row>
            <Label className={cx('label')}>Description *</Label>
            <TextInput value={state.description} onChange={value => onChange('description', value)} />
          </Flex>
          <Flex row>
            <Label className={cx('label')}>Commodity *</Label>
            <Select value={state.commodity} onChange={value => onChange('commodity', value)}>
              {commodities?.map(commodity => (
                <Select.Item key={commodity.code} value={commodity.code}>
                  {commodity.group} - {commodity.name}
                </Select.Item>
              ))}
            </Select>
          </Flex>
          <Flex row>
            <Label className={cx('label')}>Unit *</Label>
            <SearchSelect value={state.unit} onChange={value => onChange('unit', value)}>
              {unitsList?.map(unit => (
                <SearchSelect.Item value={unit.code} key={unit.code}>
                  {unit.name && unit.code ? `${unit.name} - ${unit.code}` : `${unit.name}`}
                </SearchSelect.Item>
              ))}
            </SearchSelect>
          </Flex>
          <Flex row>
            <Label className={cx('label')}>Currency *</Label>
            <SearchSelect value={state.currency} onChange={value => onChange('currency', value)}>
              {currencies?.map(currency => (
                <SearchSelect.Item value={currency.code} key={currency.code}>
                  {currency.code} - {currency.name} - {currency.symbol}
                </SearchSelect.Item>
              ))}
            </SearchSelect>
          </Flex>
          <Flex row>
            <Label className={cx('label')}>Granularity *</Label>
            <Select value={state.granularity} onChange={value => onChange('granularity', value)}>
              {granularityDuration.map(granularity => (
                <Select.Item value={granularity.code} key={granularity.code}>
                  {granularity.name}
                </Select.Item>
              ))}
            </Select>
          </Flex>
          <Flex row justifyContent="flex-end" className={cx('button-bar')}>
            {state.id && (
              <Button onClick={onClickSave} disabled={isAddFormula}>
                Save
              </Button>
            )}
            <Button onClick={onClickSyntax} disabled={isAddFormula}>
              {state.id ? 'Check Formula Syntax' : 'Add Formula Syntax'}
            </Button>
          </Flex>
        </Flex>
      </Modal.Body>
    </Modal>
  );
};

NewFormulaModal.propTypes = {
  item: PropTypes.object,
  show: PropTypes.bool,
  onHideModal: PropTypes.func,
};

export { NewFormulaModal };
