import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Flex, Label, Select, TextInput } from 'library/components';

import { useStaticCall } from 'hooks/useStaticCalls';
import staticCalls from 'utils/staticCalls.json';

import styles from './index.module.scss';
import classNames from 'classnames/bind';

const cx = classNames.bind(styles);

const CatalogueFilter = ({ priceProfileData, filters, onChange }) => {
  const priceDefinitionData = useStaticCall(staticCalls.PRICEDEFINITION);
  const commoditiesData = useStaticCall(staticCalls.COMMODITIES);

  const [sources, setSources] = useState([]);

  useEffect(() => {
    if (!priceDefinitionData) {
      setSources([]);
      return;
    }
    setSources(Array.from(new Set(priceDefinitionData.map(row => row.source))));
  }, [priceDefinitionData]);

  return (
    <Flex column alignItems="stretch" className={cx('filter')}>
      <Label className={cx('label')}>Price Profile:</Label>
      <Select value={filters.priceProfile} onChange={value => onChange('priceProfile', value)}>
        <Select.Item value={null}>(none)</Select.Item>
        {priceProfileData?.map(profile => (
          <Select.Item value={profile} key={profile.id}>
            {profile.profileName}
          </Select.Item>
        ))}
      </Select>

      <Label className={cx('label')}>Source</Label>
      <Select value={filters.source ?? null} onChange={value => onChange('source', value)}>
        <Select.Item value={null}>All Sources</Select.Item>
        {sources.map(source => (
          <Select.Item key={source} value={source}>
            {source}
          </Select.Item>
        ))}
      </Select>

      <Label className={cx('label')}>Commodity</Label>
      <Select value={filters.commodity ?? null} onChange={value => onChange('commodity', value)}>
        <Select.Item value={null}>All Commodities</Select.Item>
        {commoditiesData?.map(commodity => (
          <Select.Item key={commodity.code} value={commodity.code}>
            {commodity.group} - {commodity.name}
          </Select.Item>
        ))}
      </Select>

      <Label className={cx('label')}>Price Definition Search:</Label>
      <TextInput value={filters.search} onChange={value => onChange('search', value)} placeholder="Search..." />
    </Flex>
  );
};

CatalogueFilter.propTypes = {
  priceProfileData: PropTypes.array,
  onChange: PropTypes.func,
  filters: PropTypes.shape({
    priceProfile: PropTypes.object,
    source: PropTypes.string,
    commodity: PropTypes.string,
    search: PropTypes.string,
  }),
};

export { CatalogueFilter };
