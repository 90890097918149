import classNames from 'classnames/bind';
import { IfPermission } from 'hooks/usePermissions';
import { Button, DataTable, Flex } from 'library/components';
import { useState } from 'react';
import { useAuth } from 'react-oidc-context';
import { useQuery } from 'react-query';
import { apiFetch } from 'utils/apiService';
import { CreateModal } from './CreateModal';
import { EditModal } from './EditModal';
import PropTypes from 'prop-types';

import styles from './index.module.scss';
const cx = classNames.bind(styles);

const priceProfilesColumns = [{ id: 'name', name: 'Profile Name', field: 'profileName' }];

const PriceProfileList = ({ priceProfileSelection, onPriceProfileSelectionChange }) => {
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);

  const { user } = useAuth();

  const {
    isLoading: priceProfilesLoading,
    data: priceProfilesData,
    error: priceProfilesError,
    refetch,
  } = useQuery({
    queryKey: ['/experience/PriceProfile'],
    queryFn: ({ queryKey: [url] }) => apiFetch({ url, token: user?.access_token }),
    enabled: !!user?.access_token,
  });

  const onClickCreate = () => setShowCreateModal(true);
  const onClickEdit = () => setShowEditModal(true);
  const onHideCreateModal = () => {
    setShowCreateModal(false);
    refetch();
  };
  const onHideEditModal = () => {
    setShowEditModal(false);
    refetch();
  };

  return (
    <>
      <Flex column basis="500px" alignItems="stretch" overflow="hidden">
        <IfPermission type="price-profile" check="write">
          <Flex row alignItems="center" className={cx('box', 'box-row')}>
            <Button onClick={onClickCreate}>Create</Button>
            <Button onClick={onClickEdit} disabled={!priceProfileSelection?.length}>
              Edit
            </Button>
          </Flex>
        </IfPermission>
        <Flex column grow shrink alignItems="stretch" overflow="auto" className={cx('box')}>
          <DataTable
            columns={priceProfilesColumns}
            data={priceProfilesData}
            loading={priceProfilesLoading}
            error={priceProfilesError}
            selection={priceProfileSelection}
            onSelectionChange={onPriceProfileSelectionChange}
            multiSelect={false}
          />
        </Flex>
      </Flex>
      {showCreateModal && <CreateModal show={showCreateModal} onHideModal={onHideCreateModal} />}
      {showEditModal && (
        <EditModal show={showEditModal} onHideModal={onHideEditModal} profile={priceProfileSelection?.[0]} />
      )}
    </>
  );
};

PriceProfileList.propTypes = {
  priceProfileSelection: PropTypes.array,
  onPriceProfileSelectionChange: PropTypes.func,
};

export { PriceProfileList };
