import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { LineChart } from 'library/charts';
import { Flex, DataTable, Ribbon } from 'library/components';
import moment from 'moment';

import { RangeSliderComponent } from '../RangeSliderComponent';
import { formatDateTime } from 'utils/formatDate';
import lineColors from 'utils/lineColors.json';

import styles from './index.module.scss';
import classNames from 'classnames/bind';

const cx = classNames.bind(styles);

const LOCAL_DATE_FORMAT = 'DD-MM-YYYY';

const PriceCurveViewer = ({ priceCurvesData, loading, error, conversion, timeseriesType }) => {
  const [minRange, setMinRange] = useState(null);
  const [maxRange, setMaxRange] = useState(null);
  const [tab, setTab] = useState('chart');
  const [rangedData, setRangedData] = useState(null);
  const [dataTableData, setDataTableData] = useState(null);
  const [dataTableColumns, setDataTableColumns] = useState(null);

  useEffect(() => {
    if (priceCurvesData?.length > 0) {
      const filteredData = priceCurvesData.filter(d => !!d);
      let localTimestamps = new Set();

      filteredData.forEach(dataSet => dataSet.timeseries.forEach(x => localTimestamps.add(x[timeseriesType])));

      localTimestamps = Array.from(localTimestamps).sort((a, b) => new Date(a) - new Date(b));

      if (localTimestamps.length > 0) {
        setMinRange(new Date(localTimestamps[0]));
        setMaxRange(new Date(localTimestamps[localTimestamps.length - 1]));
      }
    } else {
      setMinRange(null);
      setMaxRange(null);
    }
  }, [priceCurvesData, timeseriesType]);

  useEffect(() => {
    if (!rangedData?.length) {
      setDataTableData(null);
      setDataTableColumns(null);
      return;
    }

    let localTimestamps = new Set();
    const timeseries = {};

    for (const dataSet of rangedData) {
      const key = `${dataSet.source}:${dataSet.priceDefinitionName}:${moment(dataSet.publicationDate).format(
        LOCAL_DATE_FORMAT,
      )}`;
      timeseries[key] = {};
      for (const x of dataSet.timeseries) {
        localTimestamps.add(x[timeseriesType]);
        timeseries[key][x[timeseriesType]] = x.value;
      }
    }

    localTimestamps = Array.from(localTimestamps);
    localTimestamps.sort((a, b) => new Date(a) - new Date(b));

    const dataTableData = [];
    for (const localTimestamp of localTimestamps) {
      const convertDateTime = formatDateTime(new Date(localTimestamp));
      const row = { localTimestamp: convertDateTime };
      const filteredData = priceCurvesData.filter(d => !!d);
      for (const dataSet of filteredData) {
        const key = `${dataSet.source}:${dataSet.priceDefinitionName}:${moment(dataSet.publicationDate).format(
          LOCAL_DATE_FORMAT,
        )}`;
        row[key] = timeseries[key] ? timeseries[key][localTimestamp] : null;
      }

      dataTableData.push(row);
    }
    setDataTableData(dataTableData);

    const columns = [];
    columns.push({
      id: 'localTimestamp',
      name: 'Local Timestamp',
      field: 'localTimestamp',
      render: value => formatDateTime(new Date(value)),
    });

    for (const dataSet of rangedData) {
      const key = `${dataSet.source}:${dataSet.priceDefinitionName}:${moment(dataSet.publicationDate).format(
        LOCAL_DATE_FORMAT,
      )}`;
      columns.push({
        id: key,
        name: `${key}:${conversion.reportingUnit ?? dataSet?.reportingUnit}`,
        field: key,
        type: 'float',
        decimalPlaces: 4,
      });
    }

    setDataTableColumns(columns);
  }, [conversion.reportingUnit, priceCurvesData, rangedData, timeseriesType]);

  const onChangeTab = value => setTab(value);

  return (
    <Flex column grow shrink nowrap className={cx('content')} alignItems={'stretch'}>
      <Flex row alignItems={'baseline'} className={cx('box', 'box-row')}>
        {minRange && maxRange && (
          <Flex column nowrap grow className={cx('range-slider')} alignItems={'stretch'}>
            <RangeSliderComponent
              minRange={minRange}
              maxRange={maxRange}
              priceCurvesData={priceCurvesData}
              updateRangedData={setRangedData}
              timeseriesType={timeseriesType}
            />
          </Flex>
        )}
        {(!minRange || !maxRange) && <Flex grow />}
        <Ribbon value={tab} onChange={onChangeTab}>
          <Ribbon.Item value="chart">Chart</Ribbon.Item>
          <Ribbon.Item value="table">Data Table</Ribbon.Item>
        </Ribbon>
      </Flex>
      {tab === 'chart' && (
        <Flex column grow shrink nowrap overflow="auto" className={cx('line-chart')} alignItems={'stretch'}>
          <LineChart
            data={rangedData}
            loading={loading}
            error={error}
            lineColors={lineColors}
            keyExtractor={row => `${row.source}:${row.priceDefinitionName}`}
            lineExtractor={row => row.timeseries}
            timeExtractor={row => moment(new Date(row[timeseriesType]), 'utc').toDate()}
            valueExtractor={row => row.value}
          />
        </Flex>
      )}
      {tab === 'table' && (
        <Flex row grow shrink className={cx('data-table')} alignItems={'stretch'}>
          {dataTableData && (
            <DataTable
              columns={dataTableColumns}
              data={dataTableData || []}
              loading={loading}
              error={error}
              exportToExcel
            />
          )}
        </Flex>
      )}
    </Flex>
  );
};

PriceCurveViewer.propTypes = {
  priceCurvesData: PropTypes.array,
  loading: PropTypes.bool,
  error: PropTypes.any,
  conversion: PropTypes.object,
  timeseriesType: PropTypes.string,
  chartViewFlag: PropTypes.bool,
  toggleViewChange: PropTypes.func,
};

export { PriceCurveViewer };
