import { useState, useEffect, useRef } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { formatDate } from 'utils/formatDate';
import * as d3 from 'd3';
import { ReactComponent as SpinnerIcon } from './spinner.svg';

import styles from './index.module.scss';
import classNames from 'classnames/bind';

const cx = classNames.bind(styles);
const MOUSELINE_CLASS = 'mouse-line';
const MOUSEPERLINE_CLASS = 'mouse-per-line';

const sortData = context => {
  const { keyExtractor, lineExtractor, timeExtractor, valueExtractor } = context;

  const data = {};

  for (const line of context.rawData) {
    const lineData = [];
    const key = keyExtractor(line);
    const timeseries = lineExtractor(line);

    for (const d of timeseries) {
      lineData.push([timeExtractor(d), valueExtractor(d)]);
    }

    lineData.sort((a, b) => {
      if (a[0] > b[0]) {
        return 1;
      }

      if (a[0] < b[0]) {
        return -1;
      }

      return 0;
    });

    data[key] = lineData;
  }

  context.data = data;
};

const updateScale = (context, { maxyw = 0, maxxh = 0 } = {}) => {
  //
  // calculate x-scale, minX, maxX and x-increment
  //
  const xValues = Object.values(context.data)
    .reduce((a, b) => {
      a.push(...b);
      return a;
    }, [])
    .map(d => d[0]);

  const minX = Math.min(...xValues);
  const maxX = Math.max(...xValues);
  const x = context.d3
    .scaleLinear()
    .domain([minX, maxX])
    .range([context.padding.left + maxyw, context.size.width - context.padding.right]);

  x.min = minX;
  x.max = maxX;
  x.padding = context.padding;

  //
  // calculate y-scale, minY, maxY, scale-y and y-increment
  //
  const yValues = Object.values(context.data)
    .reduce((a, b) => {
      a.push(...b);
      return a;
    }, [])
    .map(d => d[1]);

  let minY = Math.min(...yValues);
  let maxY = Math.max(...yValues);

  // calculate order of the scale in base-10
  const orderY = Math.floor(Math.log10(maxY - minY));

  // increment-y is 10^^orderY
  let incrY = Math.pow(10, orderY);

  // find increments based on best fit for 10 ticks at 0.2, 0.25, 0.5 and 1
  const diffY = maxY - minY;
  const distanceY = incrY => Math.abs(10 - diffY / incrY);

  const tickYCandidates = [incrY, incrY / 2, incrY / 4, incrY / 5];
  tickYCandidates.sort((a, b) => distanceY(a) - distanceY(b));
  incrY = tickYCandidates[0];

  // adjust minY and maxY to be at a round tick value below and above minY/maxY
  minY = Math.floor(minY / incrY) * incrY - incrY;
  maxY = Math.ceil(maxY / incrY) * incrY;

  const y = context.d3
    .scaleLinear()
    .domain([minY, maxY])
    .range([context.size.height - (context.padding.bottom + maxxh), context.padding.top]);

  y.min = minY;
  y.max = maxY;
  y.order = orderY;
  y.incr = incrY;
  y.padding = context.padding;

  context.scale = { x, y };
};

const updateLines = context => {
  let i = 0;
  for (const k in context.data) {
    const lineGenerator = context.d3.line();
    lineGenerator.curve(context.d3.curveMonotoneX);

    const lineData = context.data[k].map(d => [context.scale.x(d[0]), context.scale.y(d[1])]);

    const lineString = lineGenerator(lineData);
    ++i;
    const lineClass = cx(`line-${i}`);
    const u = context.d3
      .select(context.svg)
      .selectAll(`path.${cx('line')}.${lineClass}`)
      .data([{ lineString }]);

    u.enter()
      .append('path')
      .attr('class', cx('line', `line-${i}`))
      .attr('stroke', context.lineColors[i - 1] ?? '#FFFFFF')
      .merge(u)
      .attr('d', d => d.lineString);
  }
};

const yTicks = context => {
  const ticks = new Set();

  for (let i = context.scale.y.min + context.scale.y.incr; i <= context.scale.y.max; i += context.scale.y.incr) {
    ticks.add(i);
  }

  if (!isNaN(context.scale.y.max)) {
    ticks.add(context.scale.y.max);
  }

  return Array.from(ticks);
};

const xTicks = context => {
  const ticks = [];

  const diffX = context.scale.x.max - context.scale.x.min;
  const day = 24 * 3600_000;

  if (diffX === 0) {
    ticks.push(context.scale.x.min);
  } else {
    // calculate order of the scale in base-10
    const orderX = Math.floor(Math.log10(diffX / day));

    // increment-y is 10^^orderY
    const incrX = Math.pow(10, orderX);

    // find increments based on best fit for 10 ticks at 0.2, 0.25, 0.5 and 1
    const distanceX = incrX => Math.abs(10 - diffX / (day * incrX));

    const tickXCandidates = [incrX, incrX / 2, incrX / 4, incrX / 5];
    tickXCandidates.sort((a, b) => distanceX(a) - distanceX(b));
    const incr = tickXCandidates[0];

    const start = moment.utc(context.scale.x.min).startOf('month').toDate().getTime();

    let i = start;
    while (i <= context.scale.x.max) {
      if (!moment.utc(i).add(incr, 'day').isSame(i, 'month')) {
        i = moment.utc(i).add(1, 'month').startOf('month').toDate().getTime();
      }

      if (i >= context.scale.x.min && i <= context.scale.x.max) {
        ticks.push(i);
      }
      i += incr * day;
    }
  }

  return Array.from(ticks);
};

const updateAxisY = context => {
  context.d3
    .select(context.svg)
    .select(`g.${cx('y-axis')}`)
    .remove();

  const u = context.d3.select(context.svg).append('g').attr('class', cx('y-axis'));

  const orderY = Math.floor(Math.log10(context.scale.y.incr));

  const y = context.d3
    .axisLeft(context.scale.y)
    .tickValues(yTicks(context))
    .tickFormat(d => {
      if (orderY >= 0) {
        return d.toFixed(0);
      } else {
        return d.toFixed(-orderY);
      }
    })
    .tickSize(-context.size.width)
    .tickPadding(10);

  const gy = u.call(y).call(g => g.select('.domain').remove());

  let maxyw = 0;
  gy.selectAll('text').each(function () {
    if (this.getBBox().width > maxyw) {
      maxyw = this.getBBox().width;
    }
  });

  maxyw += 10;

  gy.attr('transform', `translate(${maxyw}, 0)`);

  return { maxyw };
};

const updateAxisX = context => {
  context.d3.select(context.svg).select('nonsense');
  context.d3
    .select(context.svg)
    .select(`g.${cx('xaxis')}`)
    .remove();

  const u = context.d3.select(context.svg).append('g').attr('class', cx('xaxis'));

  const ticks = xTicks(context);
  const x = context.d3
    .axisBottom(context.scale.x)
    .tickValues(ticks)
    .tickFormat(d => formatDate(new Date(d)))
    .tickPadding(context.padding.bottom);

  const gx = u.call(x).call(g => g.select('.domain').attr('d', `M0, 0.5V0.5H${context.size.width}`));

  let maxxh = 0;
  gx.selectAll('text').each(function () {
    if (this.getBBox().width > maxxh) {
      maxxh = this.getBBox().width;
    }
  });

  gx.attr('transform', `translate(0, ${context.size.height - (context.padding.bottom + maxxh)})`);

  return { maxxh };
};

const updateMouseOver = context => {
  const data = Object.entries(context.data);

  const g = context.d3.select(context.svg).append('g').attr('class', cx('mouse-over-effects'));

  g.append('path').attr('class', cx(MOUSELINE_CLASS));

  const lines = context.svg.getElementsByClassName(cx('line'));
  const mousePerLine = g
    .selectAll(`.${cx(MOUSEPERLINE_CLASS)}`)
    .data(data)
    .enter()
    .append('g')
    .attr('class', cx(MOUSEPERLINE_CLASS));

  mousePerLine
    .append('circle')
    .attr('r', 4)
    .style('fill', d => {
      const index = data.indexOf(d);
      return context.lineColors[index] ?? '#fff';
    });

  mousePerLine.append('text').attr('transform', 'translate(7,3)').style('opacity', '0');

  const rect = g
    .append('svg:rect')
    .attr('width', context.size.width)
    .attr('height', context.size.height)
    .attr('fill', 'none')
    .attr('pointer-events', 'all');

  rect.on('mouseout', () => {
    g.select(`.${cx(MOUSELINE_CLASS)}`).style('opacity', '0');
    g.selectAll(`.${cx(MOUSEPERLINE_CLASS)} circle`).style('opacity', '0');
    g.selectAll(`.${cx(MOUSEPERLINE_CLASS)} text`).style('opacity', '0');
  });

  rect.on('mouseover', () => {
    g.select(`.${cx(MOUSELINE_CLASS)}`).style('opacity', '1');
    g.selectAll(`.${cx(MOUSEPERLINE_CLASS)} circle`).style('opacity', '1');
    g.selectAll(`.${cx(MOUSEPERLINE_CLASS)} text`).style('opacity', '1');
  });

  rect.on('mousemove', event => {
    const pointer = context.d3.pointer(event);

    g.select(`.${cx(MOUSELINE_CLASS)}`).attr('d', () => {
      let d = `M${pointer[0]},${context.scale.y(context.scale.y.min)}`;
      d += ` ${pointer[0]},0`;
      return d;
    });

    g.selectAll(`.${cx(MOUSEPERLINE_CLASS)}`).attr('transform', function (d, i) {
      let beginning = 0;
      let end = lines[i].getTotalLength();
      let target = null;
      let pos;

      while (true) {
        target = Math.floor((beginning + end) / 2);
        pos = lines[i].getPointAtLength(target);

        if ((target === end || target === beginning) && pos.x !== pointer[0]) {
          break;
        }

        if (pos.x > pointer[0]) {
          end = target;
        } else if (pos.x < pointer[0]) {
          beginning = target;
        } else {
          break;
        }
      }

      context.d3.select(this).select('text').text(context.scale.y.invert(pos.y).toFixed(4));
      return `translate(${pointer[0]},${pos.y})`;
    });
  });
};

const LineChart = ({ data, loading, keyExtractor, lineExtractor, timeExtractor, valueExtractor, lineColors }) => {
  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);
  const container = useRef();

  const onUpdateChart = () => {
    const svg = container.current.querySelector('svg');

    const context = {
      rawData: data,
      d3,
      svg,
      size: { width, height },
      padding: {
        top: 10,
        bottom: 10,
        left: 10,
        right: 10,
      },
      keyExtractor,
      lineExtractor,
      timeExtractor,
      valueExtractor,
      lineColors,
    };

    sortData(context);
    updateScale(context);

    const { maxyw } = updateAxisY(context);
    updateScale(context, { maxyw });

    const { maxxh } = updateAxisX(context);
    updateScale(context, { maxyw, maxxh });

    updateAxisY(context);
    updateAxisX(context);

    updateLines(context);

    updateMouseOver(context);
  };

  useEffect(() => {
    const svg = container.current.querySelector('svg');
    while (svg.childNodes.length) {
      svg.childNodes[0].remove();
    }

    if (d3 && data && container.current && width && height) {
      onUpdateChart();

      const resizeObserver = new ResizeObserver(() => {
        const { width, height } = container.current?.getBoundingClientRect() ?? {
          width: 0,
          height: 0,
        };
        setWidth(width);
        setHeight(height);
      });

      resizeObserver.observe(container.current);
      return () => resizeObserver.unobserve(container.current);
    }
    return undefined;
  }, [d3, data, width, height]);

  const onRef = ref => {
    const { width, height } = ref?.getBoundingClientRect() ?? {
      width: 0,
      height: 0,
    };
    setWidth(width);
    setHeight(height);
    container.current = ref;
  };

  return (
    <div ref={onRef} className={cx('container')}>
      <svg viewBox={`0 0 ${width} ${height}`} />
      {loading && (
        <div className={cx('loading')}>
          <SpinnerIcon />
          <span>Loading...</span>
        </div>
      )}
    </div>
  );
};

LineChart.propTypes = {
  data: PropTypes.array,
  loading: PropTypes.bool,
  keyExtractor: PropTypes.func,
  lineExtractor: PropTypes.func,
  timeExtractor: PropTypes.func,
  valueExtractor: PropTypes.func,
  lineColors: PropTypes.array,
};

export { LineChart };
