import { useLocation, useHistory } from 'react-router-dom';
import { Tabs } from 'library/components';
import { HeaderMenu } from 'components/HeaderMenu';

import styles from './index.module.scss';
import classNames from 'classnames/bind';

const cx = classNames.bind(styles);

const Header = () => {
  const location = useLocation();
  const history = useHistory();

  const onChangeTab = value => {
    history.replace(value);
  };

  return (
    <div className={cx('container')}>
      <Tabs value={location.pathname} onChange={onChangeTab} matchPrefix>
        <Tabs.Item value={'/price-catalogue'}>Curve Catalogue</Tabs.Item>
        <Tabs.Item value={'/price-viewer'}>Price Viewer</Tabs.Item>
        <Tabs.Item value={'/historical'}>Time Series Analysis</Tabs.Item>
        <Tabs.Item value={'/price-profile'}>Price Profiles</Tabs.Item>
        <Tabs.Item value={'/bulk-retrieval'}>Bulk Retrieval</Tabs.Item>
      </Tabs>
      <HeaderMenu />
    </div>
  );
};

export { Header };
