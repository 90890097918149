import classNames from 'classnames/bind';
import { Button, Label, SearchSelect } from 'library/components';
import { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import staticCalls from '../../utils/staticCalls.json';

import styles from './index.module.scss';
import { useStaticCall } from 'hooks/useStaticCalls';
const cx = classNames.bind(styles);

const ApplyReportingUnit = ({
  priceProfileSelection,
  priceCurveSelection,
  setPriceProfileSelection,
  getErrorMessage,
  onClickSave,
}) => {
  const [unit, setUnit] = useState(null);

  const unitsData = useStaticCall(staticCalls.PRICINGUNITS);
  const priceDefinitionData = useStaticCall(staticCalls.PRICEDEFINITION);
  const commoditiesData = useStaticCall(staticCalls.COMMODITIES);

  const filteredUnitsData = useMemo(() => [{ code: null, name: 'Default' }, ...(unitsData || [])], [unitsData]);

  const checkCommodities = unit => {
    return commoditiesData
      .filter(commodity =>
        commodity?.unitConversionFactors?.map(unitConversion => unitConversion?.unit)?.includes(unit),
      )
      .map(data => data.code);
  };

  const onClickApplyUnit = async () => {
    const selected = priceProfileSelection?.[0];
    const selectedList = priceCurveSelection?.length > 0 ? priceCurveSelection : selected.curves;
    if (!unit) {
      selectedList.forEach(row => {
        const originalUnit = priceDefinitionData?.find(
          priceDefinition => priceDefinition.name === row.priceDefinitionName,
        )?.unit;
        row.reportingUnit = originalUnit;
      });
    } else {
      const allowedCommodities = checkCommodities(unit);
      const incompatibleList = [];
      selectedList.forEach(row => {
        const commodity = priceDefinitionData?.find(
          priceDefinition => priceDefinition.name === row.priceDefinitionName,
        )?.commodity;
        if (allowedCommodities.includes(commodity)) {
          row.reportingUnit = unit;
        } else {
          incompatibleList.push(row);
        }
      });
      if (incompatibleList.length) {
        getErrorMessage('unit');
      }
    }
    selected.curves = selected.curves.slice();
    await onClickSave();
    setPriceProfileSelection([selected]);
  };

  return (
    <>
      <Label className={cx('label')}>Reporting Unit:</Label>
      <SearchSelect value={unit} onChange={value => setUnit(value)} disabled={priceProfileSelection?.length === 0}>
        {filteredUnitsData?.map(unit => (
          <SearchSelect.Item value={unit.code} key={unit.code}>
            {unit.name && unit.code ? `${unit.name} - ${unit.code}` : `${unit.name}`}
          </SearchSelect.Item>
        ))}
      </SearchSelect>
      <Button onClick={onClickApplyUnit} className={cx('button')} disabled={priceProfileSelection?.length === 0}>
        Apply
      </Button>
    </>
  );
};

ApplyReportingUnit.propTypes = {
  priceProfileSelection: PropTypes.array,
  priceCurveSelection: PropTypes.array,
  setPriceProfileSelection: PropTypes.func,
  getErrorMessage: PropTypes.func,
  onClickSave: PropTypes.func,
};

export { ApplyReportingUnit };
