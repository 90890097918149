import { useState } from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-bootstrap/Modal';
import { Button, CloseButton, Flex, Label, TextInput } from 'library/components';
import styles from './EditModal.module.scss';
import classNames from 'classnames/bind';
import { useAuth } from 'react-oidc-context';
import { useMutation } from 'react-query';
import { apiFetch } from 'utils/apiService';

const cx = classNames.bind(styles);

const EditModal = ({ show, onHideModal, profile }) => {
  const [name, setName] = useState(profile.profileName);

  const onChangeName = value => setName(value);

  const { user } = useAuth();
  const { mutateAsync: updatePriceProfile } = useMutation(
    priceProfile =>
      apiFetch({
        method: 'PUT',
        url: `/experience/PriceProfile/${profile.id}`,
        token: user?.access_token,
        body: priceProfile,
      }),
    {
      queryKey: `PUT /experience/PriceProfile`,
    },
  );

  const onClickSave = async () => {
    await updatePriceProfile({
      ...profile,
      profileName: name,
    });
    onHideModal?.();
  };

  return (
    <Modal show={show} onHide={onHideModal} centered>
      <Modal.Header>
        <Modal.Title>Edit Price Profile</Modal.Title>
        <CloseButton onClick={onHideModal} />
      </Modal.Header>
      <Modal.Body>
        <Flex row alignItems="center">
          <Label className={cx('label')}>Name</Label>
          <TextInput value={name} onChange={onChangeName} />
        </Flex>
        <Flex row justifyContent="flex-end" className={cx('button-bar')}>
          <Button onClick={onClickSave}>Save</Button>
        </Flex>
      </Modal.Body>
    </Modal>
  );
};

EditModal.propTypes = {
  show: PropTypes.bool,
  onHideModal: PropTypes.func,
  profile: PropTypes.object,
};

export { EditModal };
