import classNames from 'classnames/bind';
import { Label, TextInput } from '../../library/components';

import styles from './index.module.scss';
const cx = classNames.bind(styles);

const PriceDefinitionSearch = ({ value, onChange }: { value: string; onChange: (val: string) => void }) => {
  return (
    <>
      <Label className={cx('label')}>Price Definition Search:</Label>
      <TextInput value={value} onChange={onChange} placeholder="Search..." />
    </>
  );
};

export { PriceDefinitionSearch };
