import { useState, useEffect, useMemo } from 'react';
import { useDebounce } from 'hooks/useDebounce';

import { DataTable, Flex, Label, MultiSelect, Select, TextInput } from 'library/components';
import moment from 'moment';
import { useStaticCall } from 'hooks/useStaticCalls';
import { getSearchedText } from 'utils/common';
import staticCalls from 'utils/staticCalls.json';
import { ItemModal } from './ItemModal';

import styles from './index.module.scss';
import classNames from 'classnames/bind';

import { useQuery } from 'react-query';
import { useAuth } from 'react-oidc-context';
import { apiFetch } from 'utils/apiService';

const cx = classNames.bind(styles);

const FXCatalogue = () => {
  const [sources, setSources] = useState([]);
  const [source, setSource] = useState(null);

  const [tags, setTags] = useState([]);
  const [selectedTags, setSelectedTags] = useState([]);

  const [priceType, setPriceType] = useState(null);

  const [selected, setSelected] = useState(null);

  const [search, setSearch] = useState('');
  const debouncedSearch = useDebounce(search, 300);

  const [sortBy, setSortBy] = useState(null);

  const priceTypes = useStaticCall(staticCalls.PRICETYPES);

  const { user } = useAuth();
  const {
    data,
    isLoading: loading,
    isError: error,
  } = useQuery({
    queryKey: ['/Raw/FxDefinition'],
    queryFn: ({ queryKey: [url] }) => apiFetch({ url, token: user?.access_token }),
    enabled: !!user?.access_token,
  });

  const [processedData, setProcessedData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);

  useEffect(() => {
    if (!data) {
      setSources([]);
      setTags([]);
      return;
    }

    setSources(Array.from(new Set(data.map(row => row.source))));
    setTags(
      Array.from(
        data.reduce((a, b) => {
          b.tags.forEach(tag => a.add(tag));
          return a;
        }, new Set()),
      ),
    );
  }, [data]);

  useEffect(() => {
    let processedData = data ?? [];

    if (source !== null) {
      processedData = processedData.filter(row => row.source === source);
    }

    if (selectedTags.length) {
      processedData = processedData.filter(row => {
        for (const tag of row.tags) {
          if (selectedTags.includes(tag)) {
            return true;
          }
        }
        return false;
      });
    }
    processedData.forEach(row => {
      const nameLower = row.name
        .trim()
        .toLowerCase()
        .split(/[|]/g)
        .filter(x => !!x);

      const descLower = (row.description || '')
        .trim()
        .toLowerCase()
        .split(/[|]| /g)
        .filter(x => !!x);

      row.searchTerms = [...nameLower, ...descLower];
    });

    if (priceType !== null) {
      processedData = processedData.filter(row => row.priceType === priceType);
    }

    setProcessedData(processedData);
  }, [data, source, selectedTags, priceType]);

  useEffect(() => {
    const filteredData = getSearchedText(processedData, debouncedSearch);
    setFilteredData(filteredData);
  }, [processedData, debouncedSearch]);

  const columns = useMemo(
    () =>
      [
        {
          id: 'source',
          name: 'Source',
          field: 'source',
          sort: true,
        },
        {
          id: 'name',
          name: 'Name',
          field: 'name',
          sort: true,
        },
        {
          id: 'granularity',
          name: 'Granularity',
          function: row =>
            moment
              .duration(['T', 'P'].includes(row.granularity[0]) ? row.granularity : `P${row.granularity}`)
              .humanize(),
          sort: true,
        },
        {
          id: 'priceType',
          name: 'Price Type',
          function: row => priceTypes?.find(d => d.code === row.priceType)?.name,
          sort: true,
        },
        {
          id: 'description',
          name: 'Description',
          field: 'description',
          sort: true,
        },
        {
          id: 'tags',
          name: 'Tags',
          field: 'tags',
          render: tags => (
            <Flex row wrap className={cx('tags')}>
              {tags.map((tag, i) => (
                <div className={cx('tag')} key={`${i}`}>
                  {tag}
                </div>
              ))}
            </Flex>
          ),
        },
      ].filter(x => !!x),
    [priceTypes],
  );

  const onChangeSource = value => setSource(value);
  const onChangeSelectedTags = value => setSelectedTags(value.slice());
  const onChangePriceType = value => setPriceType(value);
  const onChangeSearch = value => setSearch(value);
  const onChangeSortBy = value => setSortBy(value);

  const onClickRow = row => setSelected(row);
  const onHideModal = () => setSelected(null);

  return (
    <Flex row alignItems="stretch" grow shrink overflow="hidden">
      <Flex column alignItems="stretch" overflow="hidden" grow shrink>
        <Flex row alignItems="center" className={cx('toolbar')}>
          <Flex grow />
          <Label className={cx('label')}>Tags:</Label>
          <MultiSelect value={selectedTags} onChange={onChangeSelectedTags}>
            {tags.map(tag => (
              <MultiSelect.Item value={tag} key={tag}>
                {tag}
              </MultiSelect.Item>
            ))}
          </MultiSelect>
        </Flex>
        <Flex column alignItems="stretch" grow shrink overflow="auto" className={cx('main-data-table')}>
          <DataTable
            columns={columns}
            data={filteredData}
            loading={loading}
            error={error}
            onClickRow={onClickRow}
            sortBy={sortBy}
            onChangeSortBy={onChangeSortBy}
          />
        </Flex>
      </Flex>

      <Flex column alignItems="stretch" flexBasis="250px" overflow="auto" className={cx('sidebar')}>
        <Label className={cx('label')}>Source</Label>
        <Select value={source ?? null} onChange={onChangeSource}>
          <Select.Item value={null}>All Sources</Select.Item>
          {sources.map(source => (
            <Select.Item key={source} value={source}>
              {source}
            </Select.Item>
          ))}
        </Select>

        <Label className={cx('label')}>Price Type</Label>
        <Select value={priceType ?? null} onChange={onChangePriceType}>
          <Select.Item value={null}>All Price Types</Select.Item>
          {priceTypes?.map(priceType => (
            <Select.Item key={priceType.code} value={priceType.code}>
              {priceType.name}
            </Select.Item>
          ))}
        </Select>

        <Label className={cx('label')}>FX Definition Search:</Label>
        <TextInput value={search} onChange={onChangeSearch} placeholder="Search..." />
      </Flex>
      {selected && <ItemModal item={selected} show={!!selected} onHideModal={onHideModal} tags={tags} />}
    </Flex>
  );
};

export { FXCatalogue };
