import { useMemo, useState, useEffect, useRef } from 'react';
import { Button, Flex, DataTable } from 'library/components';
import { useSnackbar } from 'notistack';

import { useDebounce } from 'hooks/useDebounce';
import { useAuth } from 'react-oidc-context';
import { useQuery } from 'react-query';
import { useStaticCall } from 'hooks/useStaticCalls';
import staticCalls from 'utils/staticCalls.json';
import { getProcessedData, getSearchedText } from 'utils/common';

import { CatalogueFilter } from './CatalogueFilter';
import styles from './index.module.scss';
import classNames from 'classnames/bind';
import { apiFetch } from 'utils/apiService';
import { DataDownload } from './DataDownload';

const cx = classNames.bind(styles);

const BulkRetrieval = () => {
  const priceDefinitionData = useStaticCall(staticCalls.PRICEDEFINITION);
  const unitsData = useStaticCall(staticCalls.PRICINGUNITS);
  const commoditiesData = useStaticCall(staticCalls.COMMODITIES);
  const timer = useRef(null);

  const { enqueueSnackbar } = useSnackbar();

  const [sortBy, setSortBy] = useState(null);
  const [filters, setFilters] = useState({
    priceProfile: null,
    source: null,
    unit: null,
    currency: null,
    commodity: null,
    search: '',
  });
  const [processedData, setProcessedData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [selectedData, setSelectedData] = useState([]);
  const [selectedPriceCurve, setSelectedPriceCurve] = useState([]);
  const [bulkRequestId, setBulkRequestId] = useState(null);
  const debouncedSearch = useDebounce(filters.search, 300);
  const { user } = useAuth();

  const { data: priceProfileData } = useQuery({
    queryKey: [`/experience/PriceProfile`],
    queryFn: ({ queryKey: [url] }) => apiFetch({ url, token: user?.access_token }),
    enabled: !!user?.access_token,
  });

  useEffect(() => {
    const filteredData = getSearchedText(processedData, debouncedSearch);
    setFilteredData(filteredData);
  }, [processedData, debouncedSearch]);

  useEffect(() => {
    const processedData = getProcessedData(priceDefinitionData, filters);
    setProcessedData(processedData);
  }, [priceDefinitionData, filters]);

  const catalogueColumns = useMemo(
    () =>
      [
        {
          id: 'source',
          name: 'Source',
          field: 'source',
          sort: true,
        },
        {
          id: 'name',
          name: 'Name',
          field: 'name',
          sort: true,
        },
        {
          id: 'unit',
          name: 'Unit',
          function: row => unitsData?.find(d => d.code === row.unit)?.name,
          sort: true,
        },
        { id: 'currency', name: 'Currency', field: 'currency', sort: true },
        {
          id: 'commodity',
          name: 'Commodity',
          function: row => commoditiesData?.find(d => d.code === row.commodity)?.name,
          sort: true,
        },
        {
          id: 'description',
          name: 'Description',
          field: 'description',
          sort: true,
        },
      ].filter(x => !!x),
    [commoditiesData, unitsData],
  );

  const onChange = (key, value) => setFilters(state => ({ ...state, [key]: value }));

  const onChangeSortBy = value => setSortBy(value);

  const resetAll = () => {
    setBulkRequestId(null);
    clearInterval(timer);
    timer.current = null;
  };

  const onSelectedPriceCurveChange = row => setSelectedPriceCurve(row.slice(0, 20));

  const onSelectClick = () => {
    const newSelectedDataList = [...new Set([...selectedData, ...selectedPriceCurve])];
    if (newSelectedDataList.length > 20) {
      newSelectedDataList.splice(20, newSelectedDataList.length - 20);
      enqueueSnackbar('Only 20 records can be processed at once', {
        variant: 'warning',
        preventDuplicate: true,
      });
    }

    setSelectedData(newSelectedDataList);
    setSelectedPriceCurve([]);
    resetAll();
  };

  return (
    <Flex row grow shrink alignItems="stretch" overflow="hidden">
      <Flex row grow shrink alignItems="stretch" className={cx('left-container')}>
        <CatalogueFilter priceProfileData={priceProfileData} filters={filters} onChange={onChange} />
        <Flex column grow shrink alignItems="stretch">
          <Flex row>
            <Button
              className={cx('button')}
              onClick={onSelectClick}
              disabled={selectedPriceCurve?.length > 0 ? false : true}>
              Add to Selection
            </Button>
          </Flex>
          <Flex row grow shrink overflow="auto" alignItems="stretch">
            <DataTable
              columns={catalogueColumns}
              data={filteredData}
              selection={selectedPriceCurve}
              onSelectionChange={onSelectedPriceCurveChange}
              sortBy={sortBy}
              onChangeSortBy={onChangeSortBy}
            />
          </Flex>
        </Flex>
      </Flex>
      <Flex column alignItems="stretch" className={cx('right-container')}>
        <DataDownload
          timer={timer}
          selectedData={selectedData}
          setSelectedData={setSelectedData}
          bulkRequestId={bulkRequestId}
          setBulkRequestId={setBulkRequestId}
        />
      </Flex>
    </Flex>
  );
};

export { BulkRetrieval };
