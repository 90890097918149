import { useState } from 'react';
import { useSnackbar } from 'notistack';
import { useAuth } from 'react-oidc-context';
import { useMutation } from 'react-query';
import { IfPermission } from 'hooks/usePermissions';
import { Button, Flex } from 'library/components';
import { AddModal } from './AddModal';
import styles from './index.module.scss';
import classNames from 'classnames/bind';
import { apiFetch } from 'utils/apiService';
import { PriceProfileList } from './PriceProfileList';
import { PriceProfileCurvesList } from './PriceProfileCurvesList';
import { ApplyReportingCurrency } from './ApplyReportingCurrency';
import { ApplyReportingUnit } from './ApplyReportingUnit';

const cx = classNames.bind(styles);

const PriceProfile = () => {
  const [priceProfileSelection, setPriceProfileSelection] = useState([]);
  const [priceCurveSelection, setPriceCurveSelection] = useState([]);
  const [showAddModal, setShowAddModal] = useState(false);

  const { enqueueSnackbar } = useSnackbar();

  const { user } = useAuth();

  const { mutateAsync: updatePriceProfile } = useMutation(
    priceProfile =>
      apiFetch({
        method: 'PUT',
        url: `/experience/PriceProfile/${priceProfile?.id}`,
        token: user?.access_token,
        body: priceProfile,
      }),
    {
      queryKey: `PUT /experience/PriceProfile/{id}`,
    },
  );

  const onPriceProfileSelectionChange = value => {
    setPriceProfileSelection(value.slice(value.length - 1));
    setPriceCurveSelection([]);
  };

  const onClickSave = async () => {
    const selected = priceProfileSelection?.[0];
    await updatePriceProfile(selected);
    setPriceProfileSelection([]);
  };

  const onClickRemove = async () => {
    const selected = priceProfileSelection?.[0];

    for (const curve of priceCurveSelection) {
      const index = selected.curves.indexOf(curve);
      if (index !== -1) {
        selected.curves.splice(index, 1);
      }
    }

    selected.curves = selected.curves.slice();
    await onClickSave();
    setPriceProfileSelection([selected]);
  };

  const onAddModalSavedData = async () => {
    const selected = priceProfileSelection?.[0];
    selected.curves = selected.curves.slice();
    await onClickSave();
    setPriceProfileSelection([selected]);
  };

  const getErrorMessage = type => {
    const message = `Some price definitions are incompatible with the selected ${type}`;
    return enqueueSnackbar(message, {
      variant: 'error',
      preventDuplicate: true,
    });
  };

  return (
    <>
      <Flex row grow shrink alignItems="stretch" overflow="hidden">
        <PriceProfileList
          priceProfileSelection={priceProfileSelection}
          onPriceProfileSelectionChange={onPriceProfileSelectionChange}
        />
        <Flex column grow shrink alignItems="stretch" overflow="hidden" className={cx('box-column')}>
          <IfPermission type="price-profile" check="write">
            <Flex row alignItems="center" className={cx('box', 'box-row')}>
              <Button onClick={onClickSave} disabled={priceProfileSelection?.length !== 1}>
                Save
              </Button>
              <Button onClick={() => setShowAddModal(true)} disabled={priceProfileSelection?.length !== 1}>
                Add
              </Button>
              <Button
                onClick={onClickRemove}
                disabled={priceCurveSelection?.length === 0 || priceProfileSelection?.length === 0}>
                Remove
              </Button>
              <Flex grow />
              <ApplyReportingUnit
                priceProfileSelection={priceProfileSelection}
                priceCurveSelection={priceCurveSelection}
                setPriceProfileSelection={setPriceProfileSelection}
                getErrorMessage={getErrorMessage}
                onClickSave={onClickSave}
              />
              <ApplyReportingCurrency
                priceProfileSelection={priceProfileSelection}
                priceCurveSelection={priceCurveSelection}
                setPriceProfileSelection={setPriceProfileSelection}
                getErrorMessage={getErrorMessage}
                onClickSave={onClickSave}
              />
            </Flex>
          </IfPermission>
          <PriceProfileCurvesList
            priceProfileSelection={priceProfileSelection}
            priceCurveSelection={priceCurveSelection}
            onPriceCurveSelectionChange={value => setPriceCurveSelection(value.slice())}
          />
        </Flex>
      </Flex>
      {showAddModal && (
        <AddModal
          show={showAddModal}
          onHideModal={() => setShowAddModal(false)}
          curves={priceProfileSelection?.[0]?.curves}
          onSavedData={onAddModalSavedData}
        />
      )}
    </>
  );
};

export { PriceProfile };
