import styles from './index.module.scss';
import classNames from 'classnames/bind';

import PropTypes from 'prop-types';

const cx = classNames.bind(styles);

const Grid = ({ className, children }) => {
  return <div className={cx('container', className)}>{children}</div>;
};

const GridItem = ({ className, row, column, rowSpan, colSpan, children }) => {
  const rowSpanStyle = rowSpan ? ` / span ${rowSpan}` : '';
  const colSpanStyle = colSpan ? ` / span ${colSpan}` : '';
  return (
    <div
      className={cx('item', className)}
      style={{
        gridRow: `${row}${rowSpanStyle}`,
        gridColumn: `${column}${colSpanStyle}`,
      }}>
      {children}
    </div>
  );
};

Grid.Item = GridItem;

Grid.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
};

GridItem.propTypes = {
  className: PropTypes.string,
  row: PropTypes.any,
  column: PropTypes.any,
  rowSpan: PropTypes.any,
  colSpan: PropTypes.any,
  children: PropTypes.node,
};

export { Grid, GridItem };
