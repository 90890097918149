import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-bootstrap/Modal';
import { Flex, Label, TextInput } from 'library/components';
import moment from 'moment';

import styles from './ItemModal.module.scss';
import classNames from 'classnames/bind';

const cx = classNames.bind(styles);

const ItemModal = ({ item, show, onHideModal }) => {
  const [state, setState] = useState(() => ({ ...item }));

  useEffect(() => {
    if (item) {
      setState({ ...item });
    } else {
      setState({});
    }
  }, [item]);

  return (
    <Modal show={show} onHide={onHideModal} centered>
      <Modal.Header closeButton>
        <Modal.Title className={cx('modal-title')}>Fx Definition</Modal.Title>
      </Modal.Header>
      <Modal.Body className={cx('modal-body')}>
        <Flex column grow alignItems="stretch">
          {!!state.id && (
            <Flex row>
              <Label className={cx('label')}>ID</Label>
              <TextInput value={state.id} disabled />
            </Flex>
          )}
          <Flex row>
            <Label className={cx('label')}>Name</Label>
            <TextInput value={state.name} disabled={!!state.id} />
          </Flex>
          <Flex row>
            <Label className={cx('label')}>Source</Label>
            <TextInput value={state.source} disabled={true} />
          </Flex>
          <Flex row>
            <Label className={cx('label')}>Granularity</Label>
            <TextInput value={moment.duration(state.granularity).humanize()} disabled={true} />
          </Flex>
          <Flex row>
            <Label className={cx('label')}>Price Type</Label>
            <TextInput value={state.priceType} disabled={true} />
          </Flex>
          <Flex row>
            <Label className={cx('label')}>Description</Label>
            <TextInput value={state.description} disabled={true} />
          </Flex>
          <Flex row>
            <Label className={cx('label')}>Tags</Label>
            <TextInput value={state.tags} disabled={true} />
          </Flex>
        </Flex>
      </Modal.Body>
    </Modal>
  );
};

ItemModal.propTypes = {
  item: PropTypes.object,
  tags: PropTypes.array,
  show: PropTypes.bool,
  onHideModal: PropTypes.func,
};

export { ItemModal };
