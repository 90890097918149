import classNames from 'classnames/bind';
import { usePermissions } from 'hooks/usePermissions';
import { useStaticCall } from 'hooks/useStaticCalls';
import { DataTable, Flex, Select } from 'library/components';
import { useState } from 'react';
import { checkFxConversion } from 'utils/common';
import staticCalls from '../../utils/staticCalls.json';
import PropTypes from 'prop-types';

import styles from './index.module.scss';
const cx = classNames.bind(styles);

const getPriceCurveColumns = (
  priceDefinitionData,
  commoditiesData,
  unitsData,
  permissions,
  fxConversionData,
  currenciesData,
  checkFxConversion,
) => {
  return [
    { id: 'source', name: 'Source', field: 'source' },
    { id: 'priceDefinitionName', name: 'Name', field: 'priceDefinitionName' },
    {
      id: 'reportingUnit',
      name: 'Reporting Unit',
      type: 'enum',
      input: {
        value: row => row.reportingUnit ?? null,
        onChange: (row, value) => {
          row.reportingUnit = value;
        },
        render: (value, row, onChange) => {
          const commodity = priceDefinitionData?.find(
            priceDefinition => priceDefinition.name === row.priceDefinitionName,
          )?.commodity;
          const commodityDetail = commoditiesData?.filter(data => data.code === commodity);
          const filteredUnitsData = unitsData?.filter(unit =>
            commodityDetail[0]?.unitConversionFactors?.map(unitConversion => unitConversion?.unit)?.includes(unit.code),
          );
          return (
            <Select value={value} disabled={!permissions.write} onChange={onChange}>
              {filteredUnitsData?.map(unit => (
                <Select.Item value={unit.code} key={unit.code}>
                  {unit.name} - {unit.code}
                </Select.Item>
              ))}
            </Select>
          );
        },
      },
    },
    {
      id: 'reportingCurrency',
      name: 'Reporting Currency',
      type: 'enum',
      input: {
        value: row => row.reportingCurrency ?? null,
        onChange: (row, value) => {
          row.reportingCurrency = value;
        },
        render: (value, row, onChange) => {
          const fxConversions = checkFxConversion(fxConversionData, value);
          const fxConversionsList = [
            ...new Set(
              fxConversions?.filter(data => data.includes(row.reportingCurrency))?.map(fxConversion => fxConversion[0]),
            ),
          ];
          const filteredCurrenciesData = currenciesData?.filter(currency => fxConversionsList?.includes(currency.code));
          return (
            <Select value={value} disabled={!permissions.write} onChange={onChange}>
              {filteredCurrenciesData?.map(currency => (
                <Select.Item value={currency.code} key={currency.code}>
                  {currency.name} - {currency.code}
                </Select.Item>
              ))}
            </Select>
          );
        },
      },
    },
  ];
};

const PriceProfileCurvesList = ({ priceProfileSelection, priceCurveSelection, onPriceCurveSelectionChange }) => {
  const [sortBy, setSortBy] = useState(['index']);

  const priceDefinitionData = useStaticCall(staticCalls.PRICEDEFINITION);
  const commoditiesData = useStaticCall(staticCalls.COMMODITIES);
  const unitsData = useStaticCall(staticCalls.PRICINGUNITS);
  const fxConversionData = useStaticCall(staticCalls.FXDEFINITION);
  const currenciesData = useStaticCall(staticCalls.CURRENCIES);

  const permissions = usePermissions('price-profile');

  const priceCurvesColumns = getPriceCurveColumns(
    priceDefinitionData,
    commoditiesData,
    unitsData,
    permissions.write,
    fxConversionData,
    currenciesData,
    checkFxConversion,
  );

  return (
    <Flex column grow shrink alignItems="stretch" overflow="auto" className={cx('box')}>
      {priceProfileSelection?.length === 1 && (
        <DataTable
          columns={priceCurvesColumns}
          data={priceProfileSelection?.[0].curves}
          selection={priceCurveSelection}
          onSelectionChange={onPriceCurveSelectionChange}
          sortBy={sortBy}
          onChangeSortBy={value => setSortBy(value)}
        />
      )}
    </Flex>
  );
};

PriceProfileCurvesList.propTypes = {
  priceProfileSelection: PropTypes.array,
  priceCurveSelection: PropTypes.array,
  onPriceCurveSelectionChange: PropTypes.func,
};

export { PriceProfileCurvesList };
