import { Alert, AlertTitle, Stack } from '@mui/material';

type AlertType = {
  alert: string;
  helpText?: string;
};

import { Typography } from '@mui/material';

export const AccessAlerts = (props: AlertType) => {
  return (
    <Stack flexGrow={1} alignItems="center" justifyContent="center" sx={{ color: 'rgba(252,216,57,.75)' }}>
      <Typography variant="h1">Shell Energy Pricing</Typography>
      <Alert variant="filled" severity="error">
        <AlertTitle>{props.alert}</AlertTitle>
        {props.helpText}
      </Alert>
    </Stack>
  );
};
