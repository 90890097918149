import React, { useEffect } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { SnackbarProvider } from 'notistack';
import { AppInsightsErrorBoundary } from '@microsoft/applicationinsights-react-js';

import { PermissionsProvider } from 'hooks/usePermissions';
import { StaticCallProvider } from 'hooks/useStaticCalls';

import { PriceDefinition } from 'components/PriceDefinition';
import { PriceViewer } from 'components/PriceViewer';
import { HistoricalProjection } from 'components/HistoricalProjection';
import { PriceProfile } from 'components/PriceProfile';
import { BulkRetrieval } from 'components/BulkRetrieval';
import { QueryClient, QueryClientProvider } from 'react-query';

import { Header } from 'components/Header';
import { AccessAlerts } from '../UserLoading';

import styles from './index.module.scss';
import classNames from 'classnames/bind';
import PropTypes from 'prop-types';

import { useAuth } from 'react-oidc-context';
import { reactPlugin, appInsights } from 'AppInsights';
import { useAuthorize } from '../../hooks/useAuthorize';

const cx = classNames.bind(styles);

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

const withQueryProvider = Component => {
  // eslint-disable-next-line react/display-name
  return props => (
    <QueryClientProvider client={queryClient}>
      <Component {...props} />
    </QueryClientProvider>
  );
};

const withPermissions = Component => {
  // eslint-disable-next-line react/display-name
  return props => (
    <PermissionsProvider>
      <Component {...props} />
    </PermissionsProvider>
  );
};

const withStaticCalls = Component => {
  // eslint-disable-next-line react/display-name
  return props => (
    <StaticCallProvider>
      <Component {...props} />
    </StaticCallProvider>
  );
};

const withSnackbar = Component => {
  // eslint-disable-next-line react/display-name
  return props => (
    <SnackbarProvider>
      <Component {...props} />
    </SnackbarProvider>
  );
};

const appInsightTracking = (isLoading, appInsightLoaded, firstLogin, user) => {
  if (!isLoading && !appInsightLoaded.current && !firstLogin.current) {
    const userId = user?.profile.email || user?.profile.name;
    if (userId) {
      appInsights.setAuthenticatedUserContext(userId, 'AllUsers');
    }
    appInsights.loadAppInsights();
    appInsightLoaded.current = true;
  }
};

const App = withQueryProvider(
  withPermissions(
    withStaticCalls(
      withSnackbar(() => {
        const { signinRedirect, isLoading, isAuthenticated, error, user } = useAuth();
        const firstLogin = React.useRef(true);
        const appInsightLoaded = React.useRef(false);
        const isLoggedIn = (!isLoading && !firstLogin.current) || isAuthenticated;

        useEffect(() => {
          if (!isLoading && firstLogin.current) {
            firstLogin.current = false;
            if (!isAuthenticated) {
              signinRedirect({ extraQueryParams: { kc_idp_hint: 'oidc' } });
            }
          }
        }, [isAuthenticated, isLoading, signinRedirect]);

        useEffect(() => {
          appInsightTracking(isLoading, appInsightLoaded, firstLogin, user);
        }, [isLoading, user]);

        const isAuthorized = useAuthorize(isAuthenticated, user);

        const handleRender = () => {
          if (isAuthenticated && isAuthorized === 'false') {
            return (
              <AccessAlerts
                alert="You are not authorized to use this application."
                helpText="Please reach out to the Gas Desk Analytics team to get required access."
              />
            );
          }
          if (!isLoggedIn) {
            return <div className="spinner" />;
          }
          if (!isAuthenticated && !error) {
            return (
              <AccessAlerts alert="Unable to authenticate, please make sure that you are connected to Shell network." />
            );
          }
          if (!!error) {
            if (navigator.onLine) {
              return (
                <AccessAlerts
                  alert="Oops! something went wrong, please refresh your page."
                  helpText="If the issue persists, please contact Gas Desk Analytics team."
                />
              );
            } else {
              signinRedirect({ extraQueryParams: { kc_idp_hint: 'oidc' } });
            }
          }
          return (
            <AppInsightsErrorBoundary onError={() => <h1>Something went wrong</h1>} appInsights={reactPlugin}>
              <Header />
              <div className={cx('content')}>
                <Switch>
                  <Route path="/auth/callback">
                    <Redirect to="/price-catalogue" />
                  </Route>
                  <Route path="/price-catalogue">
                    <PriceDefinition />
                  </Route>
                  <Route path="/price-viewer">
                    <PriceViewer />
                  </Route>
                  <Route path="/historical">
                    <HistoricalProjection />
                  </Route>
                  <Route path="/price-profile">
                    <PriceProfile />
                  </Route>
                  <Route path="/bulk-retrieval">
                    <BulkRetrieval />
                  </Route>
                  <Route path="/" exact>
                    <Redirect to="/price-catalogue/commodity" />
                  </Route>
                  <Route path="/authenticate" exact>
                    <Redirect to="/price-catalogue/commodity" />
                  </Route>
                  <Route>
                    <Redirect to="/" />
                  </Route>
                </Switch>
              </div>
            </AppInsightsErrorBoundary>
          );
        };
        return handleRender();
      }),
    ),
  ),
);

Route.propTypes = {
  role: PropTypes.string,
  validSession: PropTypes.bool,
  path: PropTypes.string,
};

export { App };
