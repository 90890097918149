const interpolationDataType = {
  CODE: {
    LINEAR: 'Linear',
    STEP: 'Step',
  },
  NAME: {
    LINEAR: 'Linear',
    STEP: 'Step',
  },
};

const interpolationData = [
  {
    code: interpolationDataType.CODE.LINEAR,
    name: interpolationDataType.NAME.LINEAR,
  },
  {
    code: interpolationDataType.CODE.STEP,
    name: interpolationDataType.NAME.STEP,
  },
];

export { interpolationData };
