import React, { useState, createContext, useContext, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as DropdownIcon } from './dropdown.svg';

import styles from './index.module.scss';
import classNames from 'classnames/bind';

const cx = classNames.bind(styles);

const MenuContext = createContext();

const Menu = ({ children, disabled }) => {
  const [show, setShow] = useState(false);
  const wrapper = useRef();

  let items = React.Children.toArray(children);
  const button = items.find(({ type }) => type === MenuButton);
  items = items.filter(({ type }) => type === MenuItem);

  const onClickButton = () => {
    if (disabled) {
      return;
    }

    setShow(show => !show);
  };

  useEffect(() => {
    if (disabled) {
      setShow(false);
    }
  }, [disabled]);

  useEffect(() => {
    if (show) {
      const handler = event => {
        if (event.target.closest(`.${cx('wrapper')}`) !== wrapper.current) {
          setShow(false);
        }
      };

      window.addEventListener('mousedown', handler, false);
      return () => window.removeEventListener('mousedown', handler, false);
    }
    return undefined;
  }, [show]);

  return (
    <MenuContext.Provider value={{ setShow }}>
      <div ref={wrapper} className={cx('wrapper')}>
        <button className={cx('button', { disabled })} onClick={onClickButton}>
          {button}
          <DropdownIcon />
        </button>
        {show && <div className={cx('items')}>{items}</div>}
      </div>
    </MenuContext.Provider>
  );
};

Menu.propTypes = {
  children: PropTypes.node,
  disabled: PropTypes.bool,
};

const MenuButton = ({ children }) => <span className={cx('button-container')}>{children}</span>;

MenuButton.propTypes = {
  children: PropTypes.node,
};

const MenuItem = ({ children, onClick }) => {
  const { setShow } = useContext(MenuContext);

  const _onClick = () => {
    setShow(false);
    onClick?.();
  };

  return (
    <div className={cx('item')} onClick={_onClick}>
      {children}
    </div>
  );
};

MenuItem.propTypes = {
  children: PropTypes.node,
  onClick: PropTypes.func,
};

Menu.Button = MenuButton;
Menu.Item = MenuItem;

export { Menu, MenuButton, MenuItem };
