import PropTypes from 'prop-types';

import { ReactComponent as SpinnerIcon } from './spinner.svg';

import styles from './index.module.scss';
import classNames from 'classnames/bind';

const cx = classNames.bind(styles);

const Button = ({ loading, children, disabled, type, onClick, className }) => {
  return (
    <button type={type} onClick={onClick} disabled={disabled} className={cx('button', className, { disabled })}>
      {children}
      {loading && <SpinnerIcon className={cx('spinner')} />}
    </button>
  );
};

Button.propTypes = {
  loading: PropTypes.bool,
  children: PropTypes.node,
  disabled: PropTypes.bool,
  type: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  className: PropTypes.string,
};

export { Button };
