import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Flex, Label, MultiSelect, Ribbon, DateSelect, TextInput, Select } from 'library/components';
import moment from 'moment';
import { apiFetch } from 'utils/apiService';

import { CurveConversions } from '../../shared/CurveConversions';

import { granularityData } from 'utils/granularityData';

import styles from './index.module.scss';
import classNames from 'classnames/bind';
import { none } from 'utils/constants';

import { useQuery } from 'react-query';
import { useAuth } from 'react-oidc-context';
const cx = classNames.bind(styles);

const SideBarContainer = ({
  filters,
  conversion,
  onFilterChange,
  onConversionChange,
  priceDefinitionsData,
  fxConversionList,
}) => {
  const { selectedTags, live, publicationDate, priceType, search, granularity, profileName, source } = filters;

  const [tags, setTags] = useState([]);
  const [sources, setSources] = useState([]);

  const { user } = useAuth();
  const { data: priceProfileData } = useQuery({
    queryKey: ['/experience/PriceProfile'],
    queryFn: ({ queryKey: [url] }) => apiFetch({ url, token: user?.access_token }),
    enabled: !!user?.access_token,
  });
  const { data: userProfileData } = useQuery({
    queryKey: ['/experience/UserProfile'],
    queryFn: ({ queryKey: [url] }) => apiFetch({ url, token: user?.access_token }),
    enabled: !!user?.access_token,
  });

  useEffect(() => {
    if (!priceDefinitionsData) {
      setTags([]);
      setSources([]);
      return;
    }
    setTags(
      Array.from(
        priceDefinitionsData.reduce((a, b) => {
          b?.tags?.forEach(tag => a.add(tag));
          return a;
        }, new Set()),
      ),
    );
    setSources(Array.from(new Set(priceDefinitionsData.map(row => row.source))));
  }, [priceDefinitionsData]);

  useEffect(() => {
    if (userProfileData) {
      const localUserProfile = typeof userProfileData == 'string' ? JSON.parse(userProfileData) : userProfileData;
      const priceViewerUserProfile = localUserProfile?.priceViewer;

      if (priceViewerUserProfile) {
        if (priceViewerUserProfile?.lastSelectedPriceProfile) {
          const priceProfileDetails = priceProfileData?.find(
            data => data.profileName === priceViewerUserProfile?.lastSelectedPriceProfile,
          );
          onFilterChange('profileName', priceProfileDetails.profileName);
        }
      }
    }
  }, [userProfileData, priceProfileData, onFilterChange]);

  const onChangePriceProfile = value => {
    onFilterChange(
      'priceProfile',
      priceProfileData?.find(data => data.profileName === value),
    );
    onFilterChange('profileName', value);
  };

  return (
    <Flex column alignItems="stretch" className={cx('hamburger-sidebar')}>
      <Label className={cx('label')}>Tags:</Label>
      <MultiSelect value={selectedTags} onChange={value => onFilterChange('selectedTags', value)}>
        {tags.map(tag => (
          <MultiSelect.Item value={tag} key={tag}>
            {tag}
          </MultiSelect.Item>
        ))}
      </MultiSelect>

      <Label className={cx('label')}>Publication Date:</Label>
      <Flex row alignItems="center">
        <Ribbon value={live} onChange={value => onFilterChange('live', value)}>
          <Ribbon.Item value={true}>Latest</Ribbon.Item>
          <Ribbon.Item value={false}>Snapshot</Ribbon.Item>
        </Ribbon>
        {!live && (
          <Flex column alignItems="stretch" grow shrink className={cx('date-picker')}>
            <DateSelect
              value={publicationDate}
              onChange={value => onFilterChange('publicationDate', value)}
              min={moment(new Date(Date.now()), 'utc').add(-1, 'day').add(-2, 'year').startOf('day').toDate()}
              max={moment(new Date(Date.now()), 'utc').startOf('day').toDate()}
            />
          </Flex>
        )}
      </Flex>

      <Flex column className={cx('conversion')}>
        <CurveConversions conversion={conversion} onChange={onConversionChange} fxConversionList={fxConversionList} />
      </Flex>

      <Label className={cx('label')}>Price Profile:</Label>
      <Select value={profileName} onChange={onChangePriceProfile}>
        <Select.Item value={none}>(none)</Select.Item>
        {priceProfileData?.map(profile => (
          <Select.Item value={profile?.profileName} key={profile.id}>
            {profile.profileName}
          </Select.Item>
        ))}
      </Select>

      <Label className={cx('label')}>Source</Label>
      <Select value={source ?? null} onChange={value => onFilterChange('source', value)}>
        <Select.Item value={null}>All Sources</Select.Item>
        {sources.map(source => (
          <Select.Item key={source} value={source}>
            {source}
          </Select.Item>
        ))}
      </Select>

      <Label className={cx('label')}>Price Type:</Label>
      <Ribbon value={priceType} onChange={value => onFilterChange('priceType', value)}>
        <Ribbon.Item value="LIVE">Live</Ribbon.Item>
        <Ribbon.Item value="EOD">EOD</Ribbon.Item>
        <Ribbon.Item value={null}>Both</Ribbon.Item>
      </Ribbon>

      <Label className={cx('label')}>Price Definition Search:</Label>
      <TextInput value={search} onChange={value => onFilterChange('search', value)} placeholder="Search..." />

      <Label className={cx('label')}>Granularity:</Label>
      <Select bottom={true} value={granularity} onChange={value => onFilterChange('granularity', value)}>
        <Select.Item value={null}>Default</Select.Item>
        {granularityData?.map(granularity => (
          <Select.Item key={granularity.code} value={granularity.code}>
            {granularity.name}
          </Select.Item>
        ))}
      </Select>
    </Flex>
  );
};

SideBarContainer.propTypes = {
  priceDefinitionsData: PropTypes.array,
  onFilterChange: PropTypes.func,
  onConversionChange: PropTypes.func,
  filters: PropTypes.shape({
    selectedTags: PropTypes.array,
    live: PropTypes.bool,
    publicationDate: PropTypes.any,
    priceType: PropTypes.string,
    search: PropTypes.string,
    granularity: PropTypes.string,
    priceProfile: PropTypes.object,
    profileName: PropTypes.string,
    source: PropTypes.string,
  }),
  conversion: PropTypes.object,
  fxConversionList: PropTypes.array,
};

export { SideBarContainer };
