import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Flex, Label, DateSelect, Select } from 'library/components';
import moment from 'moment';

import { granularityData } from 'utils/granularityData';
import contractListData from './contractListData.json';
import contractTypeJson from './contractType.json';
import styles from './index.module.scss';
import classNames from 'classnames/bind';

const cx = classNames.bind(styles);

const CurveDateRange = ({
  publicationDate,
  onPublicationDateChange,
  contractType,
  onContractTypeChange,
  contractName,
  onContractNameChange,
  granularity,
  onGranularityChange,
}) => {
  const [contractNameList, setContractNameList] = useState([]);

  useEffect(() => {
    generateContractNameList(contractType);
  }, [contractType]);

  const datePickerClass = 'date-picker';

  const getRelativeList = (name, relCount = 5) => {
    let count = 1;
    const list = [];
    while (count <= relCount) {
      list.push({ name: `${name}+${count}`, type: contractType });
      count++;
    }
    return list;
  };

  const generateContractNameList = type => {
    let list = [];
    const date = new Date();
    let currentYear = date.getFullYear();
    const maxYear = currentYear + 5;

    switch (type) {
      case contractTypeJson.ABS_MONTH: {
        let currentMonth = new Date(date.setMonth(date.getMonth() + 1));
        currentYear = currentMonth.getFullYear();
        while (currentYear <= maxYear) {
          list.push({
            name: `${currentYear}-${currentMonth.toLocaleString('default', {
              month: 'short',
            })}`,
            month: currentMonth.getMonth(),
            year: currentYear,
            type,
            monthsCount: 1,
          });
          currentMonth = new Date(date.setMonth(date.getMonth() + 1));
          currentYear = currentMonth.getFullYear();
        }
        break;
      }
      case contractTypeJson.ABS_QUARTER: {
        const { types } = contractListData.find(data => data.code === contractTypeJson.ABS_QUARTER);
        let currentQuarter = new Date(date.setMonth(date.getMonth() + 3));
        currentYear = currentQuarter.getFullYear();
        while (currentYear <= maxYear) {
          const quarterType = types.find(x => x.months.includes(currentQuarter.getMonth()));
          list.push({
            name: `${currentYear}-${quarterType.name}`,
            month: quarterType.months[0],
            year: currentYear,
            type,
            monthsCount: 3,
          });
          currentQuarter = new Date(date.setMonth(date.getMonth() + 3));
          currentYear = currentQuarter.getFullYear();
        }
        break;
      }
      case contractTypeJson.ABS_SEASON: {
        const { types } = contractListData.find(data => data.code === contractTypeJson.ABS_SEASON);
        let currentSeason = new Date(date.setMonth(date.getMonth() + 6));
        currentYear = currentSeason.getFullYear();
        while (currentYear <= maxYear) {
          const seasonType = types.find(x => x.months.includes(currentSeason.getMonth()));
          list.push({
            name: `${currentYear}-${seasonType.name}`,
            month: seasonType.months[0],
            year: currentYear,
            type,
            monthsCount: 6,
          });
          currentSeason = new Date(date.setMonth(date.getMonth() + 6));
          currentYear = currentSeason.getFullYear();
        }
        break;
      }
      case contractTypeJson.ABS_YEAR: {
        const startYear = new Date(date.setFullYear(date.getFullYear() + 1));
        currentYear = startYear.getFullYear();
        while (currentYear <= maxYear) {
          list.push({
            name: currentYear,
            month: 0,
            year: currentYear,
            type,
            monthsCount: 12,
          });
          const nextYear = new Date(date.setFullYear(date.getFullYear() + 1));
          currentYear = nextYear.getFullYear();
        }
        break;
      }
      case contractTypeJson.REL_DAY: {
        list = getRelativeList('D', 99);
        break;
      }
      case contractTypeJson.REL_MONTH: {
        list = getRelativeList('M', 60);
        break;
      }
      case contractTypeJson.REL_QUARTER: {
        list = getRelativeList('Q', 20);
        break;
      }
      case contractTypeJson.REL_SEASON: {
        list = getRelativeList('SSN', 10);
        break;
      }
      case contractTypeJson.REL_SUMMER: {
        list = getRelativeList('SUM', 5);
        break;
      }
      case contractTypeJson.REL_WINTER: {
        list = getRelativeList('WIN', 5);
        break;
      }
      case contractTypeJson.REL_YEAR: {
        list = getRelativeList('Y', 5);
        break;
      }
      case contractTypeJson.REL_GAS_YEAR: {
        list = getRelativeList('GY', 5);
        break;
      }
      default:
        break;
    }
    list = [...new Set(list)];
    setContractNameList(list);
    onContractNameChange(list[0]);
  };

  return (
    <Flex column alignItems="stretch" className={cx('date-range')}>
      <Flex row>
        <Flex column className={cx(datePickerClass)}>
          <Label className={cx('label')}>Publication Start Date:</Label>
          <DateSelect
            value={publicationDate.start}
            onChange={value => onPublicationDateChange('start', value)}
            min={moment(new Date(Date.now()), 'utc').add(-1, 'day').add(-10, 'year').startOf('day').toDate()}
            max={moment(new Date(Date.now()), 'utc').startOf('day').toDate()}
          />
        </Flex>
        <Flex column className={cx(datePickerClass)}>
          <Label className={cx('label')}>Publication End Date:</Label>
          <DateSelect
            value={publicationDate.end}
            onChange={value => onPublicationDateChange('end', value)}
            min={publicationDate.start}
            max={moment(new Date(Date.now()), 'utc').startOf('day').toDate()}
          />
        </Flex>
      </Flex>

      <Flex column>
        <Label className={cx('label')}>Contract Type:</Label>
        <Select value={contractType} onChange={onContractTypeChange}>
          {contractListData.map(data => (
            <Select.Item key={data.code} value={data.code}>
              {data.name}
            </Select.Item>
          ))}
        </Select>
      </Flex>

      <Flex column>
        <Label className={cx('label')}>Contract Name:</Label>
        <Select value={contractName} onChange={onContractNameChange}>
          {contractNameList?.map(data => (
            <Select.Item key={data.name} value={data}>
              {data.name}
            </Select.Item>
          ))}
        </Select>
      </Flex>
      <Flex column>
        <Label className={cx('label')}>Granularity:</Label>
        <Select bottom={true} value={granularity} onChange={onGranularityChange}>
          <Select.Item value={null}>Default</Select.Item>
          {granularityData?.map(granularity => (
            <Select.Item key={granularity.code} value={granularity.code}>
              {granularity.name}
            </Select.Item>
          ))}
        </Select>
      </Flex>
    </Flex>
  );
};

CurveDateRange.propTypes = {
  onPublicationDateChange: PropTypes.func,
  publicationDate: PropTypes.object,
  contractType: PropTypes.string,
  onContractTypeChange: PropTypes.func,
  contractName: PropTypes.string,
  onContractNameChange: PropTypes.func,
  granularity: PropTypes.string,
  onGranularityChange: PropTypes.func,
};

export { CurveDateRange };
