// eslint-disable-next-line @typescript-eslint/no-unused-vars
import CssBaseline from '@mui/material/CssBaseline';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { App } from './components/App';
import { AuthProvider } from 'react-oidc-context';
import { AppInsightsContext } from '@microsoft/applicationinsights-react-js';
import { reactPlugin } from './AppInsights';

import './index.scss';
const theme = createTheme({
  palette: {
    mode: 'dark',
  },
  components: {
    MuiTextField: {
      styleOverrides: {
        root: {
          borderRadius: '50px',
        },
      },
    },
  },
  typography: {
    fontFamily: `"Avenir Next", "Helvetica", "Arial", sans-serif`,
    fontSize: 10,
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
  },
});

const onSigninCallback = (): void => {
  window.history.replaceState({}, document.title, window.location.pathname);
};

const oidcConfig = {
  authority: process.env.REACT_APP_AUTH_AUTHORITY || '',
  client_id: process.env.REACT_APP_AUTH_CLIENT_ID || '',
  redirect_uri: `${location.protocol}//${location.host}/auth/callback`,
  onSigninCallback,
};

ReactDOM.render(
  <ThemeProvider theme={theme}>
    <CssBaseline />
    <BrowserRouter>
      <AuthProvider {...oidcConfig}>
        <AppInsightsContext.Provider value={reactPlugin}>
          <App />
        </AppInsightsContext.Provider>
      </AuthProvider>
    </BrowserRouter>
  </ThemeProvider>,
  document.getElementById('root'),
);
